import React, {useState} from 'react';
import Select from '../../../components/Select';

const SelectExample: React.FC = () => {
  const [selectError, setSelectError] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string>('');

  return(
    <Select
      disabledOption="Ordenar por"
      options={[
        'Cores',
        'Tamanho',
      ]}
      selectError={selectError}
      setselectError={setSelectError}
      selectedValue={selectedValue}
      setSelectedValue={setSelectedValue}
      required={false}
    />
  );
};

export default SelectExample;
