import styled from 'styled-components';

export const Container = styled.section``;

export const Content = styled.section`
  margin-top: 20px;

  > h2 {
    color: var(--purple);
  }

  > p {
    color: var(--grey);
    max-width: 700px;
    font-size: 14px;
    padding: 10px 0px;
  }
`;

export const CardInfo = styled.div`
  width: 100%;
  border: 2px solid var(--grey-light-2);
  border-radius: 9px;
`;

export const CardHeader = styled.div`
  width: 100%;
  padding: 10px 20px;
  display: flex;
  gap: 30px;
`;

export const CardBody = styled.div`
  width: 100%;
  background-color: var(--grey-light-3);
  padding: 30px 20px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;

  > div {
    margin-top: 20px;

    > p,
    pre {
      color: var(--grey-medium-3);
    }
  }
`;

export const Wraper = styled.div`
  display: flex;
  gap: 20px;
  border-top: 1px solid var(--grey-light-1);
`;
export const CodeBlock = styled.div`
  padding: 20px;
`;
