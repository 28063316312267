import React from 'react';

import { Title } from '../styles';
import { Link } from 'react-router-dom';
import {
  LinkIcon,
  LinkText,
  LinkContainer,
  Container,
} from './styles';

const Main: React.FC = () => {
  return (
    <Container>
      <Title>Minha Conta</Title>
      <LinkContainer>
        <Link to={'enderecos'}>
          <LinkIcon>
            <i className="icon icon-location"/>
          </LinkIcon>
          <LinkText>Meus Endereços</LinkText>
        </Link>
        <Link to={'pedidos'}>
          <LinkIcon>
            <i className="icon icon-box"/>
          </LinkIcon>
          <LinkText>Meus Pedidos</LinkText>
        </Link>
        <Link to={'pagamentos'}>
          <LinkIcon>
            <i className="icon icon-credit-card"/>
          </LinkIcon>
          <LinkText>Formas de Pagamento</LinkText>
        </Link>
        <Link to={'meu-cadastro'}>
          <LinkIcon>
            <i className="icon icon-user-text"/>
          </LinkIcon>
          <LinkText>Meu Cadastro</LinkText>
        </Link>
      </LinkContainer>
    </Container>
  );
};

export default Main;
