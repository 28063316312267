import React, {FunctionComponent, useCallback, useRef, useState} from 'react';
import {Container, Title, Form, Body, ButtonClose, Row, Col, AreaIcon, TooltipCard, ValidInput} from './styles';
import {Backdrop, Fade} from '@material-ui/core';
import Input, { RequiredTip } from '../../../../../components/Input';
import Checkbox from '../../../../../components/Checkbox';
import Button from '../../../../../components/Button';
import useForm from '../../../../../hooks/useForm';
import {validateCreditCard} from '../../../../../helpers/inputValidation';
import {MoipValidator} from 'moip-sdk-js';
import CreditCardImage from '../../../../../assets/png/credit_card.png';
import AMEX from '../../../../../assets/svg/AMEX.svg';
import ELO from '../../../../../assets/svg/ELO.svg';
import DINERS from '../../../../../assets/svg/DINERS.svg';
import HIPER from '../../../../../assets/svg/HIPER.svg';
import HIPERCARD from '../../../../../assets/svg/HIPERCARD.svg';
import MASTERCARD from '../../../../../assets/svg/MASTERCARD.svg';
import VISA from '../../../../../assets/svg/VISA.svg';
import {CardSaveInterface, cardTypesInterface} from '../../../../../interfaces/creditCard';
import {encryptCard} from '../../../../../helpers/moipHelper';
import {removeMask} from '../../../../../helpers/removeMasks';
import {useToast} from '../../../../../hooks/useToast';

interface OwnProps {
  open: boolean;
  onClose: () => void;
  onSave?: (newCard: CardSaveInterface) => void;
}

const RegisterCardModal: FunctionComponent<OwnProps> = (props) => {
  const [mainCard, setMainCard] = useState(0);
  const [cardType, setCardType] = useState<string | null>(null);
  const {form, onChange, onBlur, validateForm, error, clearForm} = useForm({
    cardNumber: {
      required: true,
      type: 'cardNumber',
      limit: 19
    },
    holder: {
      required: true,
      type: 'fullname'
    },
    cpf: {
      required: true,
      type: 'cpf',
      limit: 14
    },
    date: {
      required: true,
      type: 'date',
      limit: 10
    },
    phone: {
      required: true,
      type: 'phone',
      limit: 15
    },
    expirationDate: {
      required: true,
      type: 'cardDate'
    },
    securityCode: {
      required: true,
      type: 'cardSecurityCode',
      limit: 4
    }
  });

  const cardTypes = useRef<cardTypesInterface>({
    AMEX,
    ELO,
    DINERS,
    HIPER,
    HIPERCARD,
    MASTERCARD,
    VISA
  }).current;

  const {toast} = useToast();

  const getCardType = (cardNumber: string) => {
    cardNumber = cardNumber.replace(/\D/g, '');
    const type = MoipValidator.cardType(cardNumber);

    setCardType(type ? type.brand : null);
  };

  const closeModal = useCallback(() => {
    if (props.onClose) {
      props.onClose();
    }
  }, [props]);

  const saveCard = useCallback(async () => {
    const {cardNumber, securityCode, expirationDate, cpf, phone, date} = form;
    const cardWithoutMask = removeMask(cardNumber, 'card');

    const cardValidation = validateCreditCard({cardNumber, securityCode, expirationDate});

    if (!cardValidation.valid || !validateForm()) {
      if (!cardValidation.valid) {
        toast({
          type: 'error',
          description: cardValidation.message
        });
      }
      return;
    }

    const {brand} = await MoipValidator.cardType(cardWithoutMask);
    const brandLowerCase = brand.toLowerCase();

    const hash = await encryptCard({card: cardNumber, validate: expirationDate, cvc: securityCode});

    //enviar dados do cartão para salvar
    if (props.onSave) {
      const [month, year] = expirationDate.split('/');

      props.onSave({
        hash,
        last4Digits: cardWithoutMask.substr(cardWithoutMask.length - 4, 4),
        brand: brandLowerCase,
        brandName: brandLowerCase.charAt(0).toUpperCase() + brandLowerCase.slice(1),
        holder: form.holder,
        birthDate: date,
        cpf: cpf,
        main: mainCard,
        expirationMonth: month,
        expirationYear: '20' + year,
        holderPhoneNumber: phone,
      });
    }

    setCardType(null);
    clearForm();

    closeModal();
  }, [clearForm, closeModal, form, mainCard, props, toast, validateForm]);

  return (
    <Container
      {...props}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      BackdropComponent={Backdrop}
      BackdropProps={{timeout: 500}}
    >
      <Fade in={props.open}>
        <Body>
          <ButtonClose onClick={closeModal}>
            <i className={'icon icon-exit'}/>
          </ButtonClose>

          <button className={'back-button'} onClick={closeModal}>
            <i className={'icon icon-arrow-left'}/>
            <span>Voltar</span>
          </button>

          <Title id="transition-modal-title">Cadastrar novo cartão</Title>

          <Form>
            <Row>
              <Col>
                <Input
                  value={form.holder}
                  error={error.holder}
                  onChange={(e) => onChange('holder', e)}
                  onBlur={() => onBlur('holder')}
                  title={'Nome do Titular'}
                  placeholder={'Nome impresso no cartão'}
                  required
                />
              </Col>
              <Col>
                <Input
                  value={form.cpf}
                  error={error.cpf}
                  onChange={(e) => onChange('cpf', e)}
                  onBlur={() => onBlur('cpf')}
                  title={'CPF do Titular'}
                  placeholder={'Digite apenas número'}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  value={form.date}
                  error={error.date}
                  onChange={(e) => {
                    onChange('date', e);
                  }}
                  onBlur={() => {
                    onBlur('date');
                  }}
                  title={'Data de Nascimento'}
                  placeholder={'DDMMAAAA'}
                  required
                />
              </Col>
              <Col>
                <Input
                  value={form.phone}
                  error={error.phone}
                  onChange={(e) => {
                    onChange('phone', e);
                  }}
                  onBlur={() => {
                    onBlur('phone');
                  }}
                  title={'Telefone'}
                  placeholder={'Digite o número com DDD'}
                  required
                />
              </Col>
            </Row>

            <Row className={'last'}>
              <Col>
                <Input
                  value={form.cardNumber}
                  error={error.cardNumber}
                  onChange={(e) => {
                    getCardType(e.target.value);
                    onChange('cardNumber', e);
                  }}
                  onBlur={() => {
                    onBlur('cardNumber');
                  }}
                  title={'Número do Cartão'}
                  placeholder={'1234 1234 1234 1234'}
                  required
                />

                <AreaIcon>
                  {cardType ? (
                    <img src={cardTypes[cardType]} alt="bandeira do cartão"/>
                  ) : (
                    <i className={'icon icon-credit-card'}/>
                  )}
                </AreaIcon>
              </Col>

              <Col>
                <ValidInput>
                  <Input
                    value={form.expirationDate}
                    error={error.expirationDate}
                    onChange={(e) => onChange('expirationDate', e)}
                    onBlur={() => onBlur('expirationDate')}
                    title={'Validade'}
                    placeholder={'MMAA'}
                    required
                  />
                </ValidInput>

                <Input
                  value={form.securityCode}
                  error={error.securityCode}
                  onChange={(e) => onChange('securityCode', e)}
                  onBlur={() => onBlur('securityCode')}
                  title={'Código de Segurança'}
                  placeholder={'Digite apenas números'}
                  tooltipContent={
                    <TooltipCard>
                      <img
                        src={CreditCardImage}
                        alt={'Imagem localizando o código de segurança na parte traseira do cartão.'}
                      />
                    </TooltipCard>
                  }
                  required
                />
              </Col>
            </Row>

            <Checkbox 
              checked={mainCard === 1} 
              onChange={(checked: boolean) => setMainCard(checked ? 1 : 0)}
            >
              Salvar este cartão como principal
            </Checkbox>
            <RequiredTip />

            <div className={'container-button'}>
              <Button color={'var(--purple)'} scale={'scale-3'} expand={true} onClick={saveCard}>Adicionar
                cartão</Button>
            </div>
          </Form>
        </Body>
      </Fade>
    </Container>
  );
};

export default RegisterCardModal;
