import React, { useRef } from 'react';
import { Container, Text } from './styles';
import LoadingAnimation from '../../assets/animations/loading.json';
import Lottie from 'react-lottie';

interface Props{
  show: boolean;
}

const Loading: React.FC<Props> = ({
  show = false
}) => {

  const defaultOptions = useRef({
    loop: true,
    autoplay: true,
    animationData: LoadingAnimation,
  }).current;

  return (
    <>
    {show &&
      <Container>
        <Lottie width={64} height={64} options={defaultOptions} />
        <Text>Carregando</Text>
      </Container>
    }
    </>
  );
};

export default Loading;
