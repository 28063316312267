import React, { useCallback, useEffect, useState } from 'react';
import { Title } from '../../styles';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { ProductOrders } from '../../../../interfaces/orderProduct';
import { api } from '../../../../services/api';
import { useLoading } from '../../../../hooks/useLoading';
import ListOrders from './components/ListOrders';

export interface OrderRequestInterface {
  pages: number;
  results: number;
  orders: OrderInterface[];
}

export interface OrderInterface {
  order_number: number;
  quantity: number;
  total: string;
  payment_method: string;
  order_status: string;
  order_status_id: number;
  request_order: string;
  order_delivered: string;
  stage: number;
  stageTitle: string;
}

function a11yProps(index: unknown) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'var(--white)',
    marginTop: '55px',
    '@media screen and (min-width: 607px) and (max-width: 1279px)': {
      '&': {
        maxWidth: '606px',
        margin: '0 auto',
      },
    },
    '@media screen and (max-width: 607px)': {
      '&': {
        maxWidth: '312px',
        margin: '0 auto',
      },
    },
  },
  tabs: {
    backgroundColor: 'var(--white)',
    color: 'var(--grey-dark)',
    '& > div > div': {
      display: 'grid',
      gridTemplateColumns: '204.3px 193px 158px 185.49px',
      alignItems: 'center',
      '@media screen and (min-width: 607px) and (max-width: 1279px)': {
        '&': {
          gridTemplateColumns: 'minmax(145px, 167px) 158px 129px 152px',
        },
      },
      '@media screen and (max-width: 607px)': {
        '&': {
          gridTemplateColumns: '76px 80px 80px 76px',
        },
      },
    },
  },
  tab: {
    fontSize: '12px',
    opacity: 1,
    borderBottom: '1.22px solid var(--grey-light-2)',
    padding: 0,
    minWidth: 0,
    '&.Mui-selected': {
      color: 'var(--purple)',
      borderColor: 'var(--purple)',
    },
    '@media screen and (max-width: 1279px)': {
      '&': {
        borderBottom: '1px solid var(--grey-light-2)',
      },
    },
    '@media screen and (max-width: 607px)': {
      '&': {
        fontSize: '9px',
      },
    },
  },
  cancelTab: {
    '&.Mui-selected': {
      color: '#EB001B',
      borderColor: '#EB001B',
    },
  },
  bar: {
    boxShadow: 'none',
    '& .MuiTabs-indicator': {
      display: 'none',
      backgroundColor: 'var(--purple)',
    },
  },
}));

const Main: React.FC = () => {
  const [orders, setOrders] = useState<OrderRequestInterface>(
    {} as OrderRequestInterface,
  );
  const [page, setPage] = useState<number>(1);
  const [tab, setTab] = useState<string>('all');
  const [limit] = useState<number>(10);
  const [value, setValue] = useState<number>(0);
  const [products, setProducts] = useState<ProductOrders[]>([]);
  const { showLoading, closeLoading } = useLoading();
  const classes = useStyles();

  const executeScroll = useCallback(
    () => {
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    [],
  );

  const getOrders = useCallback(async () => {
    try {
      showLoading();
      window.scrollTo({ top: 0 });
      const { data } = await api.get(
        `/orders?limit=${limit}&page=${page}&tab=${tab}`,
      );
      setOrders(data.data);
    } catch (e: any) {
      closeLoading();
    } finally {
      closeLoading();
      executeScroll();
    }
  }, [setOrders, limit, page, closeLoading, showLoading, tab, executeScroll]);

  const nextPage = useCallback(() => {
    if (page === orders.pages) {
      return false;
    }
    setPage(page + 1);
  }, [orders, page]);

  const previousPage = useCallback(() => {
    if (page === 1) {
      return false;
    }
    setPage(page - 1);
  }, [page]);

  const getProducts = useCallback(async () => {
    try {
      const { data } = await api.get(
        `/search/products?page=1&order=popularity&limit=3`,
      );
      setProducts(data.products);
    } catch (e: any) {}
  }, []);

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setValue(newValue);
    setPage(1);
    switch (newValue) {
      case 0:
        setTab('all');
        break;

      case 1:
        setTab('delivered');
        break;

      case 2:
        setTab('inProgress');
        break;

      case 3:
        setTab('canceled');
        break;

      default:
        setTab('all');
        break;
    }
  };

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, tab]);

  return (
    <>
      <Title>Meus Pedidos</Title>
      <div className={classes.root}>
        <AppBar className={classes.bar} position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Ordenar pedidos pelo o status"
            className={classes.tabs}
          >
            <Tab className={classes.tab} label="TODOS" {...a11yProps(0)} />
            <Tab className={classes.tab} label="ENTREGUES" {...a11yProps(1)} />
            <Tab
              className={classes.tab}
              label="EM ANDAMENTO"
              {...a11yProps(2)}
            />
            <Tab
              className={`${classes.tab} ${classes.cancelTab}`}
              label="CANCELADOS"
              {...a11yProps(3)}
            />
          </Tabs>
        </AppBar>
        <ListOrders
          orders={orders}
          products={products}
          nextPage={nextPage}
          previousPage={previousPage}
          page={page}
          tab={tab}
        />
      </div>
    </>
  );
};

export default Main;
