import styled from 'styled-components';

export const Container = styled.div`
  padding: 32px 24px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  > h3 {
    font-family: var(--poppins);
    display:none;
    font-size: 20px;
    font-weight: 600;

    @media(min-width: 79.9375em){
      display: block;
    }
  }

  i{
    @media(min-width: 79.9375em){
      display: none;
    }
  }
`;

export const ButtonClear = styled.button`
  border: none;
  background-color: transparent;
  text-decoration: underline;
  font-size: 10px;
  color: var(--purple);

  cursor: pointer;
`;

export const Body = styled.div`
  margin-top: 8px;
  max-height: 245px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: var(--grey-light-3);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--grey-light-1);
  }

  > h4 {
    font-size: 14px;
    margin-bottom: 12px;
  }
`;

export const Check = styled.div`
  label {
    font-size: 14px;
  }

  margin-bottom: 13px;
`;

export const AccordionsContainer = styled.div`
  > div {
    margin-bottom: 18px;
  }
`;


export const InputPriceContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  > input {
    display: block;
    width: 80px;
    height: 39px;
    border: 1px solid var(--grey-light-2);
    outline: none;
    color: var(--grey-medium-1);
    font-size: 1rem;
    text-align: center;
  }

  > p {
    margin-right: 10px;

    &.ml {
      margin-left: 10px;
    }
  }
`;
