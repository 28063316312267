import React from 'react';
import {
  NavWrapper,
  Title,
  Nav,
  LinkStyled,
  AppStoreWrapper,
  GooglePlayWrapper,
  DownloadApp,
  SocialMedia,
  SocialMediaIcon,
} from './styles';
import { LineBreak } from '../styles';
import GooglePlay from '../../../assets/png/google-play.png';
import AppStore from '../../../assets/png/app-store.png';
import Instagram from '../../../assets/svg/instagram.svg';
import Facebook from '../../../assets/svg/facebook.svg';
import LinkedIn from '../../../assets/svg/linkedIn.svg';


const Navegation: React.FC = () => {
  return (
    <NavWrapper>
      <div>
        <Title>houpa!</Title>
        <Nav>
          <li>
            <LinkStyled to='/'>Home</LinkStyled>
          </li>
          <li>
            <LinkStyled to='/sobre'>Sobre o houpa</LinkStyled>
          </li>
        </Nav>
      </div>
      <div>
        <Title>
          houpa!
          <LineBreak className='tablet' /> Negócios
        </Title>
        <Nav>
          <li>
            <LinkStyled to='/para-negocios'>Sobre</LinkStyled>
          </li>
        </Nav>
      </div>
      <div>
        <Title>
          Informações
          <LineBreak className='tablet' /> úteis
        </Title>
        <Nav>
          <li>
            <LinkStyled to='/termos'>Termos de uso</LinkStyled>
          </li>
          <li>
            <LinkStyled to='/politicas-privacidade'>
              Politica de Privacidade
            </LinkStyled>
          </li>
        </Nav>
      </div>
      <div>
        <Title>Ajuda</Title>
        <Nav>
          <li>
            <LinkStyled to='/ajuda'>Tô aqui!</LinkStyled>
          </li>
        </Nav>
      </div>
      <div>
        <Title>Compre no atacado, baixe o houpa! negócios</Title>
        <DownloadApp>
          <AppStoreWrapper>
            <a
              href="https://apps.apple.com/br/app/houpa-neg%C3%B3cios/id1489513685"
              target="blank"
            >
              <img src={AppStore} alt='App store' />
            </a>
          </AppStoreWrapper>
          <GooglePlayWrapper>
            <a
              href="https://play.google.com/store/apps/details?id=com.houpabusiness.org&hl=pt_BR&gl=US"
              target="blank"
            >
              <img src={GooglePlay} alt='GooglePlay' />
            </a>
          </GooglePlayWrapper>
        </DownloadApp>
      </div>
      <div>
        <Title>Acompanhe nossas redes sociais</Title>
        <SocialMedia>
          <SocialMediaIcon>
            <a href='https://www.instagram.com/houpa.app/?hl=pt-br' target='blank'>
              <img src={Instagram} alt='Instagram logo' />
            </a>
          </SocialMediaIcon>
          <SocialMediaIcon>
            <a href='https://www.facebook.com/Houpa-App-106793408155811' target='blank'>
              <img src={Facebook} alt='Facebook logo' />
            </a>
          </SocialMediaIcon>
          <SocialMediaIcon>
            <a
              href='https://www.linkedin.com/company/houpa/mycompany/'
              target='blank'
            >
              <img src={LinkedIn} alt='LinkedIn logo' />
            </a>
          </SocialMediaIcon>
        </SocialMedia>
      </div>
    </NavWrapper>
  );
};

export default Navegation;
