import styled from 'styled-components';
import Button from '../../Button';
import { Slider } from '@material-ui/core';

export const Container = styled.div``;

export const Buttons = styled.div`
  position: relative;
  max-width: 416px;
  margin: 40px auto;
  display: flex;
  align-items: center;
  gap: 16px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    max-width: 295px;
    gap: 11px;
  }
  @media screen and (max-width: 607px) {
    max-width: 100%;
    gap: 8px;
  }
`;

export const CancelButton = styled(Button)`
  color: var(--purple);
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const CropWrapper = styled.div`
  position: relative;
  height: 398px;
  background-color: var(--grey-medium-1);
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    height: 283px;
  }
  @media screen and (max-width: 607px) {
    height: 360px;
  }
`;

export const NoImageContainer = styled.div`
  height: inherit;
  width: 100%;
  @media screen and (max-width: 607px) {
    padding: 24px;
  }
`;

export const NoImageWrapper = styled.div`
  height: inherit;
  max-width: 400px;
  margin: 0 auto;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px solid var(--grey-light-1);
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    max-width: 280px;
  }
  @media screen and (max-width: 607px) {
    max-width: 100%;
    height: 100%;
  }
`;

export const NoImageIcon = styled.img` 
  width: 100px;
  height: 100px;
  @media screen and (max-width: 1279px) {
    width: 86px;
    height: 86px;
  }
`;

export const ImageControls = styled.div`
  position: relative;
  padding: 20px 56px 0;
`;

export const ZoomWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 607px) {
    flex-direction: column-reverse;
    row-gap: 16px;
  }
`;

export const SliderStyled = styled(Slider)`
  max-width: 400px;
  span {
    background-color: var(--purple);
  }
  span:nth-child(2), span:first-child {
    height: 4px;
    border-radius: 4px;
  }
  span:first-child {
    background-color: var(--grey-light-1);
  }
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    max-width: 283px;
  }
  @media screen and (max-width: 607px) {
    max-width: 100%;
  }
`;

export const ChangeImage = styled.a`
  color: var(--purple);
  font-size: 16px;
  cursor: pointer;
  @media screen and (max-width: 1279px) {
    font-size: 10px;
  }
  @media screen and (max-width: 607px) {
    position: absolute;
    top: 5px;
    left: 0;
  }
`;

export const FileTypes = styled.p`
  position: absolute;
  line-height: 22px;
  top: 58px;
  font-size: 12px;
  color: var(--red-dark);
  @media screen and (max-width: 607px) {
    display: none;
  }
`;

export const FileTypesMobile = styled.p`
  display: none;
  line-height: 15.59px;
  font-size: 10px;
  text-align: center;
  color: var(--red-dark);
  margin-top: 10px;
  @media screen and (max-width: 607px) {
    display: block;
  }
`;

export const ResetIcon = styled.i`
  font-size: 20px;
  color: var(--grey-medium-1);
  cursor: pointer;
  @media screen and (max-width: 1279px) {
    font-size: 16px;
  }
  @media screen and (max-width: 607px) {
    font-size: 22px;
  }
`;