import React, {useCallback, useState} from 'react';
import {
  ModalContent,
  CloseModalIcon,
  FormContainer,
  Title,
  ButtonContainer,
} from './styles';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import useForm from '../../../../../hooks/useForm';
import Input, { RequiredTip } from '../../../../../components/Input';
import Button from '../../../../../components/Button';
import AlertModal from '../../../../../components/AlertModal';
import {api} from '../../../../../services/api';
import {handleErrors} from '../../../../../helpers/utils';

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      outline: 'none',
    },
    paper: {
      backgroundColor: 'var(--white)',
    },
  })
);

interface Props {
  open: boolean;
  setOpen: (arg: boolean) => void;
}

const PasswordModal: React.FC<Props> = ({ open, setOpen }) => {
  const [modalSuccess, setModalSuccess] = useState<boolean>(false);

  const classes = useStyles();
  const {
    form,
    error,
    setError,
    onChange,
    validateForm,
    onBlur,
    clearForm,
  } = useForm({
    password: {
      type: 'password',
      required: true,
      limit: 100,
    },
    newPassword: {
      type: 'password',
      required: true,
      limit: 100,
    },
    confirmPassword: {
      type: 'password',
      required: true,
      limit: 100,
    },
  });

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    if (form.newPassword !== form.confirmPassword) {
      error.confirmPassword = 'As senhas precisam ser iguais';
      setError({ ...error });

      return;
    }
    if (validateForm() && !error.password && !error.newPassword && !error.confirmPassword) {
      try {
        await api.post('change-password', form);

        setModalSuccess(true);
        handleClose();
        clearForm();
      } catch (e: any){
        setError(handleErrors(e, error));
      }
    }
  }, [clearForm, error, form, handleClose, setError, validateForm]);

  return (
    <div>
      <AlertModal
        open={modalSuccess}
        type="success"
        description="Senha alterada com sucesso!"
        submitText="Ok"
        onSubmit={() => setModalSuccess(false)}
        close={() => setModalSuccess(false)}
      />
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        BackdropProps={{
          timeout: 0,
        }}
      >
        <ModalContent className={classes.paper}>
          <CloseModalIcon onClick={handleClose}>
            <i className="icon icon-exit" />
          </CloseModalIcon>
          <Title>Altere sua senha</Title>
          <FormContainer onSubmit={onSubmit}>
            <Input
              title="Senha atual"
              value={form.password}
              onBlur={() => onBlur('password')}
              onChange={(e) => onChange('password', e)}
              error={error.password}
              type="password"
              required
              placeholder="********"
            />
            <Input
              title="Nova senha"
              value={form.newPassword}
              onBlur={() => onBlur('newPassword')}
              onChange={(e) => onChange('newPassword', e)}
              error={error.newPassword}
              type={'password'}
              required
              placeholder={'********'}
            />
            <Input
              title="Confirme sua senha"
              value={form.confirmPassword}
              onBlur={() => onBlur('confirmPassword')}
              onChange={(e) => onChange('confirmPassword', e)}
              error={error.confirmPassword}
              type="password"
              required
              placeholder="********"
            />
            <RequiredTip  removePadding/>
            <ButtonContainer>
              <Button
                className="btnWeb"
                color="var(--purple)"
                scale="scale-4"
                expand
              >
                Salvar alterações
              </Button>
              <Button
                className="btnDevice"
                color="var(--purple)"
                scale="scale-4"
                expand
              >
                Salvar
              </Button>
            </ButtonContainer>
          </FormContainer>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default PasswordModal;
