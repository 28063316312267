import React, {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
} from 'react';

import Button from '../Button';
import Product from './components/Product';

import {
  ButtonClose,
  Container,
  ModalContent,
  ModalHeader,
  ProductsContainer,
  Wrapper,
  ButtonContainer,
  Details
} from './styles';
import { api } from '../../services/api';
import { CartData } from '../../interfaces/productCart';
import { Link } from 'react-router-dom';
import { useToast } from '../../hooks/useToast';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const SideCart: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const [cartData, setCartData] = useState<CartData>();
  const { toast } = useToast();

  useEffect(() => {
    const getProducts = async () => {
      try {
        const pullCartData = await api.get('/carts');
        const { data } = pullCartData.data;
        setCartData(data);
      } catch(error) {
        console.log(error);
        setIsOpen(false);
        toast({ type: 'error', description: 'Não foi possível listar os produtos do carrinho' });
      }
    }
    getProducts();
  }, [setIsOpen, toast]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden';
    }
    return () => {
      document.body.style.overflowY = 'auto';
    }
  }, [isOpen]);

  return (
    <>
      <Container isOpen={isOpen}>
        <div
          className="backDrop"
          onClick={() => setIsOpen(!isOpen)}
        ></div>
        {cartData && (
          <div className="sideCart">
            <ModalHeader>
              <p>
                SEU CARRINHO
                <span>
                  (
                    {cartData.products.length}
                    {cartData.products.length > 1 ? ' itens' : ' item'}
                  )
                </span>
              </p>
              <ButtonClose onClick={() => setIsOpen(!isOpen)}>
                &#215;
              </ButtonClose>
            </ModalHeader>

            <ModalContent>
              <ProductsContainer>
                {cartData.products.map((product) => (
                  <Product
                    setCartData={setCartData}
                    product={product}
                    setIsOpen={setIsOpen}
                    key={product.productId}
                  />
                ))}
              </ProductsContainer>

              <Wrapper />

              <Details>
                <div>
                  <span>VOCÊ ECONOMIZOU</span>
                  <span className="text-muted">{cartData.discount}</span>
                </div>
                <div>
                  <span>
                    TOTAL
                  </span>
                  <span>{cartData.totalWithDiscount}</span>
                </div>
              </Details>

              <ButtonContainer>
                <Link to='/carrinho'>
                  <Button expand disabled={false}>
                    Finalizar compra
                  </Button>
                </Link>
                <Button outline expand onClick={() => setIsOpen(!isOpen)}>
                  Continuar comprando
                </Button>
              </ButtonContainer>
            </ModalContent>
          </div>
        )}
      </Container>
    </>
  );
};

export default SideCart;
