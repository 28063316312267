import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import Magnifier from 'react-magnifier';

import { ReactComponent as IconArrowNext } from '../../assets/svg/arrow-next.svg';
import { ReactComponent as IconArrowPrev } from '../../assets/svg/arrow-prev.svg';

import { ProductImages } from './styles';


interface UrlString {
  big: string;
  medium: string;
  mini: string;
}

export interface Image {
  id: string;
  urlStrings: UrlString;
}

interface OwnProps {
  smallColumnWidth: number;
  bigColumnWidth: number;
  images?: Image[];
  isFavorite?: boolean;
  onFavorite: () => void;
  mainImage?: string;
  isSoldOut?: [];
}

const ProductImagesContainer: FunctionComponent<OwnProps> = ({ smallColumnWidth, bigColumnWidth, images = [], onFavorite, mainImage, isFavorite, isSoldOut }) => {
  const [mainPicture, setMainPicture] = useState('');

  const setFavorite = useCallback(() => {
    onFavorite();
  }, [onFavorite]);

  useEffect(() => {
    if (mainImage) {
      setMainPicture(mainImage);
    }
  }, [mainImage]);

  let btnPrev;
  let btnNext;

  if (images.length > 0) {
    if (mainPicture) {
      const index = images.findIndex(image => image.urlStrings.big === mainPicture);

      if (index > 0) {
        btnPrev = <button className="arrow-button-prev" onClick={() => setMainPicture(images[index - 1].urlStrings.big)}>
          <IconArrowPrev />
        </button>;
      }

      if (index < images.length - 1) {
        btnNext = <button className="arrow-button-next" onClick={() => setMainPicture(images[index + 1].urlStrings.big)}>
          <IconArrowNext />
        </button>;
      }

    }
  }

  const removeNotSelected = (key: number) => {
    const image = document.getElementById(`image-${key}`);
    if (image) {
      image.classList.remove('image-not-selected');
      image.classList.add('image-selected');
      images.forEach((image, index) => {
        if (index !== key) {
          const imageNotSelected = document.getElementById(`image-${index}`);
          if (imageNotSelected) {
            imageNotSelected.classList.add('image-not-selected');
          }
        }
      });
    }
  };

  useEffect(() => {
    if (mainPicture) {
      const index = images.findIndex(image => image.urlStrings.big === mainPicture);
      if (index > -1) {
        removeNotSelected(index);
      } else {
        removeNotSelected(0);
      }
    }
  }, [mainPicture]);



  return (
    <ProductImages isFavorite={isFavorite} smallColumn={smallColumnWidth} bigColumn={bigColumnWidth}>
      <div className="all-images">
        {
          images.map((image, index) => (
            <div key={index} id={"image-" + index} className="small-image image-not-selected" onClick={() => { setMainPicture(image.urlStrings.big); removeNotSelected(index) }}>
              <img src={image.urlStrings.mini} alt="Imagem do produto" />
            </div>
          ))
        }
      </div>

      <div className="big-image-container image-sold-out">
        <button className="favorite-button" onClick={setFavorite}>
          <i className="icon icon-favorite" />
        </button>
        {/* <div className="alert-sold-out">
          <span>COR ESGOTADA</span>
        </div> */}

        <Magnifier src={mainPicture} />
        {btnPrev}
        {btnNext}
      </div>
    </ProductImages>
  );
};

export default ProductImagesContainer;
