import styled, {keyframes} from 'styled-components';
import {Modal} from '@material-ui/core';


const animeRight = keyframes`
  from {
    opacity: 1;
    transform: translateX(405px);
  }
`;

export const Container = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3000 !important;
`;

export const AddressModal = styled.div`
  position: relative;
  width: 849px;
  background: ${props => props.theme.colors.white};
  padding: 30px 0 43px;
  outline: 0;
  border-radius: 10px;

  @media (max-width: 1279px) {
    width: 583px;
  }

  @media (max-width: 607px) {
    width: 100%;
    height: 100%;
    border-radius: 0;

    transform: translateX(0);
    animation: ${animeRight} 0.2s ease-out;
    overflow-y: auto;
  }
`;

export const AddressContent = styled.div`
  width: 629px;
  margin: 0 auto;

  h1 {
    font-size: 30px;
    margin-bottom: 31px;

    @media (max-width: 607px) {
      font-size: 20px;
      margin: 32px 0;
    }
  }

  @media (max-width: 1279px) {
    width: 433px;
  }

  @media (max-width: 607px) {
    width: 310px;
  }
`;

export const ButtonClose = styled.button`
  position: absolute;
  top: 17px;
  right: 17px;
  outline: 0;
  background: transparent;
  border: none;

  font-size: 1rem;
  cursor: pointer;


  @media (max-width: 607px) {
    display: none;
  }
`;

export const AddressForm = styled.form`
  width: 100%;
  @media screen and (max-width: 607px) {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
`;

export const AddressRow = styled.div`
  display: flex;
  margin-bottom: 8px;

  @media (max-width: 607px) {
    &.mb-col {
      flex-direction: column;

      > div:nth-child(2) {
        margin-left: 0;
      }
    }

    &.reverse {
      flex-direction: row-reverse;

      > div:nth-child(2) {
        margin-left: 0;
        margin-right: 30px;
      }
    }
  }

`;

export const AddressCol = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &.flex-2 {
    flex: 2;
  }

  > a {
    color: ${props => props.theme.colors.black};
    font-weight: 500;
    margin-bottom: 15px;

    @media (max-width: 607px) {
      margin-top: 5px;
      text-align: right;
    }
  }

  &:nth-child(2) {
    margin-left: 15px;
  }


  @media (max-width: 607px) {
    &:nth-child(2) {
      margin-left: 30px;
    }

    &.mb-flex-2 {
      flex: 2
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 28px 0 14px;
`;

export const CheckboxContainer = styled.div`
  label {
    font-size: 14px;
  }
`;

export const ButtonTrash = styled.div`
  background: transparent;
  border: none;
  outline: 0;
  display: flex;
  font-size: 14px;
  cursor: pointer;

  > span {
    font-family: Roboto-Medium, Poppins-Medium, serif;
    font-weight: 500;
    text-decoration: underline;
    margin-left: 12px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin : 31px 0 15px;
`;

export const ButtonBack = styled.button`
  display: none;
  outline: none;
  background: transparent;
  align-items: center;
  font-size: 1rem;
  outline: 0;
  cursor: pointer;

  > i {
    margin-right: 5px;
    color: ${({theme}) => theme.colors.purple};
  }

  @media (max-width: 607px) {
    display: flex;
  }
`;
