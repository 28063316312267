import styled from 'styled-components';

interface Props {
  direction?: string;
}

export const Main = styled.section`
  @media (max-width: 1279px) {
    padding-top: 10px;
  }
  @media (max-width: 607px) {
    padding-top: 25px;
  }
`;

export const Container = styled.div`
  width: 100%;
  background: var(--grey-light-3);
  margin-top: 52px;
  border-radius: 10px;
  border: 1px solid var(--grey-light-2);

  @media (max-width: 1279px) {
    margin: 75px 0 32px;
  }

  @media (max-width: 607px) {
    max-width: 312px;
    margin: 24px 0;
  }
`;

export const PanelInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 25px 32px;
  border-bottom: 1px solid var(--grey-light-2);

  @media (max-width: 607px) {
    padding: 16px;
    flex-direction: column;
    align-items: stretch;
  }
`;

export const PanelCol = styled.div`
  display: flex;
  flex-direction: column;

  &.mr {
    margin-right: 64px !important;
  }

  &:first-child {
    .titleFirst {
      color: var(--grey-dark);
    }

    .textInfo {
      font-size: 20px;
      font-weight: 700;

      @media (max-width: 1279px) {
        font-size: 12px;
      }
    }

    @media (max-width: 607px) {
      flex-direction: row;
      width: 100%;
      justify-content: space-between;

      .titleFirst {
        color: var(--grey-dark);
        margin-bottom: 0;
      }
    }
  }

  .titleFirst {
    color: var(--grey-medium-1);
    margin-bottom: 6px;
    font-size: 16px;

    @media (max-width: 1279px) {
      font-size: 10px;
    }
  }

  .textInfo {
    font-size: 16px;
    color: var(--grey-dark);
    font-size: 14px;

    @media (max-width: 1279px) {
      font-size: 10px;
    }
  }

  .subtitle {
    font-family: Roboto-Medium, Poppins-Medium, Arial, serif;
    margin-bottom: 6px;
    font-size: 16px;

    @media (max-width: 1279px) {
      font-size: 12px;
    }
  }

  @media (max-width: 607px) {
    margin-bottom: 10px;

    &.mr {
      margin-right: 0;
    }
  }
`;

export const StepsContainer = styled.div`
  background: var(--grey-light-3);
  border-bottom: 1px solid var(--grey-light-2);
  padding: 38px 75px 52px;
  font-family: var(--poppins);

  > div h1 {
    font-size: 30px;
    margin-bottom: 20px;
    text-align: center;
    font-family: var(--poppins);

    @media (max-width: 1279px) {
      font-size: 20px;
    }

    @media (max-width: 607px) {
      font-size: 16px;
      padding-top: 40px;
    }
  }

  > p {
    color: var(--grey-dark);
    text-align: center;

    @media (max-width: 1279px) {
      font-size: 14px;
    }

    @media (max-width: 607px) {
      font-size: 12px;
    }
  }

  > h3 {
    color: var(--grey-dark);
    text-align: center;
    margin: 52px 0;
    font-family: var(--poppins);
    &.paymentStatus {
      margin: 24px 0;
    }
    &.canceled {
      color: var(--red-dark);
      margin: 24px 0 40px;
    }

    @media (max-width: 1279px) {
      font-size: 14px;
      margin: 32px 0;
    }
  }

  @media (max-width: 1279px) {
    padding: 35px 27px 45px;
  }

  @media (max-width: 607px) {
    padding: 35px 0 45px;
  }
`;

export const ResumeCard = styled.div`
  width: 100%;

  background-color: var(--grey-light-3);
  border-bottom: 1px solid var(--grey-light-2);

  padding: 30px 54px 35px;

  h3 {
    font-weight: 400;
    font-size: 1.25rem;
    color: var(--grey-dark);
    margin-bottom: 20px;

    @media (max-width: 1279px) {
      font-size: 14px;
    }

    @media (max-width: 607px) {
      font-size: 11px;
    }
  }

  @media (max-width: 607px) {
    padding: 16px 16px 22px;
  }
`;

export const ResumeContent = styled.div`
  display: flex;
  justify-content: space-between;

  h3 {
    font-size: 20px;
  }

  > div:last-child {
    width: 400px;

    @media (max-width: 1279px) {
      width: 245px;
    }
  }

  .divider {
    width: 1px;

    background-color: var(--grey-light-2);
    border: 1px solid var(--grey-light-2);
  }

  @media (max-width: 1279px) {
    .divider {
      display: none;
    }

    > div:last-child {
      width: auto;
    }

    > div {
      flex: 1;
    }
  }
`;

export const ResumeText = styled.p`
  color: var(--grey-dark);
  line-height: 18.75px;
  font-size: 16px;

  &.mt {
    margin-top: 8px;
  }

  &.s14 {
    font-size: 14px;

    @media (max-width: 1279px) {
      font-size: 10px;
    }
  }

  > span {
    font-weight: 700;
  }

  @media (max-width: 1279px) {
    font-size: 10px;
  }
`;

export const ResumeRow = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: ${({ direction }) => direction ?? 'space-between'};
`;

export const DividerH = styled.div`
  height: 1px;
  width: 100%;

  margin: 31px 0 21px;

  background-color: var(--grey-light-2);
  border: 1px solid var(--grey-light-2);
`;

export const ResumeButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;

  > button {
    max-width: 186px;
  }
`;

export const ProductsContainer = styled.div`
  margin-top: 32px;
  > h4 {
    font-size: 20px;
    margin-bottom: 16px;

    @media (max-width: 1279px) {
      margin-bottom: 12px;
    }
  }

  > button {
    display: block;
    background-color: transparent;
    border: none;
    color: var(--purple);
    margin: 32px auto 0;
    cursor: pointer;
    font-weight: 700;
  }

  @media (max-width: 607px) {
    padding: 16px;
  }
`;

export const ProductsContent = styled.div`
  width: 100%;
  padding-bottom: 8px;

  &:last-child {
    border-bottom: 0;
  }
  @media screen and (max-width: 607px) {
    padding: 0 19px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0 20px;
`;

export const Text = styled.span`
  display: block;
  font-size: 16px;
  line-height: 20px;
  max-width: 730px;
  text-align: center;
  margin: 0 auto 32px;
  color: var(--grey-dark);
  @media screen and (max-width: 1279px) {
    font-size: 12px;
    line-height: 13px;
    max-width: 492px;
  }
  @media screen and (max-width: 607px) {
    max-width: 280px;
  }
`;

export const OrderStage = styled.div`
  position: relative;
`;

export const SelectWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0px;
  width: 170px;
  label select {
  }
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    right: 0px;
    width: 150px;
  }
  @media screen and (max-width: 607px) {
    left: 24px;
    top: -25px;
    width: 85%;
    label span {
      font-size: 12px;
    }
    label select {
      font-size: 12px;
    }
  }
`;

export const ProductWrapper = styled.div`
  background-color: var(--grey-light-3);
  border-radius: 6.11px;
  margin-bottom: 40px;
`;

export const ProductInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  padding: 18px 24px;
  border-bottom: 2px solid var(--grey-light-2);
  @media screen and (max-width: 1279px) {
    padding: 11px 15px;
  }
  @media screen and (max-width: 607px) {
    padding: 5px 19px;
  }
`;

export const Product = styled.p`
  display: none;
  color: var(--grey-dark);
  font-size: 7.33px;
  font-family: var(--roboto);
  font-weight: 700;
  @media screen and (max-width: 607px) {
    display: block;
  }
`;

export const SellerName = styled(Product)`
  display: block;
  font-weight: 400;
  strong {
    font-family: var(--roboto);
  }
  @media screen and (min-width: 607px) {
    font-size: 10px;
  }
  @media screen and (min-width: 1279px) {
    font-size: 16px;
  }
`;

export const Icon = styled.i`
  &.icon-success {
    color: var(--green);
  }
  &.icon-exit {
    color: var(--red-medium);
  }
`;
