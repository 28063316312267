import styled from 'styled-components';

interface Props {
    progress?: boolean;
    color?: string;
    link?: string;
    scale?: string;
    expand?: boolean;
    outline?: boolean;
}

export const Container = styled.button<Props>`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${props =>
    props.outline || props.link
      ? 'transparent'
      : props.color ?? 'var(--black)'};

  color: ${props =>
    props.outline
      ? props.color ?? 'var(--black)'
      : props.color === 'var(--yellow-dark)'
        ? 'var(--purple-business)'
        : props.color === 'var(--grey-light-2)'
          ? 'var(--grey-dark)'
          : props.color === 'var(--white)'
            ? 'var(--black)'
            : props.link === 'b2c'
              ? 'var(--purple)'
              : props.link === 'b2b'
                ? 'var(--purple-business)'
                : 'var(--white)'};

  border: 1px solid ${props => (props.link ? 'var(--white)' : props.color ?? 'var(--black)')};

  padding: ${props =>
    props.scale === 'scale-0' ? '4px 12px' : props.scale === 'scale-1' ? '8px 12px' : props.scale === 'scale-2' ? '7px 16px 8px' : props.scale === 'scale-3' ? '9px 16px 10px' : props.scale === 'scale-4' ? '13px 24px 14px' : props.scale === 'scale-5' ? '18px 24px' : '8px 12px'};

  width: ${props => (props.expand ? '100%' : 'auto')};

  font-size: ${props =>
    props.scale === 'scale-0' ? '10px' : props.scale === 'scale-1' ? '12px' : props.scale === 'scale-2' || props.scale === 'scale-3' || props.scale === 'scale-4'  ? '16px'  : props.scale === 'scale-5' ? '18px' : '12px'};

  outline: 0;

  /* cursor: pointer; */
  border-radius: 4px;

  > i {
    margin-right: 10px;
  }

  &:disabled {
    cursor: ${props => (props.progress ? 'progress' : 'pointer')};
    background-color: ${props =>
    props.outline ? 'transparent' : 'var(--grey-light-1)'};
    border-color: var(--grey-light-1);
    color: ${props =>
    props.outline ? 'var(--grey-light-1)' : 'var(--grey-medium-3)'};

    transition: 0.3s;
  }

  &:hover,
  &:focus {
    background-color: ${props =>
    props.outline
      ? 'transparent'
      : props.color === 'var(--purple)'
        ? 'var(--houpa-purple-hover)'
        : props.color === 'var(--purple-business)'
          ? 'var(--houpa-business-purple-hover)'
          : props.color === 'var(--blue)'
            ? 'var(--blue-hover)'
            : props.color === 'var(--green)'
              ? 'var(--green-hover)'
              : props.color === 'var(--red-dark)'
                ? 'var(--red-dark-hover)'
                : props.color === 'var(--yellow-dark)'
                  ? 'var(--yellow-dark-hover)'
                  : props.color === 'var(--grey-light-2)'
                    ? 'var(--grey-light-hover-1)'
                    : props.color === 'var(--white)'
                      ? 'var(--white-hover)'
                      : ''};
  }

  cursor: pointer;
`;


// padding: ${props =>
//         props.scale === "scale-2" || props.scale === "scale-3"
//                 ? "0px 16px"
//                 : props.scale === "scale-4" || props.scale === "scale-5"
//                 ? "0px 24px"
//                 : "0px 12px"};
