import React from 'react';
import Accordion from '../../../components/Accordion';
import {CardBody, CardHeader, CardInfo, CodeBlock, Container, Content} from '../ProductCardExample/styles';

const Accordions: React.FC = () => {

  return (
    <>
      <Container>
        <Content>
          <h2>Accordion</h2>
          <p></p>
          <CardInfo>
            <CardHeader>
              <Accordion title={'FEMININO'}>
                <div style={{padding: 10}}>
                  <p>Corpo do Accordion</p>
                </div>
              </Accordion>
            </CardHeader>
            <CardBody>
              <p>import Accordion from "components/Accordion"</p>
              <CodeBlock>
                <h1>Accordion</h1>
                <pre>{`
                <Accordion title={'FEMININO'}>
                  <div style={{padding: 10}}>
                    <p>Corpo do Accordion</p>
                  </div>
                </Accordion>
              `}</pre>
              </CodeBlock>
            </CardBody>
          </CardInfo>
        </Content>
      </Container>
    </>
  );
};

export default Accordions;
