import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import BackButton from '../../../../components/BackButton';
import {
  Container,
  DividerH,
  PanelCol,
  PanelInfo,
  ProductsContainer,
  ProductsContent,
  ResumeButtonContainer,
  ResumeCard,
  ResumeContent,
  ResumeRow,
  ResumeText,
  StepsContainer,
  OrderStage,
  SelectWrapper,
  ProductWrapper,
  ProductInfo,
  SellerName,
  Icon,
} from './styles';
import OrderProductCard from '../components/OrderProductCard';
import StepBar from '../../../../components/StepBar';
import { Main } from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import Snackbar from '../../../../components/Snackbar';
import ReplacementModal, {
  RequestReplacementInterface,
} from '../../Replacement/Replace/ReplaceDetails/components/ReplacementModal';
import { api } from '../../../../services/api';
import { useLoading } from '../../../../hooks/useLoading';
import { useAlert } from '../../../../hooks/useAlert';
import Select from '../../MyRegister/components/Select';
import { addDays, isBefore } from 'date-fns';
import Button from '../../../../components/Button';

export interface OrderDetailsInterface {
  order_number: number;
  quantity: number;
  total: string;
  payment_method: string;
  paymentMethodWithInstallments: string;
  paymentLink: string | null;
  order_status: string;
  request_order: string;
  order_delivered: string;
  receiver: string;
  street: string;
  number: string;
  complement: string;
  district: string;
  city: string;
  state: string;
  zipcode: string;
  stage: number;
  stageTitle: string;
  email: string;
  cellphone: string;
  freight: string;
}

interface OrderStageInterface {
  title: string;
  subtitle: string;
  date: string;
  step: number;
  isCanceled: boolean;
}

interface OrderItemsInterface {
  hasExchangeOrReturns: number;
  hasExchangedOrReturneds: number;
  message: string;
  brands?: BrandInterface[];
}

interface BrandInterface {
  brand_id: number;
  brand_name: string;
  profile_url: string;
  products: ProductsInterface[];
}

export interface ProductsInterface {
  SKU: string;
  brand: string;
  item_id: number;
  product_color: string;
  product_image: string;
  product_name: string;
  product_size: string;
  product_slug: string;
  product_status: number;
  quantity: number;
  subtotal: string;
  unit_price: string;
  exchangeReturnRequested: number;
  exchangeReturnType?: number;
  exchangeReturnStatus?: number;
  exchangeReturnDate?: string;
  reasons?: string;
}

export interface ReasonsAndTypesInterface {
  reasons: ReasonsInterface[];
  types: TypesInterface[];
}

interface TypesInterface {
  id: number;
  name_type: string;
}

interface ReasonsInterface {
  id: number;
  name_reason: string;
}

type ReasonType = {
  id: number;
  name_reason: string;
};

const Details: React.FC = () => {
  const { showLoading, closeLoading } = useLoading();
  const { showAlert } = useAlert();
  const { id } = useParams();
  const page = useRef<number>(1);
  const selectedProduct = useRef(null);
  const [selectError, setSelectError] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState<string>('');
  const [brands, setBrands] = useState<{ id: number; name: string }[]>([]);
  const [order, setOrder] = useState<OrderDetailsInterface>(
    {} as OrderDetailsInterface,
  );

  const exchangeEnabled = useMemo(() => {
    if (!order.order_delivered) {
      return false;
    }

    const [day, month, year] = order.order_delivered.split('/');
    const deliveredDate = new Date(`${year}-${month}-${day}`);
    const ninetyDays = addDays(deliveredDate, 90);

    return isBefore(new Date(), ninetyDays);
  }, [order]);

  const [orderItems, setOrderItems] = useState<OrderItemsInterface>(
    {} as OrderItemsInterface,
  );
  const [orderStage, setOrderStage] = useState<OrderStageInterface>(
    {} as OrderStageInterface,
  );
  const [reasonsAndTypes, setReasonsAndTypes] =
    useState<ReasonsAndTypesInterface>({} as ReasonsAndTypesInterface);

  const getReasonsAndTypes = useCallback(async (order) => {
    try {
      const { data } = await api.get(`/exchange-returns`);

      data.data.reasons = data.data.reasons.filter((reason: ReasonType) => {
        if (order.order_delivered) {
          const [day, month, year] = order.order_delivered.split('/');
          const deliveredDate = new Date(`${year}-${month}-${day}`);
          const sevenDays = addDays(deliveredDate, 7);

          if (!isBefore(new Date(), sevenDays)) {
            return reason.id !== 2 && reason.id !== 3;
          }
        }

        return reason;
      });

      setReasonsAndTypes(data.data);
    } catch (e: any) {}
    // eslint-disable-next-line
  }, []);

  const getOrderDetails = useCallback(async () => {
    try {
      const { data } = await api.get(`/orders/details/${id}`);
      setOrder(data.data);
      getReasonsAndTypes(data.data);
    } catch (e: any) {}
    // eslint-disable-next-line
  }, [id]);

  const navigate = useNavigate();

  const getOrderItems = useCallback(async () => {
    try {
      showLoading();
      const { data } = await api.get(`/orders/details/items/${id}`);
      setOrderItems(data.data);
      const brands = data.data.brands.map((brand: BrandInterface) => {
        return {
          id: brand.brand_id,
          name: brand.brand_name,
        };
      });
      setBrands(brands);
      setSelectedBrand(brands[0].id);
    } catch (e: any) {
      closeLoading();
    } finally {
      closeLoading();
    }
    // eslint-disable-next-line
  }, [id, page]);

  const getOrderStage = useCallback(async () => {
    try {
      if (selectedBrand !== '') {
        showLoading();
        const { data } = await api.get(
          `/orders/details/stage/${id}?brand_id=${selectedBrand}`,
        );
        setOrderStage(data.data);
        closeLoading();
      }
    } catch (e: any) {
      closeLoading();
    }
    // eslint-disable-next-line
  }, [selectedBrand]);

  const handleOpenModal = useCallback(
    (product = null) => {
      if (!modalOpen && product) {
        selectedProduct.current = product;
      }

      setModalOpen(!modalOpen);
    },
    [modalOpen],
  );

  const handleRequestReplacement = useCallback(
    async (req: RequestReplacementInterface) => {
      try {
        showLoading();
        await api.post(`/exchange-returns`, req);
        await getOrderItems();
        showAlert({
          description:
            'Recebemos sua solicitação!\n Fique de olho no seu e-mail para dar continuidade ao pedido.',
          type: 'success',
          submitText: 'Ok',
        });
      } catch (e: any) {
        closeLoading();

        if (!e.message) {
          return;
        }

        const description =
          typeof e.message === 'string' ? e.message : e.message[0].message;

        showAlert({
          description,
          type: 'error',
          submitText: 'Ok',
        });
      } finally {
        closeLoading();
      }
    },
    // eslint-disable-next-line
    [],
  );

  useEffect(() => {
    getOrderDetails();
    getOrderItems();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getOrderStage();
    // eslint-disable-next-line
  }, [selectedBrand]);

  return (
    <Main>
      <BackButton onClick={() => navigate('/minha-conta/pedidos')}>
        Voltar para meus pedidos
      </BackButton>
      <Container>
        <PanelInfo>
          <PanelCol>
            <p className={'titleFirst'}>Nº do pedido</p>
            <p className={'textInfo'}>{order?.order_number}</p>
          </PanelCol>
          <PanelCol>
            <p className={'titleFirst'}>Resumo da compra</p>

            <p className={'textInfo'}>Data do pedido: {order?.request_order}</p>
            <p className={'textInfo'}>
              Qtd.: {order?.quantity} {order?.quantity === 1 ? 'item' : 'itens'}
            </p>
            <p className={'textInfo'}>Valor Total: {order?.total}</p>
            <p className={'textInfo'}>
              Forma de Pagamento: {order?.payment_method}
            </p>
          </PanelCol>
          <PanelCol className="mr">
            <p className={'titleFirst'}>Status do pedido</p>
            <p className={'subtitle'}>
              {order?.stageTitle}
              {order.stage === 1 && <Icon className="icon icon-success" />}
              {order.stage === 2 && <Icon className="icon icon-exit" />}
            </p>
            {order?.order_delivered && (
              <p className={'textInfo'}>
                Entrega feita em: {order?.order_delivered}
              </p>
            )}
          </PanelCol>
        </PanelInfo>

        <StepsContainer>
          <OrderStage>
            <h1>{orderStage.title}</h1>
            <SelectWrapper>
              <Select
                label="Marcas"
                selectError={selectError}
                setselectError={setSelectError}
                options={brands}
                disabledOption="selecione uma marca"
                selectedValue={selectedBrand}
                setSelectedValue={setSelectedBrand}
                required={false}
              />
            </SelectWrapper>
          </OrderStage>
          <p>{orderStage.date}</p>
          <h3>{orderStage.subtitle}</h3>

          <StepBar canceled={orderStage.isCanceled} active={orderStage.step} />
        </StepsContainer>

        <ResumeCard>
          <ResumeContent>
            <div>
              <h3>Endereço de entrega</h3>

              <ResumeText>
                <p>{order?.receiver}</p>
                <p>
                  {order?.street}, {order?.number}
                </p>
                <p>{order?.complement}</p>
                <p>{order?.district}</p>
                <p>
                  {order?.city} - {order?.state}
                </p>
                <p>CEP {order?.zipcode}</p>
              </ResumeText>
            </div>
            <div className={'divider'} />
            <div>
              <h3>Resumo do pedido</h3>

              <ResumeRow>
                <ResumeText>
                  {order?.quantity}{' '}
                  {order?.quantity === 1 ? 'produtos' : 'Produtos'}
                </ResumeText>
                <ResumeText>{order?.total}</ResumeText>
              </ResumeRow>
              <ResumeRow>
                <ResumeText>Frete</ResumeText>
                <ResumeText>{order.freight}</ResumeText>
              </ResumeRow>
              <DividerH />

              <ResumeRow>
                <ResumeText>Total</ResumeText>
                <ResumeText>{order?.total}</ResumeText>
              </ResumeRow>

              <ResumeRow direction={'flex-end'}>
                <ResumeText className={'s14'}>
                  {order?.paymentMethodWithInstallments
                    ? order?.paymentMethodWithInstallments
                    : order?.payment_method}
                </ResumeText>
              </ResumeRow>
            </div>
          </ResumeContent>

          {orderStage.step === 0 && order.paymentLink && (
            <ResumeButtonContainer>
              <Button
                onClick={() => window.open(order.paymentLink ?? '')}
                scale={'scale-3'}
                color={'var(--purple)'}
                expand
                outline
              >
                Gerar Boleto
              </Button>
            </ResumeButtonContainer>
          )}

          <ResumeButtonContainer>
            {(orderItems.hasExchangeOrReturns > 0 ||
              orderItems.hasExchangedOrReturneds > 0) && (
              <Snackbar>{orderItems.message}</Snackbar>
            )}
          </ResumeButtonContainer>
        </ResumeCard>
      </Container>

      <ProductsContainer>
        <h4>Produtos</h4>
        {orderItems.brands?.map((brand) => (
          <ProductWrapper key={brand.brand_id}>
            <ProductInfo>
              <SellerName>
                Vendido e entregue por <strong>{brand.brand_name}</strong>
              </SellerName>
            </ProductInfo>
            {brand.products.map((product) => (
              <ProductsContent key={product.item_id}>
                <OrderProductCard
                  exchangeEnabled={exchangeEnabled}
                  isCanceled={orderStage.isCanceled}
                  onReplace={() => handleOpenModal(product)}
                  product={product}
                  brand={brand.brand_name}
                  profileUrl={brand.profile_url}
                />
              </ProductsContent>
            ))}
          </ProductWrapper>
        ))}
      </ProductsContainer>

      {modalOpen && (
        <ReplacementModal
          open={modalOpen}
          orderId={order?.order_number}
          onClose={handleOpenModal}
          onSave={handleRequestReplacement}
          product={selectedProduct.current}
          freightData={order}
          reasonsAndTypes={reasonsAndTypes}
        />
      )}
    </Main>
  );
};

export default Details;
