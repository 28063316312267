import React from 'react';
import {
  CardInfo,
  CardHeader,
  CardBody,
  Content,
  Container,
  Wraper,
  CodeBlock,
} from './styles';

import Card from '../../../components/Card';

const Cards: React.FC = () => {
  const addressParams = {
    dweller: 'Gabriel Barbosa de Almeida',
    street: 'Rua logo ali',
    number: '12',
    city: 'SP',
    neighborhood: 'Ali Perto',
    floor: '3 andar',
    apartmentNumber: 'casa 123',
    state: 'São Paulo',
    zipCode: 54423312218,
  };

  const paymentParams = {
    lastNumbers: '1234',
    cardBaneer: 'MasterCard',
    shelfLife: '12/2028',
    cardBannerImg:
      'https://marcas-logos.net/wp-content/uploads/2020/03/Mastercard-Logo-1.png',
    cardBannerAlt: 'Logo mastercard',
  };

  return (
    <Container>
      <Content>
        <h2>Cards de endereço e pagamento</h2>
        <p></p>
        <CardInfo>
          <CardHeader>
            <Card
              addNewCard={true}
              text="Novo Cartão"
              onAdd={() => console.log('adicionado')}
            />

            <Card
              headerTitle="Principal"
              type="address"
              onDelete={() => console.log('Deletado')}
              onEdit={() => console.log('Editado')}
              params={addressParams}
              onCheck={() => {
                console.log('checked');
              }}
              checked={true}
            />
            <Card
              headerTitle="Cartão 1"
              type="payment"
              params={paymentParams}
              checked={true}
              onDelete={() => console.log('Deletado')}
            />
          </CardHeader>
          <CardBody>
            <p>import Card from "components/Card"</p>
            <CodeBlock>
              <h1>Novo Cartão / Endereço:</h1>
              <pre>
                {`
                  <Card
                    onCheck={() => {}}
                    addNewCard={true}
                    text="Novo Cartão"
                    onAdd={() =>{}}
                  />`}
              </pre>
            </CodeBlock>
            <Wraper>
              <CodeBlock>
                <h1>Card de endereço:</h1>
                <pre>
                  {`
                <Card
                  headerTitle="Principal"
                  type="address"
                  onDelete={() => {}}
                  onEdit={() =>{}}
                  params={Dados em formato JSON}
                  onCheck={() => {}}
                  checked={true}
                />
                `}
                </pre>
              </CodeBlock>
              <CodeBlock>
                <h1>Dados Recebidos em params</h1>
                <pre>
                  {`
                  params = {
                    dweller: "Gabriel Barbosa de Almeida",
                    street: "Rua logo ali",
                    number: "12",
                    city: "SP",
                    neighborhood: "Ali Perto",
                    floor: "3 andar",
                    apartmentNumber: "casa 123",
                    state: "São Paulo",
                    zipCode: 54423312218,
                  }
                `}
                </pre>
              </CodeBlock>
            </Wraper>
            <Wraper>
              <CodeBlock>
                <h1>Card de Forma de pagamento:</h1>
                <pre>
                  {`
                  <Card
                    headerTitle="Cartão 1"
                    type="payment"
                    params={Dados em formato JSON}
                    onCheck={() => {}}
                    onDelete={() => {}}
                  />
                `}
                </pre>
              </CodeBlock>
              <CodeBlock>
                <h1>Dados Recebidos em params</h1>
                <pre>
                  {`
                  params = {
                    lastNumbers: "1234",
                    cardBaneer: "MasterCard",
                    shelfLife: "12/2028",
                    cardBannerImg:
                      "https://marcas-logos.net/wp-content/uploads/2020/03/Mastercard-Logo-1.png",
                    cardBannerAlt: "Logo mastercard",
                  }
                `}
                </pre>
              </CodeBlock>
            </Wraper>
          </CardBody>
        </CardInfo>
      </Content>
    </Container>
  );
};

export default Cards;
