import styled, {keyframes} from 'styled-components';
import {Modal} from '@material-ui/core';

export const Container = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonClose = styled.button`
  width: 24px;
  height: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 24px;
  right: 24px;

  cursor: pointer;

  i {
    font-size: 20px;
    color: ${props => props.theme.colors.greyLight1};
  }

  @media (max-width: 607px) {
    display: none;
  }
`;

const animeRight = keyframes`
  from {
    opacity: 1;
    transform: translateX(405px);
  }
`;

export const Body = styled.div`
  width: 100%;
  max-height: 90%;
  padding-bottom: 20px;

  @media (min-width: 37.9375em) {
    max-width: 855px;
  }

  background: ${(props) => props.theme.colors.white};
  outline: none;

  display: flex;
  flex-direction: column;

  position: relative;

  border-radius: 16px;

  .back-button {
    display: none;
  }

  .title {
    font-size: 24px;
    font-family: Roboto-Bold;

    margin: 50px 100px;
  }

  .wrapper {
    overflow-y: auto;
    @media (min-width: 608px) {
      padding: 0 100px;
    }

    @media (max-width: 607px) {
      padding: 0 10px;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: var(--grey-light-3);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: var(--grey-light-1);
    }

    .product {
      display: flex;

      margin-bottom: 50px;

      .product-image {
        width: 92px;
        height: 120px;
        margin-right: 18px;

        > img {
          width: 100%;
          height: 100%;
        }
      }

      .product-info {
        display: flex;
        flex-direction: column;
        flex: 1;
        line-height: 18px;

        p {
          font-size: 14px;

          &.mb16 {
            margin-bottom: 16px;
          }

          .product-title {
            font-weight: 700;
          }

          .order-number {
            margin-left: 24px;
            font-size: 12px;

            @media (min-width: 608px) {
              font-size: 18px;
            }
          }
        }
      }
    }

    .form {
      display: flex;
      flex-direction: column;

      .subtitle {
        font-size: 18px;
        font-family: Roboto-Bold;

        margin-bottom: 24px;
      }

      .radio-group {
        margin-bottom: 45px;

        span {
          font-size: 14px;
        }
      }

      .select-container {
        margin-bottom: 86px;

        label {
          font-size: 16px;
          font-family: Roboto-Bold;
        }
      }

      .text-area-container {
        margin-bottom: 58px;

        > label {
          font-size: 16px;
          font-family: Roboto-Medium;
          display: flex;
          flex-direction: column;

          textarea {
            @media (min-width: 607px) {
              max-width: 456px;
            }

            width: 100%;
            height: 136px;

            margin-top: 17px;

            border: 1px solid ${(props) => props.theme.colors.greyLight1};
            border-radius: 8px;
            box-sizing: border-box;
            resize: none;
          }
        }
      }

      .footer {
        width: 200px;
        height: 40px;
        margin: 0 100px 100px 0;
      }
    }
  }

  @media (max-width: 1279px) and (min-width: 608px) {
    width: 85%;
    max-height: 95%;

    .title {
      font-size: 16px;
      margin: 44px 77px;
    }

    .wrapper {
      padding-left: 77px;

      .product {
        margin-bottom: 50px;

        .product-image {
          width: 64px;
          height: 83.2px;

          margin-right: 15px;
        }

        .product-info {
          display: flex;
          flex-direction: column;
          flex: 1;

          > p {
            font-size: 14px;
          }
        }
      }

      .form {
        .subtitle {
          font-size: 14px;

          margin-bottom: 20px;
        }

        .radio-group {
          margin-bottom: 35px;
        }

        .select-container {
          margin-bottom: 54px;

          label {
            font-size: 14px;
          }
        }

        .text-area-container {
          > label {
            font-size: 14px;
          }
        }

        .footer {
          width: 168px;
          height: 32px;
          margin-bottom: 50px;
        }
      }
    }
  }

  @media (max-width: 607px) {
    width: 100%;
    max-height: 100%;
    height: 100%;

    padding: 20px;

    border-radius: 0;

    transform: translateX(0);
    animation: ${animeRight} 0.2s ease-out;

    .back-button {
      display: flex;
      align-items: center;

      i {
        font-size: 18px;
        color: ${(props) => props.theme.colors.purple};
        margin-right: 15px;
      }

      span {
        font-size: 16px;
      }
    }

    .title {
      font-size: 16px;
      margin: 32px 0;
    }

    .wrapper {
      padding-left: 0;

      .product {
        margin-bottom: 40px;

        .product-image {
          width: 42px;
          height: 54.6px;

          margin-right: 15px;
        }

        .product-info {
          > p {
            font-size: 10px;
          }
        }
      }

      .form {
        .subtitle {
          font-size: 10px;
        }

        .radio-group {
          margin-bottom: 32px;

          span {
            font-size: 10px;
          }
        }

        .select-container {
          margin-bottom: 34px;

          label {
            font-size: 10px;
          }
        }

        .text-area-container {
          margin-bottom: 0;

          > label {
            font-size: 10px;

            textarea {
              width: 100%;
              height: 80px;

              margin-top: 12px;
            }
          }
        }

        .footer {
          margin: 32px auto;
        }
      }
    }
  }
`;

export const DeliveryData = styled.div`
  margin: 20px 0;

  h1{
    font-size: 16px;
    margin-bottom: 20px;
  }

  p{
    margin: 5px 0;
    font-size: 12px;
  }
`;

export const RequiredTipContainer = styled.div`
    position: relative;
    top: -30px;
    @media screen and (max-width: 607px) {
      top: 10px;
    }
`;

export const TipChar = styled.span`
  color: var(--red-dark);
  font-weight: normal;
`;