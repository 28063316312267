import React from 'react';
import { Header, List } from './styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const Icons: React.FC = () => {
  const [open, setOpen] = React.useState(false);

  const CopyIcon = (e: React.MouseEvent<HTMLElement>) => {
    const className = e.currentTarget.firstElementChild?.className || '';
    const icon = `<i className="${className}" />`;
    const input = document.body.appendChild(document.createElement('input'));
    input.value = icon;
    input.focus();
    input.select();
    document.execCommand('copy');
    input.parentNode?.removeChild(input);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Header>
        <h1>houpa Font</h1>
      </Header>

      <div id="icons-wrapper">
        <section>
          <List>
            <li onClick={CopyIcon}>
              <i className="icon icon-eye-slash-solid" />
              <p>icon-eye-slash-solid</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-eye-solid" />
              <p>icon-eye-solid</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-exit" />
              <p>icon-exit</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-slip" />
              <p>icon-slip</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-padlock" />
              <p>icon-padlock</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-success" />
              <p>icon-success</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-success-circle" />
              <p>icon-success-circle</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-arrow-down" />
              <p>icon-arrow-down</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-arrow-right" />
              <p>icon-arrow-right</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-arrow-up" />
              <p>icon-arrow-up</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-arrow-left" />
              <p>icon-arrow-left</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-houpa" />
              <p>icon-houpa</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-credit-card"/>
              <p>icon-credit-card</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-box"/>
              <p>icon-box</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-location"/>
              <p>icon-location</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-arrows"/>
              <p>icon-arrows</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-user-text"/>
              <p>icon-user-text</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-user"/>
              <p>icon-user</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-hanger"/>
              <p>icon-hanger</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-warning"/>
              <p>icon-warning</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-trashcan"/>
              <p>icon-trashcan</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-card"/>
              <p>icon-card</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-edit"/>
              <p>icon-edit</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-info-circle"/>
              <p>icon-info-circle</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-favorite"/>
              <p>icon-favorite</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-clothes-hanger"/>
              <p>icon-clothes-hanger</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-stock"/>
              <p>icon-stock</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-home"/>
              <p>icon-home</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-truck"/>
              <p>icon-truck</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-chat-internal"/>
              <p>icon-chat-internal</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-arrow-menu"/>
              <p>icon-arrow-menu</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-cart"/>
              <p>icon-cart</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-search"/>
              <p>icon-search</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-share"/>
              <p>icon-share</p>
            </li>


            <li onClick={CopyIcon}>
              <i className="icon icon-free-shipping"/>
              <p>icon-free-shipping</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-truck-normal"/>
              <p>icon-truck-normal</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-truck-express"/>
              <p>icon-truck-express</p>
            </li>


            <li onClick={CopyIcon}>
              <i className="icon icon-user-logout"/>
              <p>icon-user-logout</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-filter"/>
              <p>icon-filter</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-followers"/>
              <p>icon-followers</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-shopkeeper"/>
              <p>icon-shopkeeper</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-logo-instagram"/>
              <p>icon-logo-instagram</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-logo-facebook"/>
              <p>icon-logo-facebook</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-logo-whatsapp"/>
              <p>icon-logo-whatsapp</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-bar-code"/>
              <p>icon-bar-code</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-cipher"/>
              <p>icon-cipher</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-back-arrow"/>
              <p>icon-back-arrow</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-photo-camera"/>
              <p>icon-photo-camera</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-logout"/>
              <p>icon-logout</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-copy"/>
              <p>icon-copy</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-eye"/>
              <p>icon-eye</p>
            </li>

            <li onClick={CopyIcon}>
              <i className="icon icon-closed-eye"/>
              <p>icon-closed-eye</p>
            </li>


          </List>
          <Snackbar open={open} autoHideDuration={1500} onClose={handleClose}>
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={handleClose}
              severity="info"
            >
              Icone copiado!
            </MuiAlert>
          </Snackbar>
        </section>
      </div>
    </>
  );
};

export default Icons;
