import React from 'react';

import { Container, Button, Page} from './styles';

interface Props {
  onClick: (direction: string) => void;
  page?: string;
  color?: string;
}

const PaginationButton: React.FC<Props> = ({ page, onClick, color}) => {
  return (
    <Container color={color}>
      <Button onClick={() => onClick('previous')}>
        <i className="icon icon-arrow-left" />
      </Button>
      <Page>{page}</Page>
      <Button onClick={() => onClick('next')}>
        <i className="icon icon-arrow-right" />
      </Button>
    </Container>
  );
};

export default PaginationButton;
