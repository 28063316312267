import React from 'react';
import { Container, Wrapper } from './styles';
import Nav from './Nav';
import HoupaInfo from './HoupaInfo';

const Footer: React.FC = () => {
  return (
    <Container>
      <Wrapper>
        <Nav />
        <HoupaInfo />
      </Wrapper>
    </Container>
  );
};

export default Footer;