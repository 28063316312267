import React from 'react';
import SelectCheckbox from '../../../components/SelectCheckbox';

const SelectCheckboxes = (): JSX.Element => {
  return (
    <div>
      <SelectCheckbox
        options={[
          'A peça veio com defeito.',
          'Problemas com tamanho.',
          'Não gostei da peça.',
          'Outros motivos.',
        ]}
        label="Motivo da troca*"
      />
    </div>
  );
};

export default SelectCheckboxes;
