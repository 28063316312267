import { Modal } from '@material-ui/core';
import styled from 'styled-components';

interface IconProps {
  type?: string;
}

interface ModalProps {
  isBottom?: boolean;
}

export const CustomModal = styled(Modal)<ModalProps>`
  display: flex;
  justify-content: center;
  align-items: ${({isBottom}) => isBottom ? 'flex-end' : 'center'};
  z-index: 4000 !important;
`;

export const Container = styled.div<ModalProps>`
  width: 492px;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  padding: 24px;
  border-radius: 10px;
  outline: none;

  @media (max-width: 1279px) {
    width: 360px;
    padding: 17px;
  }

  @media (max-width: 607px) {
    width: ${({isBottom}) => isBottom ? '100%' : '308px'};
    padding: 26px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const IconButton = styled.div`
  cursor: pointer;
  color: var(--grey-light-1);
  font-size: 19px;

  @media (max-width: 1279px) {
    font-size: 16px;
  }

  @media (max-width: 607px) {
    font-size: 14px;
  }
`;

export const Content = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: 26px 39px;

  @media (max-width: 1279px) {
    padding: 0px 29px;
  }

  @media (max-width: 607px) {
    padding: 0px 13px;
  }
`;

export const IconContainer = styled.div<IconProps>`
  width: 100%;
  div{
    height: 48px;
    @media (max-width: 1279px) {
      height: 40px;
    }
  }
`;

export const Description = styled.div`
  margin-top: 37px;
  margin-bottom: 43px;

  @media (max-width: 1279px) {
    margin-top: 14px;
    margin-bottom: 23px;
  }

  &,
  div {
    line-height: 28px;
    font-size: 20px;
    text-align: center;
    color: var(--grey);

    @media (max-width: 1279px) {
      line-height: 20px;
      font-size: 16px;
    }

    @media (max-width: 607px) {
      font-size: 18px;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 12px;

  @media (max-width: 1279px) {
    gap: 8px;
  }

  button {
    width: 118px;
    border-radius: 2px;
    height: 40px;
    font-size: 16px;

    @media (max-width: 1279px) {
      width: 86px;
      height: 30px;
      font-size: 12px;
      margin-bottom: 30px;
    }
  }
`;
