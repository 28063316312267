import React from 'react';
import ButtonFilters from '../../../components/ButtonFilters';
import {CardBody, CardHeader, CardInfo, CodeBlock, Container, Content} from '../ProductCardExample/styles';

const ButtonsFilters: React.FC = () => {
  return (
    <Container>
      <Content>
        <h2>Cards de endereço e pagamento</h2>
        <p></p>
        <CardInfo>
          <CardHeader>
            <ButtonFilters onClick={() => alert('festa')}>Festa</ButtonFilters>
            <ButtonFilters color={'#E9E9E9'}>Cinza</ButtonFilters>
            <ButtonFilters>M</ButtonFilters>
            <ButtonFilters>Vestido</ButtonFilters>
          </CardHeader>
          <CardBody>
            <p>import ProductCard from "components/ButtonFilters"</p>
            <CodeBlock>
              <h1>ButtonFilters</h1>
              <pre>{`
                <ButtonFilters onClick={() => alert("festa")}>Festa</ButtonFilters>
                <ButtonFilters color={'#E9E9E9'}>Cinza</ButtonFilters>
                <ButtonFilters>M</ButtonFilters>
                <ButtonFilters>Vestido</ButtonFilters>
              `}</pre>
            </CodeBlock>
          </CardBody>
        </CardInfo>
      </Content>
    </Container>
  );
};

export default ButtonsFilters;
