import styled from 'styled-components';

interface Props {
  color?: string;
}

export const Container = styled.div`
  padding: 0px;
`;

export const Content = styled.div`
  margin-top: 20px;

  > h2 {
    color: var(--purple);
  }
`;

export const RowColors = styled.div`
  display: flex;
`;

export const ColsColors = styled.div`
  flex: 1;

  > span {
    font-family: var(--poppins);
    font-size: 9px;
    color: #ff00ff;
    opacity: 0.8;
  }

  > div {
    margin-top: 17px;

    > h3 {
      font-size: 13px;
    }

    > p {
      font-size: 10px;
    }
  }
`;

export const ContainerColors = styled.div<Props>`
  width: 100%;
  height: 72px;
  background-color: ${(props) => props.color ?? 'var(--white)'};
  border: 1px solid var(--white)
`;
