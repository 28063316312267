import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  @media (max-width: 607px) {
    margin-bottom: 64px;
  }
`;

export const LinkContainer = styled.div`
  margin-top: 55px;
  display: grid;
  gap: 190px;
  justify-content: center;
  grid-template-columns: repeat(4, 60px);

  @media (max-width: 1279px) {
    gap: 98px;
    padding: 0 10px;
    margin-top: 67px;
  }

  @media (max-width: 607px) {
    grid-template-columns: repeat(2, 80px);
    gap: 84px;
    padding: 0 10px;
  }

  > a {
    text-decoration: none;
    max-width: 80px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:hover {
      opacity: 0.5;
    }
  }
`;
export const LinkIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  font-size: 30px;
  background-color: ${(props) => props.theme.colors.greyLight2};
  color: ${(props) => props.theme.colors.purple};
`;

export const LinkText = styled.span`
  font-family: var(--roboto);
  font-weight: 500;
  color: ${(props) => props.theme.colors.greyMedium1};
  font-size: 13px;
  margin-top: 16px;
  line-height: 16px;
`;
