import React from 'react';

import { CardInfo, CardHeader, CardBody, Content, Container } from './styles';
import Button from '../../../components/Button';

const Buttons: React.FC = () => {
  return (
    <Container>
      <Content>
        <h2>Houpa Buttons</h2>
        <p>
          Estilos de botão predefinidos, cada um servindo a seu próprio
          propósito semântico, com alguns extras adicionados para mais controle.
        </p>

        <CardInfo>
          <CardHeader>
            <Button scale="scale-1" color="var(--purple)">
              Minha conta
            </Button>
            <Button scale="scale-1" color="var(--purple-business)">
              Minha conta
            </Button>
            <Button scale="scale-1" color="var(--blue)">
              Minha conta
            </Button>
            <Button scale="scale-1" color="var(--green)">
              Minha conta
            </Button>
            <Button scale="scale-1" color="var(--red-dark)">
              Minha Conta
            </Button>
            <Button scale="scale-1" color="var(--yellow-dark)">
              Minha conta
            </Button>
            <Button scale="scale-1" color="var(--grey-light-2)">
              Minha conta
            </Button>
            <Button scale="scale-1" >
              Minha conta
            </Button>
            <Button scale="scale-1" disabled>
              Minha conta
            </Button>
            <Button scale="scale-1" link="b2c">
              Minha conta
            </Button>
            <Button scale="scale-1" link="b2b">
              Minha conta
            </Button>
          </CardHeader>
          <CardBody>
            <p>Import Button from './src/components/forms/button'</p>

            <div>
              <p>
                {
                  '<Button scale=\'scale-1\' color=\'var(--purple)\'>Minha conta</Button>'
                }
              </p>
              <p>
                {
                  '<Button scale=\'scale-1\' color=\'var(--purple-business)\'>Minha conta</Button>'
                }
              </p>
              <p>
                {
                  '<Button scale=\'scale-1\' color=\'var(--blue)\'>Minha conta</Button>'
                }
              </p>
              <p>
                {
                  '<Button scale=\'scale-1\' color=\'var(--green)\'>Minha conta</Button>'
                }
              </p>
              <p>
                {
                  '<Button scale=\'scale-1\' color=\'var(--red-dark)\'>Minha conta</Button>'
                }
              </p>
              <p>
                {
                  '<Button scale=\'scale-1\' color=\'var(--yellow-dark)\'>Minha conta</Button>'
                }
              </p>
              <p>
                {
                  '<Button scale=\'scale-1\' color=\'var(--grey-light-2)\'>Minha conta</Button>'
                }
              </p>
              <p>
                {
                  '<Button scale=\'scale-1\'>Minha conta</Button>'
                }
              </p>
              <p>
                {
                  '<Button scale=\'scale-1\' disabled>Minha conta</Button>'
                }
              </p>
              <p>
                {
                  '<Button scale=\'scale-1\' link=\'b2c\'>Minha conta</Button>'
                }
              </p>
              <p>
                {
                  '<Button scale=\'scale-1\' link=\'b2b\'>Minha conta</Button>'
                }
              </p>
            </div>
          </CardBody>
        </CardInfo>
      </Content>

      <Content>
        <h2>Buttons Outline</h2>
        <p>
          Precisa de um botão, mas não tem as cores de fundo? Acrescente o
          atributo 'outline' pra remover a cor de fundo
        </p>
        <CardInfo>
          <CardHeader>
            <Button outline scale="scale-1" color="var(--purple)">
              Minha conta
            </Button>
            <Button outline scale="scale-1" color="var(--purple-business)">
              Minha conta
            </Button>
            <Button outline scale="scale-1" color="var(--blue)">
              Minha conta
            </Button>
            <Button outline scale="scale-1" color="var(--green)">
              Minha conta
            </Button>
            <Button outline scale="scale-1" color="var(--red-dark)">
              Minha Conta
            </Button>
            <Button outline scale="scale-1" color="var(--yellow-dark)">
              Minha conta
            </Button>
            <Button outline scale="scale-1" color="var(--grey-medium-3)">
              Minha conta
            </Button>
            <Button outline scale="scale-1">
              Minha conta
            </Button>
            <Button outline scale="scale-1" disabled>
              Minha conta
            </Button>
          </CardHeader>
          <CardBody>
            <p>Import Button from './src/components/forms/button'</p>

            <div>
              <p>
                {
                  '<Button outline scale=\'scale-1\' color=\'var(--purple)\'>Minha conta</Button>'
                }
              </p>
              <p>
                {
                  '<Button outline scale=\'scale-1\' color=\'var(--purple-business)\'>Minha conta</Button>'
                }
              </p>
              <p>
                {
                  '<Button outline scale=\'scale-1\' color=\'var(--blue)\'>Minha conta</Button>'
                }
              </p>
              <p>
                {
                  '<Button outline scale=\'scale-1\' color=\'var(--green)\'>Minha conta</Button>'
                }
              </p>
              <p>
                {
                  '<Button outline scale=\'scale-1\' color=\'var(--red-dark)\'>Minha conta</Button>'
                }
              </p>
              <p>
                {
                  '<Button outline scale=\'scale-1\' color=\'var(--yellow-dark)\'>Minha conta</Button>'
                }
              </p>
            </div>
          </CardBody>
        </CardInfo>
      </Content>

      <Content>
        <h2>Buttons scale</h2>
        <p>
          Tamanhos de botão definidos, sendo necessário passar o atributo scale
          com o tamanho desejado.
        </p>

        <CardInfo>
          <CardHeader>
            <Button scale="scale-0">Minha conta</Button>
            <Button scale="scale-1">Minha conta</Button>
            <Button scale="scale-2">Minha conta</Button>
            <Button scale="scale-3">Minha conta</Button>
            <Button>Minha Conta</Button>
            <Button scale="scale-5">Minha conta</Button>
          </CardHeader>
          <CardBody>
            <p>Import Button from './src/components/forms/button'</p>

            <div>
              <p> {'<Button scale=\'scale-0\'>Minha conta</Button>'}</p>
              <p> {'<Button scale=\'scale-1\'>Minha conta</Button>'}</p>
              <p> {'<Button scale=\'scale-2\'>Minha conta</Button>'}</p>
              <p> {'<Button scale=\'scale-3\'>Minha conta</Button>'}</p>
              <p> {'<Button>Minha conta</Button>'}</p>
              <p> {'<Button scale=\'scale-5\'>Minha conta</Button>'}</p>
            </div>
          </CardBody>
        </CardInfo>
      </Content>

      <Content>
        <h2>Buttons expand</h2>
        <p>
          Crie botões de nível de bloco - aqueles que abrangem a largura total
          de um pai - adicionando o atributo expand.
        </p>
        <CardInfo>
          <CardHeader>
            <Button expand>Minha conta</Button>
            <Button outline expand>
              Minha conta
            </Button>
          </CardHeader>
          <CardBody>
            <p>Import Button from './src/components/forms/button'</p>

            <div>
              <p> {'<Button expand>Minha conta</Button>'}</p>
              <p> {'<Button outline expand>Minha conta</Button>'}</p>
            </div>
          </CardBody>
        </CardInfo>
      </Content>
    </Container>
  );
};

export default Buttons;
