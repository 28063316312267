import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  margin-top: 30px;
`;

export const InputCodeContainer = styled.div`
  margin-bottom: 60px;
`;

export const InputContainerTitle = styled.h2`
  margin-bottom: 40px;
  color: var(--purple);
  font-family: var(--poppins);
  font-size: 18px;
`;

export const InputCodeWrapper = styled.div`
  width: 450px;
  margin: 0 auto;
  @media screen and (max-width: 607px) {
    max-width: 300px;
  }
`;

export const CodeExample = styled.div`
  margin-top: 40px;
  background-color: var(--grey-light-3);
  width: 100%;
  border: 2px solid var(--grey-light-1);
  border-radius: 9px;
`;

export const PreFormater = styled.pre`
  color: var(--grey-medium-3);
  letter-spacing: 0.01em;
  line-height: 1.4;
`;
