import styled, {css} from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Title = styled.div`
  font-size: 12px;
  line-height: 14.06px;
  display: flex;
  height: 16px;
  align-items: center;

  > span {
    color: var(--red-dark);
  }

  .tooltip {
    margin-left: 20px;

    @media (max-width: 607px){
      margin-left: 5px;
    }
  }
`;

interface ContentInterface {
  allBorders?: boolean;
}

export const Content = styled.div<ContentInterface>`
  margin-top: 8px;
  border-bottom: 1px solid var(--grey-dark);
  padding-bottom: 5px;

  display: flex;

  > input {
    width: 100%;
    height: 19px;
    font-size: 1rem;
    outline: 0;
    padding: 3px 0;
    color: var(--grey-dark);

    &::placeholder {
      font-size: 14px;
      line-height: 22px;
      color: ${props => props.theme.colors.greyLight1};
    }

    &:disabled {
      color: ${props => props.theme.colors.greyLight1};
    }
  }

  ${props => props.allBorders && css`
    border: 1px solid ${props.theme.colors.greyLight2};
    border-radius: 3px;
    height: 36px;
    margin-top: 0;
    padding: 0 0 0 12px;

    > input {
      height: 100%;
    }

    @media (max-width: 1279px) and (min-width: 608px) {
      height: 32px;
    }
  `};

  &.error {
    border-color: var(--red-dark);

    i {
      color: var(--red-dark);
    }
  }
`;

export const HelpContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
  height: 14px;
`;

export const HelpText = styled.p`
  font-size: 12px;
  color: var(--grey);

  &.error {
    color: var(--red-dark);
  }
`;

export const Count = styled.p`
  font-size: 12px;
  color: var(--grey);
`;

export const PasswordContainer = styled.button`
  background: transparent;
  border: none;
  outline: 0;
  cursor: pointer;

  > i {
    color: var(--grey-dark);
    font-size: 19px;
  }
`;

export const RequiredText = styled.p`
  font-size: 14px;
  color: var(--grey-dark);
  &.center {
    text-align: center;
  }
  &.padding {
    padding: 20px 0;
  }
  span {
    color: var(--red-dark);
  }
  @media screen and (max-width: 607px) {
    font-size: 12px;
  }
`;