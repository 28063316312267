import styled from 'styled-components';

interface Props {
  background ?: string
}

export const Container = styled.div<Props>`
  display: flex;
  align-items: center;
  padding: 29px;
  background-color: ${({background}) => background ? background : 'var(--grey-light-2)'};

  > p {
    font-size: 14px;
    line-height: 22px;
    color: var(--grey-dark);
    opacity: 50%;
  }

  @media (max-width: 1279px) {
    padding: 16px;

    > p {
      font-size: 10px;
    }
  }

  @media (max-width: 607px) {
    padding: 8px;
  }

`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 40px;
  min-height: 40px;

  max-width: 40px;
  max-height: 40px;

  border-radius: 50%;
  background-color: var(--white);
  margin-right: 24px;

  font-family: Poppins-Regular, Roboto-Regular, Arial, sans-serif;
  font-size: 30px;
  color: var(--grey-medium-4);

  @media (max-width: 1279px) {
    min-width: 25px;
    min-height: 25px;
    max-width: 25px;
    max-height: 25px;

    font-size: 19px;

    margin-right: 12px;
  }
`;
