import styled from 'styled-components';

export const Container = styled.div`
`;

export const PageTitle = styled.h2` 
  color: var(--purple);
`;

export const Content = styled.div`
  margin-top: 20px;
`;

export const ModalWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  border: 2px solid var(--grey-light-2);
  border-radius: 9px;
`;

export const ModalItems = styled.div`
  margin: 20px;
  display: flex;
  gap: 10px;
`;

export const ModalBody = styled.div`
  width: 100%;
  padding: 10px 20px;
  background-color: var(--grey-light-3);
`;

export const Button = styled.button` 
  padding: 10px 15px;
  background-color: var(--purple);
  color: var(--white);
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
`;

export const ModalSuccess = styled.h1`
color: var(--grey-dark);
font-size: 18px;
margin-top: 30px;
`;
export const PreFormater = styled.pre`
  color: var(--grey-medium-3);
  line-height: 1.3;
`;

export const ModalError = styled(ModalSuccess)`

`;

export const ImportText = styled(PreFormater)`
  margin-top: 10px;
`;