import React from 'react';
import { ButtonToTop, Container, Content } from './styles';

interface Props{
 fixed?: boolean,
 showIn?: number
}

const ScrollTopTop: React.FC<Props> = ({
  fixed = true,
  showIn = 100
}) => {
  const [scroll, setScroll] = React.useState(0);

  React.useEffect(() =>{
    window.addEventListener('scroll', function(){
      setScroll(window.pageYOffset);
    });
  }, []);

  function toTop(){
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  if(scroll < showIn) return <></>;

  if(!fixed){
    return(
      <ButtonToTop onClick={toTop}><i className="icon icon-arrow-up" /> Topo</ButtonToTop>
    );
  }

  return(
    <Container>
      <Content>
        <ButtonToTop onClick={toTop}><i className="icon icon-arrow-up" /> Topo</ButtonToTop>
      </Content>
    </Container>
  );
};

export default ScrollTopTop;
