import styled from 'styled-components';

export const Container = styled.section`
  margin: 0 auto;
`;

export const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 1071;

  width: 100%;
  height: 60px;
  background-color: var(--white);

  display: flex;
  align-items: center;

  padding: 0px 16px;

  box-shadow: 0px 0px 10px var(--grey);

  .header-text {
    font-family: var(--poppins);
    margin-left: 10px;
    color: var(--purple);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const NavLeft = styled.div`
  overflow-y: auto;
  flex: 0 1 220px;
  position: sticky;
  top: 4rem;
  z-index: 1000;
  height: calc(100vh - 60px);
  border-right: 1px solid var(--grey-light-2);
  padding: 20px 16px;
`;

export const NavList = styled.ul`
  > li a{
      display: block;
      padding: 10px 0px;
      text-decoration: none;
      
      width: 100%;
      font-size: 16px;
      color: var(--grey-dark);
  }
`;

export const Main = styled.div`
  flex: 1;
  padding: 20px 40px;
`;
