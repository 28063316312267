import React, { useEffect, useRef } from 'react';
import { Container, ListWrapper } from './styles';

interface Props {
  userNotLoggedMenu: boolean;
  setUserNotLoggedMenu: (arg: boolean) => void;
  setUserIsLogged: () => void;
}

const UserNotLoggedMenu: React.FC<Props> = ({
  userNotLoggedMenu,
  setUserNotLoggedMenu,
  setUserIsLogged
}) => {
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (
        menuRef.current &&
        !menuRef.current.contains(target) &&
        userNotLoggedMenu
      ) {
        setUserNotLoggedMenu(false);
      }
    };
    document.body.addEventListener('click', handler);
    return () => {
      document.body.removeEventListener('click', handler);
    };
  });

  const handleClose = () => {
    setUserNotLoggedMenu(false);
  };

  return (
    <>
      <Container
        ref={menuRef}
        className={`${!userNotLoggedMenu ? 'disappear' : ''}`}
      >
        <div>
          <ListWrapper>
            <div>
              <span onClick={() => {
                setUserIsLogged();
                handleClose();
              }}
              >Entrar ou Criar Conta
              </span>
            </div>
          </ListWrapper>
        </div>
      </Container>
    </>
  );
};

export default UserNotLoggedMenu;
