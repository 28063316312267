import React from 'react';
import {
  Container,
  AddCardContainer,
  AddButton,
  Header,
  HeaderActionContainer,
  HeaderAction,
  Content,
  Footer,
  Payment,
  CardIcon,
  CardInfoContainer,
  AddressContainer,
  AddressInfoWrapper,
} from './styles';
import Radio from '../Radio';

interface Props {
  type?: 'payment' | 'address';
  addNewCard?: boolean;
  text?: string;
  headerTitle?: string | number;
  params?: {
    receiver?: string;
    street?: string;
    number?: string;
    adjunct?: string;
    neighborhood?: string;
    city?: string;
    uf?: string;
    zipCode?: number | string;
    lastNumbers?: string | number;
    cardBanner?: string;
    shelfLife?: string;
    cardBannerImg?: string;
    cardBannerAlt?: string;
  };
  checked?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  onAdd?: () => void;
  onCheck?:()=>void;
}

const Card: React.FC<Props> = ({
  type,
  addNewCard,
  text,
  headerTitle,
  onEdit,
  onDelete,
  onAdd,
  onCheck,
  params,
  checked,
}) => {
  return (
    <>
      {addNewCard ? (
        <AddCardContainer>
          <AddButton onClick={onAdd}>
            <span>+</span>
            {text}
          </AddButton>
        </AddCardContainer>
      ) : (
        <Container>
          <Header>
            <p>{headerTitle}</p>
            <HeaderActionContainer>
              {onEdit && (
                <HeaderAction onClick={onEdit}>
                  <i className="icon icon-edit" />
                </HeaderAction>
              )}
              {onDelete && (!checked || type === 'payment') && (
                <HeaderAction onClick={onDelete}>
                  <i className="icon icon-trashcan" />
                </HeaderAction>
              )}
            </HeaderActionContainer>
          </Header>
          <Content>
            {type === 'payment' ? (
              <Payment>
                <CardIcon>
                  <img
                    src={params?.cardBannerImg}
                    alt={params?.cardBannerAlt}
                  />
                </CardIcon>
                <CardInfoContainer>
                  <p className="lastNumbers">
                    Terminado em {params?.lastNumbers}
                  </p>
                  <p className="cardBanner">{params?.cardBanner}</p>
                  <p className="dueDate">Vencimento: {params?.shelfLife}</p>
                </CardInfoContainer>
              </Payment>
            ) : (
              <>
                <AddressContainer>
                  <AddressInfoWrapper>
                    <li>{params?.receiver}</li>
                    <li>
                      {params?.street}, {params?.number + ','}
                      <br />
                      {params?.adjunct && `${params?.adjunct}`}
                    </li>
                    <li>{params?.neighborhood}</li>
                    <li>
                      {params?.city} - {params?.uf}
                    </li>
                    <li>CEP {params?.zipCode}</li>
                  </AddressInfoWrapper>
                </AddressContainer>
              </>
            )}
          </Content>
          <Footer>
            {
              <Radio
                label={
                  type === 'payment'
                    ? 'Este é meu cartão principal.'
                    : checked ? 'Este é meu endereço principal.' : 'Selecionar endereço principal.'
                }
                checked={checked}
                onCheck={onCheck}
              />
            }
          </Footer>
        </Container>
      )}
    </>
  );
};

export default Card;
