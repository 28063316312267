import styled, {css} from 'styled-components';

interface Props {
  expandContent?: boolean;
  icon?: boolean;
  withBorder?: boolean;
}

export const Container = styled.div<Props>`
  margin-bottom: 8px;
  padding-bottom: 20px;

  ${props => props.withBorder && css`
    border-bottom: 1px solid var(--grey-light-2);
  `};

  .accordion-item {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
  }

  .accordion-item.collapsed {
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
  }

`;

export const AccordionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: var(--black);
  font-size: 14px;

  background-color: var(--white);
  border-radius: 5px;
  cursor: pointer;
  padding: 20px 0;
  font-weight: 500;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    font-size: 16px;
  }
  @media screen and (max-width: 607px) {
    font-size: 14px;
  }
`;

export const AccordionIcon = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  transition: .3s;
   > i {
     font-size: 6px;
      transition: .3s;
      transform: rotate(180deg);
    }

  &.open {
    > i {
      transform: rotate(0deg);
    }
  }
`;
