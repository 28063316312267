import React from 'react';
import {
  CardInfo,
  CardHeader,
  CardBody,
  Content,
  Container,
  Wraper,
  CodeBlock,
} from './styles';

import StepBar from '../../../components/StepBar';

const Step: React.FC = () => {
  return (
    <Container>
      <Content>
        <h2>Cards de endereço e pagamento</h2>
        <p></p>
        <CardInfo>
          <CardHeader>
            <div>
              <StepBar active={2} />
              <StepBar active={3} canceled={true} />
              <StepBar />
            </div>
          </CardHeader>
          <CardBody>
            <p>import StepBar from "components/StepBar"</p>
            <Wraper>
              <CodeBlock>
                <h1>StepBar active</h1>
                <pre>
                  {`
                <StepBar 
                  active={2} 
                />
                `}
                </pre>
              </CodeBlock>
              <CodeBlock>
                <h1>StepBar canceled</h1>
                <pre>
                  {`
                <StepBar 
                  active={3} 
                  canceled={true}
                />
                `}
                </pre>
              </CodeBlock>
              <CodeBlock>
                <h1>StepBar</h1>
                <pre>
                  {`
                <StepBar/>
                `}
                </pre>
              </CodeBlock>
            </Wraper>
          </CardBody>
        </CardInfo>
      </Content>
    </Container>
  );
};

export default Step;
