import React, { useContext, useState } from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import {
  Cols,
  Container,
  MenuContainer,
  MenuHeader,
  MenuList ,
  NoUserPhoto,
  CameraIconContainer,
  CameraIcon
} from './styles';

import Main from './Main';
import Address from './Address';
import Payments from './Payments';
import Orders from './Orders';
import MyRegister from './MyRegister';
import UserPhotoModal from '../../components/UserPhotoModal';
import { AuthContext } from '../../hooks/useAuth';
import { format } from 'date-fns';

const Account: React.FC = () => {
  const [userPhotoModalOpen, setUserPhotoModalOpen] = useState<boolean>(false);
  const { pathname } = useLocation();
  const { user } = useContext(AuthContext);

  const openUserPhotoModal = () => {
    setUserPhotoModalOpen(true);
  };

  if (!user) return null;

  return (
    <>
      <UserPhotoModal
        userPhotoModalOpen={userPhotoModalOpen}
        setUserPhotoModalOpen={setUserPhotoModalOpen}
      />

      <Container>
        <Cols>
          <MenuContainer>
            <MenuHeader>
              <NoUserPhoto background={user.image} onClick={openUserPhotoModal}>
                <CameraIconContainer>
                  <CameraIcon className="icon icon-photo-camera" />
                </CameraIconContainer>
              </NoUserPhoto>
              <div>
                <h3>Olá, {user.name}</h3>
                <p>
                  No houpa desde{' '}
                  {user.registerDate &&
                    format(new Date(user.registerDate), 'yyyy')}
                </p>
              </div>
            </MenuHeader>
            <MenuList>
              <li>
                <Link
                  className={pathname === '/minha-conta' ? 'active' : ''}
                  to={'/minha-conta'}
                >
                  <div>
                    <i className="icon icon-user" />
                  </div>
                  <span>Minha conta</span>
                </Link>
              </li>
              <li>
                <Link
                  className={pathname.includes('enderecos') ? 'active' : ''}
                  to={'/minha-conta/enderecos'}
                >
                  <div>
                    <i className="icon icon-location" />
                  </div>
                  <span>Meus Endereços</span>
                </Link>
              </li>
              <li>
                <Link
                  className={pathname.includes('pedidos') ? 'active' : ''}
                  to={'/minha-conta/pedidos'}
                >
                  <div>
                    <i className="icon icon-box" />
                  </div>
                  <span>Meus Pedidos</span>
                </Link>
              </li>
              <li>
                <Link
                  className={pathname.includes('pagamentos') ? 'active' : ''}
                  to={'/minha-conta/pagamentos'}
                >
                  <div>
                    <i className="icon icon-credit-card" />
                  </div>
                  <span>Formas de Pagamento</span>
                </Link>
              </li>
              <li>
                <Link
                  className={pathname.includes('meu-cadastro') ? 'active' : ''}
                  to={'/minha-conta/meu-cadastro'}
                >
                  <div>
                    <i className="icon icon-user-text" />
                  </div>
                  <span>Meu Cadastro</span>
                </Link>
              </li>
            </MenuList>
          </MenuContainer>
        </Cols>

        <Cols>
          <Routes>
            <Route path={'/'} element={<Main />} />
            <Route path={'/enderecos'} element={<Address />} />
            <Route path={'/pedidos/*'} element={<Orders />} />
            <Route path={'/pagamentos'} element={<Payments />} />
            <Route path={'/meu-cadastro'} element={<MyRegister />} />
          </Routes>
        </Cols>
      </Container>
    </>
  );
};

export default Account;
