import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1280px) {
    justify-content: center;
    flex-direction: column;
  }

  @media (max-width: 608px) {
    justify-content: flex-start;
  }

`;

export const PanelFooter = styled.div`
  display: flex;
  font-family: var(--roboto);
  font-size: 16px;
  justify-content: center;

  a {
    color: var(--grey-dark);
    font-weight: 700;
    margin-left: 4px;
    text-decoration: underline;
  }

  @media (max-width: 1280px) {
    font-size: 14px;
  }
`;

export const PanelFooterText = styled.p`
  color: var(--grey-dark);
`;

export const ButtonContainer = styled.div`
  width: 100%;
  max-width: 214px;
  align-self: center;
  padding: 100px 0 65px 0;

  @media (max-width: 1280px) {
    max-width: 298px;
  }

  @media (max-width: 608px) {
    max-width: 152px;
    padding: 82px 0 45px 0;

  }
`;

export const Form = styled.form`
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 100%;

  @media (max-width: 1280px) {
    max-width: 452px;
  }
`;
