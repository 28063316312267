import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  > h3 {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 19px;

  &.error {
    > input {
      color: var(--red-dark);
      border-color: var(--red-dark);
    }
  }

  &.success {
    > input {
      color: var(--green);
      border-color: var(--green);
    }
  }
`;

export const Code = styled.input`
  width: 90px;
  height: 90px;
  border: 2px solid var(--grey-medium-3);
  text-align: center;
  border-radius: 10px;
  outline: none;
  font-weight: 500;
  font-size: 56px;
  color: var(--grey);

  @media (max-width: 607px) {
    width: 63px;
    height: 63px;
    font-size: 48px;
  }
`;

export const ValidContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  margin-bottom: 80px;
  font-size: 18px;
  font-weight: 500;
  height: 21px;

  &.error {
    color: var(--red-dark);
  }

  &.success {
    color: var(--green);
  }

  > i {
    margin-right: 10px;
  }
`;
