import React, { ChangeEvent, useRef, useState, useCallback, useEffect, Dispatch, SetStateAction } from 'react';
import {
  Container,
  Buttons,
  CancelButton,
  HiddenInput,
  CropWrapper,
  NoImageContainer,
  NoImageWrapper,
  NoImageIcon,
  ImageControls,
  ZoomWrapper,
  SliderStyled,
  ChangeImage,
  FileTypes,
  FileTypesMobile,
  ResetIcon
 } from './styles';
import Button from '../../Button';
import EasyCrop from 'react-easy-crop';
import NoImageSelected from '../../../assets/png/noimage.png';
import getCroppedImg from '../../../utils/cropImage';
import { Image } from '../index';

interface Props {
  userPhotoModalOpen: boolean;
  setUserPhotoModalOpen: (arg: boolean) => void;
  image: Image;
  setImage: Dispatch<SetStateAction<Image>>;
}
interface cropProps {x: number, y: number, width: number, height: number}

const Cropper: React.FC<Props> = ({
  userPhotoModalOpen,
  setUserPhotoModalOpen,
  image,
  setImage,
}) => {
  const [extension, setExtension] = useState<string>('');
  const [selectedImage, setSelectedImage] = useState<string>('');
  const [croppedArea, setCroppedArea] = useState<cropProps>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);

  const inputRef = useRef<HTMLInputElement>(null);

  const inputFileAction = () => {
    inputRef.current?.click();
  };

  const handleFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const imgType = files?.length && files[0].name.split('.')[1];
    imgType && setExtension(imgType);
    if (imgType !== 'jpg' && imgType !== 'png') {
      setSelectedImage('');
      return image;
    }
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.addEventListener('load', () => {
        setSelectedImage(String(reader.result));
      });
    }
  };

  const onCropComplete = (
    cropAreaPercentage: cropProps,
    cropAreaPixels: cropProps,
  ) => {
    setCroppedArea(cropAreaPixels);
    return { cropAreaPercentage, cropAreaPixels };
  };

  const handleZoomBarChange = (
    e: ChangeEvent<unknown>,
    zoom: number | number[],
  ) => {
    setZoom(Number(zoom));
    return { e, zoom };
  };

  const CloseModal = () => setUserPhotoModalOpen(false);

  const saveCroppedImage = useCallback(async () => {
    try {
      const croppedImageResponse = await getCroppedImg(
        selectedImage,
        croppedArea,
      );


      const imageProps: Image = {
        base64: String(croppedImageResponse),
        type: extension,
      };
      setImage(imageProps);
      setUserPhotoModalOpen(false);
    } catch (e: any) {
      return e;
    }

    // eslint-disable-next-line
  }, [image, croppedArea, setUserPhotoModalOpen, extension]);

  const onReset = () => {
    setZoom(1);
    setCrop({ x: 0, y: 0 });
  };

  useEffect(() => {
    userPhotoModalOpen && inputFileAction();
  }, [userPhotoModalOpen]);

  return (
    <Container>
      <CropWrapper>
        {selectedImage !== '' ? (
          <EasyCrop
            aspect={1}
            image={selectedImage}
            crop={crop}
            onCropChange={setCrop}
            zoom={zoom}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
            showGrid={false}
          />
        ) : (
          <NoImageContainer>
            <NoImageWrapper>
              <NoImageIcon
                src={NoImageSelected}
                alt="two mountains represented as triangles"
              />
              <FileTypesMobile>
                Arquivo precisa ser
                <br /> JPG ou PNG
              </FileTypesMobile>
            </NoImageWrapper>
          </NoImageContainer>
        )}
      </CropWrapper>
      <ImageControls>
        <ZoomWrapper>
          <HiddenInput
            type="file"
            accept=".jpg, .png"
            ref={inputRef}
            onChange={handleFileSelected}
          />
          <ChangeImage onClick={inputFileAction}>Trocar imagem</ChangeImage>
          <SliderStyled
            min={1}
            max={3}
            step={0.1}
            value={zoom}
            onChange={handleZoomBarChange}
          />
          <ResetIcon className="icon icon-back-arrow" onClick={onReset} />
        </ZoomWrapper>
        {!image && (
          <FileTypes>
            Arquivo precisa ser
            <br /> JPG ou PNG
          </FileTypes>
        )}
        <Buttons>
          <CancelButton
            onClick={CloseModal}
            color="var(--grey-light-3)"
            scale="scale-3"
            expand
          >
            Cancelar
          </CancelButton>
          <Button
            onClick={saveCroppedImage}
            color="var(--purple)"
            scale="scale-3"
            expand
          >
            Salvar
          </Button>
        </Buttons>
      </ImageControls>
    </Container>
  );
};

export default Cropper;
