import React, { ButtonHTMLAttributes } from 'react';
import { Container } from './styles';

type Props = ButtonHTMLAttributes<HTMLButtonElement>;

const BackButton: React.FC<Props> = ({ onClick, children }) => {
  return (
    <Container onClick={onClick}>
      <i className={'icon icon-arrow-left'} />
      {children ?? 'Voltar'}
    </Container>
  );
};

export default BackButton;
