import React, {FunctionComponent, InputHTMLAttributes, useCallback, useState} from 'react';
import {
  Container, 
  Content, 
  Title, 
  PasswordContainer, 
  HelpContainer, 
  HelpText, 
  Count,
  RequiredText
} from './styles';
import Tooltip from '../Tooltip';

interface RequiredTipProps {
  centerText?: boolean;
  removePadding?: boolean;
}

interface OwnProps extends InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  required?: boolean;
  error?: string;
  message?: string;
  limit?: string;
  className?: string;
  tooltipContent?: JSX.Element;
  allBorders?: boolean;
}

type Props = OwnProps;

export const RequiredTip: FunctionComponent<RequiredTipProps> = ({centerText, removePadding}) => {
  return (
    <RequiredText 
      className={`
        ${centerText ? 'center' : ''} 
        ${!removePadding ? 'padding' : ''}
      `}
    >
      Todos os campos marcados com <span>*</span> são obrigatórios.
    </RequiredText>
  )
}

const Input: FunctionComponent<Props> = ({
  title,
  disabled,
  value,
  required,
  placeholder,
  type,
  error,
  message,
  limit,
  onBlur,
  onChange,
  className,
  tooltipContent,
  allBorders = false
}) => {
  const [inputType, setInputType] = useState(type || 'text');

  const alterTypeInput = useCallback(() => {
    setInputType(inputType === 'password' ? 'text' : 'password');
  }, [inputType]);

  return (
    <Container className={className}>
      {title !== undefined && <Title>{title} {required && <span>*</span>} {tooltipContent !== undefined &&
      <Tooltip className={'tooltip'}>{tooltipContent}</Tooltip>}</Title>}
      <Content allBorders={allBorders} className={error ? 'error' : ''}>
        <input disabled={disabled} onChange={onChange} onBlur={onBlur} type={inputType} placeholder={placeholder ?? ''}
               value={value}/>
        {
          type === 'password' &&
          <PasswordContainer type={'button'} onClick={alterTypeInput}>
            {
              inputType === 'password' ?
                <i className={'icon icon-eye'}/>
                :
                <i className={'icon icon-closed-eye'}/>
            }
          </PasswordContainer>
        }
      </Content>
      <HelpContainer>
        <HelpText className={error ? 'error' : ''}>{error ? error : message ? message : ''}</HelpText>
        {limit && <Count>00/00</Count>}
      </HelpContainer>
    </Container>
  );
};

export default Input;
