import styled from 'styled-components';
import Input from '../../../components/Input';

export const Container = styled.form`
  max-width: 975px;
  margin-bottom: 40px;
  padding-right: 10px;
  padding-left: 10px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    max-width: 612px;
    margin-top: 32px;
  }
  @media screen and (max-width: 607px) {
    max-width: 312px;
    margin-top: 24px;
  }
`;

export const Title = styled.h1`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 24px;
  color: var(--grey-dark);
`;

export const ContentWrapper = styled.div`
  width: 100%;
  margin-top: 44px;
  padding: 20px 36px 52px 20px;
  border-radius: 12px;
  background-color: var(--grey-light-3);
  &.first {
    margin-top: 0;
  }
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    margin-top: 32px;
  }
  @media screen and (max-width: 607px) {
    margin-top: 16px;
    padding: 20px 16px;
  }
`;

export const ContentTitle = styled.h2`
  color: var(--purple);
  font-size: 18px;
  margin-bottom: 32px;
`;

export const InputStyled = styled(Input)`
  > div:nth-child(2) {
    border-color: var(--grey-medium-4);
  }
`;

export const ButtonContainer = styled.div`
  margin: 56px auto 0 auto;
  width: 310px;
  .btnWeb {
    border-radius: 2px;
  }
  .btnMobile {
    display: none;
  }
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    margin: 32px auto 0 auto;
    width: 296px;
  }
  @media screen and (max-width: 607px) {
    margin: 47px auto 0 auto;
    width: 120px;
    .btnWeb {
      display: none;
    }
    .btnMobile {
      display: block;
      border-radius: 2px;
    }
  }
`;

export const SaveChanges = styled.button`
  background-color: var(--purple);
  color: var(--grey-light-3);
  width: 310px;
  height: 40px;
  font-size: 16px;
  border-radius: 2px;
  margin-top: 55px;
  cursor: pointer;
`;

export const SaveChangesMobile = styled.button`
  background-color: var(--purple);
  color: var(--grey-light-3);
  width: 118px;
  height: 30px;
  font-size: 16px;
  border-radius: 2px;
  margin-top: 55px;
  cursor: pointer;
`;

export const AccountInfo = styled.div`
  display: grid;
  grid-template-columns: 270px 310px auto;
  align-items: end;
  column-gap: 16px;
  width: 100%;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    grid-template-columns: repeat(2, 256px);
    grid-auto-rows: 1fr;
    .email {
      grid-column: 1;
      grid-row: 2;
    }
  }
  @media screen and (max-width: 607px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
  }
`;

export const ChangePassword = styled.span`
  display: block;
  position: relative;
  top: 8px;
  font-size: 14px;
  color: var(--grey);
  text-decoration: underline;
  justify-self: start;
  align-self: center;
  cursor: pointer;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    grid-column: 2;
    grid-row: 2;
    justify-self: center;
  }
`;

export const PersonalData = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 410px);
  column-gap: 53px;
  row-gap: 9px;
  align-items: end;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    grid-template-columns: 251px 257px;
    grid-row: 1fr;
    column-gap: 37px;
  }
  @media screen and (max-width: 607px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
  }
`;

export const ContactInfo = styled.div`
  display: grid;
  grid-template-columns: 378px 416px;
  column-gap: 16px;
  margin-bottom: 10px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    grid-template-columns: 255px 269px;
    grid-row: 1fr;
    column-gap: 17px;
    margin-bottom: 16px;
  }
  @media screen and (max-width: 607px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    row-gap: 16px;
    margin-bottom: 16px;
  }
`;

export const CheckboxContainer = styled.div`
  label:nth-child(2) {
    display: none;
  }
  @media screen and (max-width: 607px) {
    label:nth-child(2) {
      display: flex;
    }
    label:first-child {
      display: none;
    }
  }
`;

export const CkeckboxLabel = styled.span`
  font-size: 14px;
  line-height: 22px;
  color: var(--grey);
`;

export const TooltipText = styled.p`
  width: 120px;
  color: var(--grey-medium-4);
  line-height: 15.2px;
  text-align: center;
`;
