import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import {
  Container,
  NavWrapper,
  Nav,
  UserType,
  UserTypeOption,
  Pages,
  PageOption,
  ContentWrapper,
  Content,
  Logo,
  MenuHamburguer,
  HamburguerLines,
  LogoIcon,
  Form,
  Actions,
  Dropdown,
  UserPhoto,
  UserPhotoWrapper,
  WelcomeMessage,
  ArrowIcon,
  FavoriteIconWrapper,
  FavoriteIcon,
  CartIconWrapper,
  CartIcon,
  NotLoggedIcon,
  NotLoggedWrapper,
  LoginDesktop,
  ResetLinkStyle,
  Gap,
  PhotoContainer,
  NoPhotoIconContainer,
  NoPhotoIcon,
} from './styles';
import Search from '../Search';
import UserLoggedMenu from './components/UserLoggedMenu';
import UserNotLoggedMenu from './components/UserNotLoggedMenu';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

const Header: React.FC = () => {
  const { user, logOut } = useAuth();
  const navigate = useNavigate();

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [userLoggedMenu, setUserLoggedMenu] = useState<boolean>(false);
  const [userNotLoggedMenu, setUserNotLoggedMenu] = useState<boolean>(false);
  /*
    Ignorei as linhas abaixo para evitar o 'Warning', pois o Component Product estava exigindo essas funções,
    mas não tem utilidade aqui porque os dados são pegos via Socket. Podemos avaliar uma solução melhor.
  */

  const menuRef = useRef<HTMLElement>(null);

  const userIsLogged = useMemo(() => {
    return user !== undefined;
  }, [user]);

  const handleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleUserLoggedMenu = () => {
    setUserLoggedMenu(!userLoggedMenu);
  };

  const handleUserNotLoggedMenu = () => {
    setUserNotLoggedMenu(!userNotLoggedMenu);
  };

  const handleUserLogin = () => {
    navigate('login');
  };

  const handleLogOut = useCallback(() => {
    logOut();
    window.location.href = process.env.REACT_APP_PUBLIC_URL || '';
  }, [logOut]);

  const goToFav = () => {
    if (!user) {
      navigate('/login?ReturnUrl=favoritos');
      return;
    }

    navigate('/favoritos');
  };

  useEffect(() => {
    const handler = (e: MouseEvent): void => {
      const target = e.target as HTMLElement;
      if (menuRef.current && !menuRef.current.contains(target) && menuOpen) {
        setMenuOpen(false);
      }
    };
    document.body.addEventListener('click', handler);
    return () => {
      document.body.removeEventListener('click', handler);
    };
    // eslint-disable-next-line
  }, []);

  const handleMenuNavigate = (to: string) => {
    navigate(to);
    setMenuOpen(false);
  };

  return (
    <>
      <Container>
        <NavWrapper>
          <Nav className={`${menuOpen ? 'menuActive' : ''}`} ref={menuRef}>
            <UserType>
              <UserTypeOption
                onClick={() => handleMenuNavigate('/para-negocios')}
              >
                Para negócios
              </UserTypeOption>
            </UserType>
            <Pages>
              <PageOption onClick={() => handleMenuNavigate('/sobre')}>
                Sobre nós
              </PageOption>
              <PageOption
                onClick={() => handleMenuNavigate('/politicas-privacidade')}
              >
                Política de privacidade
              </PageOption>
              <PageOption onClick={() => handleMenuNavigate('/ajuda')}>
                Ajuda
              </PageOption>
            </Pages>
          </Nav>
        </NavWrapper>
        <ContentWrapper>
          <Content>
            <Logo>
              <MenuHamburguer onClick={handleMenu}>
                <HamburguerLines
                  className={`${menuOpen ? 'menuActive' : ''}`}
                />
              </MenuHamburguer>
              <LogoIcon
                onClick={() => navigate('/')}
                className="icon icon-houpa"
              />
            </Logo>
            <Form>
              <Search />
            </Form>
            <Actions>
              <Dropdown onClick={() => setUserLoggedMenu(!userLoggedMenu)}>
                {userIsLogged ? (
                  <UserPhotoWrapper>
                    {user.image ? (
                      <PhotoContainer>
                        <UserPhoto src={user?.image} alt="Usuário" />
                      </PhotoContainer>
                    ) : (
                      <PhotoContainer>
                        <NoPhotoIconContainer>
                          <NoPhotoIcon className="icon icon-followers" />
                        </NoPhotoIconContainer>
                      </PhotoContainer>
                    )}
                    <div onClick={handleUserLoggedMenu}>
                      <WelcomeMessage>Olá, {user.name}</WelcomeMessage>
                      {userLoggedMenu ? (
                        <ArrowIcon className="icon icon-arrow-up" />
                      ) : (
                        <ArrowIcon className="icon icon-arrow-down" />
                      )}
                    </div>
                  </UserPhotoWrapper>
                ) : (
                  <>
                    <LoginDesktop>
                      <Link to="/login">Entrar ou criar conta</Link>
                    </LoginDesktop>
                    <NotLoggedWrapper onClick={handleUserNotLoggedMenu}>
                      <NotLoggedIcon className="icon icon-followers" />
                      {userNotLoggedMenu ? (
                        <ArrowIcon className="icon icon-arrow-up" />
                      ) : (
                        <ArrowIcon className="icon icon-arrow-down" />
                      )}
                    </NotLoggedWrapper>
                  </>
                )}
                {userIsLogged ? (
                  <UserLoggedMenu
                    userLoggedMenu={userLoggedMenu}
                    setUserLoggedMenu={setUserLoggedMenu}
                    logOut={handleLogOut}
                  />
                ) : (
                  <UserNotLoggedMenu
                    userNotLoggedMenu={userNotLoggedMenu}
                    setUserNotLoggedMenu={setUserNotLoggedMenu}
                    setUserIsLogged={handleUserLogin}
                  />
                )}
              </Dropdown>
              <ResetLinkStyle onClick={goToFav}>
                <FavoriteIconWrapper>
                  <FavoriteIcon className="icon icon-favorite" />
                </FavoriteIconWrapper>
              </ResetLinkStyle>
              <CartIconWrapper onClick={() => navigate('/carrinho')}>
                <CartIcon className="icon icon-cart" />
              </CartIconWrapper>
            </Actions>
          </Content>
        </ContentWrapper>
      </Container>
      <Gap />
    </>
  );
};

export default Header;
