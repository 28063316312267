import React from 'react';

import {
  Container,
  Content,
  RowColors,
  ColsColors,
  ContainerColors,
} from './styles';

const Colors: React.FC = () => {
  return (
    <Container>
      <h1>Colors</h1>

      <Content>
        <h2>Houpa Colors</h2>
        <RowColors>
          <ColsColors>
            <ContainerColors color="var(--purple-business-dark)" />

            <span>var(--purple-business-dark)</span>

            <div>
              <h3>Houpa Empresa Escuro</h3>
              <p>#3F0B6D</p>
            </div>
          </ColsColors>
          <ColsColors>
            <ContainerColors color="var(--purple-business)" />

            <span>var(--purple-business)</span>

            <div>
              <h3>Houpa Empresa</h3>
              <p>#3D0E73</p>
            </div>
          </ColsColors>
          <ColsColors>
            <ContainerColors color="var(--purple-medium)" />

            <span>var(--purple-medium)</span>

            <div>
              <h3>Houpa roxo medio</h3>
              <p>#5E0DB3</p>
            </div>
          </ColsColors>
          <ColsColors>
            <ContainerColors color="var(--purple)" />

            <span>var(--purple)</span>

            <div>
              <h3>Houpa roxo</h3>
              <p>#7F1CEF</p>
            </div>
          </ColsColors>
          <ColsColors>
            <ContainerColors color="var(--purple-hover)" />

            <span>var(--purple-hover)</span>

            <div>
              <h3>Houpa hover</h3>
              <p>#861EFC</p>
            </div>
          </ColsColors>
        </RowColors>
      </Content>

      <Content>
        <h2>Neutral Colors</h2>
        <RowColors>
          <ColsColors>
            <ContainerColors color="var(--grey-dark)" />

            <span>var(--grey-dark)</span>

            <div>
              <h3>Grey dark</h3>
              <p>#222222</p>
            </div>
          </ColsColors>
          <ColsColors>
            <ContainerColors color="var(--grey)" />

            <span>var(--grey)</span>

            <div>
              <h3>Grey</h3>
              <p>#525252</p>
            </div>
          </ColsColors>
          <ColsColors>
            <ContainerColors color="var(--grey-medium-1)" />

            <span>var(--grey-medium-1)</span>

            <div>
              <h3>Grey medium 1</h3>
              <p>#707070</p>
            </div>
          </ColsColors>
          <ColsColors>
            <ContainerColors color="var(--grey-medium-2)" />

            <span>var(--grey-medium-2)</span>

            <div>
              <h3>Grey medium 2</h3>
              <p>#676D70</p>
            </div>
          </ColsColors>
          <ColsColors>
            <ContainerColors color="var(--grey-medium-3)" />

            <span>var(--grey-medium-3)</span>

            <div>
              <h3>Grey medium 3</h3>
              <p>#838C91</p>
            </div>
          </ColsColors>
          <ColsColors>
            <ContainerColors color="var(--grey-medium-4)" />

            <span>var(--grey-medium-4)</span>

            <div>
              <h3>Grey medium 4</h3>
              <p>#919191</p>
            </div>
          </ColsColors>
          <ColsColors>
            <ContainerColors color="var(--grey-light-1)" />

            <span>var(--grey-light-1)</span>

            <div>
              <h3>Grey light 1</h3>
              <p>#C8C8C8</p>
            </div>
          </ColsColors>
          <ColsColors>
            <ContainerColors color="var(--grey-light-2)" />

            <span>var(--grey-light-2)</span>

            <div>
              <h3>Grey light 2</h3>
              <p>#E9E9E9</p>
            </div>
          </ColsColors>
          <ColsColors>
            <ContainerColors color="var(--grey-light-3)" />

            <span>var(--grey-light-3)</span>

            <div>
              <h3>Grey light 3</h3>
              <p>#FAFAFA</p>
            </div>
          </ColsColors>
        </RowColors>
      </Content>

      <Content>
        <h2>Error Colors</h2>
        <RowColors>
          <ColsColors>
            <ContainerColors color="var(--red-dark)" />

            <span>var(--red-dark)</span>

            <div>
              <h3>Houpa fail dark</h3>
              <p>#D50000</p>
            </div>
          </ColsColors>
          <ColsColors>
            <ContainerColors color="var(--red-medium)" />

            <span>var(--red-medium)</span>

            <div>
              <h3>Houpa fail medium</h3>
              <p>#E01F26</p>
            </div>
          </ColsColors>
          <ColsColors>
            <ContainerColors color="var(--red-light)" />

            <span>var(--red-light)</span>

            <div>
              <h3>Houpa fail light</h3>
              <p>#E16868</p>
            </div>
          </ColsColors>  
        </RowColors>
      </Content>

      <Content>
        <h2>Rating Colors</h2>
        <RowColors>
          <ColsColors>
            <ContainerColors color="var(--yellow-dark)" />

            <span>var(--yellow-dark)</span>

            <div>
              <h3>Houpa rating dark</h3>
              <p>#D50000</p>
            </div>
          </ColsColors>
          <ColsColors>
            <ContainerColors color="var(--yellow-medium)" />

            <span>var(--yellow-medium)</span>

            <div>
              <h3>Houpa rating medium</h3>
              <p>#E01F26</p>
            </div>
          </ColsColors>
          <ColsColors>
            <ContainerColors color="var(--yellow-light)" />

            <span>var(--yellow-light)</span>

            <div>
              <h3>Houpa rating light</h3>
              <p>#E16868</p>
            </div>
          </ColsColors>  
        </RowColors>
      </Content>
      
      <Content>
        <h2>Black and White</h2>
        <RowColors>
          <ColsColors>
            <ContainerColors color="var(--black)" />

            <span>var(--black)</span>

            <div>
              <h3>Houpa black</h3>
              <p>#000000</p>
            </div>
          </ColsColors>
          <ColsColors>
            <ContainerColors color="var(--white)" />

            <span>var(--white)</span>

            <div>
              <h3>Houpa white</h3>
              <p>#FFFFFF</p>
            </div>
          </ColsColors>
 
        </RowColors>
      </Content>
      
      <Content>
        <h2>Outher Colors</h2>
        <RowColors>
          <ColsColors>
            <ContainerColors color="var(--purple)" />

            <span>var(--purple)</span>

            <div>
              <h3>Houpa connected b2c</h3>
              <p>#7F1CEF</p>
            </div>
          </ColsColors>
          <ColsColors>
            <ContainerColors color="var(--purple-business-dark)" />

            <span>var(--purple-business-dark)</span>

            <div>
              <h3>Houpa connected b2b</h3>
              <p>#FFFFFF</p>
            </div>
          </ColsColors>
          <ColsColors>
            <ContainerColors color="var(--blue)" />

            <span>var(--blue)</span>

            <div>
              <h3>Houpa guest</h3>
              <p>#188CFF</p>
            </div>
          </ColsColors>
          <ColsColors>
            <ContainerColors color="var(--grey-light-1)" />

            <span>var(--grey-light-1)</span>

            <div>
              <h3>Houpa blocked</h3>
              <p>#C8C8C8</p>
            </div>
          </ColsColors>
          <ColsColors>
            <ContainerColors color="var(--yellow-dark)" />

            <span>var(--yellow-dark)</span>

            <div>
              <h3>Houpa waiting</h3>
              <p>#F0EE05</p>
            </div>
          </ColsColors>
          <ColsColors>
            <ContainerColors color="var(--green)" />

            <span>var(--green)</span>

            <div>
              <h3>Houpa solicitation</h3>
              <p>#FFFFFF</p>
            </div>
          </ColsColors>
          <ColsColors>
            <ContainerColors color="var(--red-dark)" />

            <span>var(--red-dark)</span>

            <div>
              <h3>Houpa canceled</h3>
              <p>#FFFFFF</p>
            </div>
          </ColsColors>
 
        </RowColors>
      </Content>


    </Container>
  );
};

export default Colors;
