import React, { createContext, useContext, useState } from 'react';
import Loading from '../components/Loading';
interface LoadingContextData {
  showLoading: () => void;
  closeLoading: () => void;
}

const LoadingContext = createContext<LoadingContextData>({} as LoadingContextData);

const LoadingProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState(false);

  const showLoading = () => {
    setOpen(true);
  };

  const closeLoading = () => setOpen(false);

  return (
    <LoadingContext.Provider value={{ showLoading, closeLoading }}>
      <Loading show={open}/>
      {children}
    </LoadingContext.Provider>
  );
};

function useLoading(): LoadingContextData {
  const context = useContext(LoadingContext);

  if (!context) {
    throw new Error('useLoading deve ser usado com o LoadingProvider');
  }

  return context;
}

export { LoadingProvider, useLoading };
