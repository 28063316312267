import React, {useCallback, useState} from 'react';
import cep from 'cep-promise';

import {
  Container,
  AddressModal,
  AddressContent,
  AddressForm,
  AddressRow,
  AddressCol,
  ButtonContainer,
  CheckboxContainer,
  ButtonClose,
  ActionsContainer,
  ButtonTrash,
} from './styles';

import Input, { RequiredTip } from '../Input';
import useForm from '../../hooks/useForm';
import Checkbox from '../Checkbox';
import Button from '../Button';
import {ButtonBack} from './styles';
import {useToast} from '../../hooks/useToast';
import {AddressInterface} from '../../interfaces/address';
import {useAlert} from '../../hooks/useAlert';
import {api} from '../../services/api';

interface Props {
  modal: boolean;
  setModal: (opened: boolean) => void;
  edit: AddressInterface;
  onAddAddress?: (newAddress:  Omit<AddressInterface, 'id'>) => void;
  onUpdateAddress?: (newAddress: AddressInterface) => void;
  onDeleteAddress?: (addressId: number) => void;
}

const ModalAddress: React.FC<Props> = ({
  modal,
  setModal,
  edit,
  onAddAddress,
  onUpdateAddress,
  onDeleteAddress,
}) => {
  const {toast} = useToast();
  const {showAlert} = useAlert();
  const {
    form,
    error,
    onChange,
    onBlur,
    validateForm,
    setValueForm,
    clearForm,
  } = useForm({
    zipCode: {
      type: 'cep',
      value: edit?.zipCode ?? '',
      required: true,
      limit: 9,
    },
    city: {
      type: '',
      value: edit?.city ?? '',
      required: true,
    },
    uf: {
      type: '',
      value: edit?.uf ?? '',
      required: true,
    },
    street: {
      type: '',
      value: edit?.street ?? '',
      required: true,
    },
    number: {
      type: '',
      value: edit?.number ?? '',
      required: true,
    },
    adjunct: {
      type: '',
      value: edit?.adjunct ?? '',
    },
    neighborhood: {
      type: '',
      value: edit?.neighborhood ?? '',
      required: true,
    },
    title: {
      type: '',
      value: edit?.title ?? '',
    },
    receiver: {
      type: '',
      value: edit?.receiver ?? '',
      required: true,
    },
    main: {
      type: '',
      value: edit?.main ?? 0,
    },
  });

  const [loading, setLoading] = useState(false);

  const getCep = useCallback(async () => {
    try {
      const addressInfo = await cep(form.zipCode);

      setValueForm('street', addressInfo.street);
      setValueForm('city', addressInfo.city);
      setValueForm('uf', addressInfo.state);
      setValueForm('neighborhood', addressInfo.neighborhood);
    } catch (e: any) {
      console.log(e);
    }
  }, [form.zipCode, setValueForm]);

  const updateAddress = useCallback(
    async (e) => {
      e.preventDefault();

      if (loading) {
        return;
      }

      try {
        setLoading(true);

        if (!validateForm()) return false;

        const response = await api.put(`/addresses/${edit.id}`, form);

        if (!response) {
          return;
        }

        onUpdateAddress && onUpdateAddress(response.data);

        clearForm();
        setModal(false);

        toast({
          type: 'success',
          description: 'Endereço atualizado com sucesso!',
        });

        return true;
      } catch (err: any) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    [
      loading,
      validateForm,
      edit.id,
      form,
      onUpdateAddress,
      clearForm,
      setModal,
      toast,
    ],
  );


  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (loading) {
        return;
      }

      try {
        setLoading(true);

        if (!validateForm()) return false;

        onAddAddress && onAddAddress({
          adjunct: form.adjunct,
          city: form.city,
          main: form.main,
          neighborhood: form.neighborhood,
          number: form.number,
          receiver: form.receiver,
          street: form.street,
          title: form.title,
          uf: form.uf,
          zipCode: form.zipCode,
        });

        clearForm();
        setModal(false);
      } catch (e: any) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [loading, validateForm, form, onAddAddress, clearForm, setModal],
  );

  const deleteAddress = useCallback(async () => {
    try {
      onDeleteAddress && onDeleteAddress(edit.id);

      clearForm();
      setModal(false);
    } catch (err: any) {
      console.log(err);
    }
  }, [edit.id, clearForm, setModal, onDeleteAddress]);

  return (
    <Container open={modal} onClose={() => setModal(false)}>
      <AddressModal>
        <ButtonClose onClick={() => setModal(false)}>
          <i className={'icon icon-exit'}/>
        </ButtonClose>
        <AddressContent>
          <ButtonBack onClick={() => setModal(false)}>
            <i className={'icon icon-arrow-left'}/>
            voltar
          </ButtonBack>
          <h1>
            {Object.entries(edit).length > 0
              ? 'Editar endereço'
              : 'Cadastrar endereço'}
          </h1>

          <AddressForm
            onSubmit={
              Object.entries(edit).length === 0 ? handleSubmit : updateAddress
            }
          >
            <AddressRow className={'mb-col'}>
              <AddressCol>
                <Input
                  value={form.zipCode}
                  error={error.zipCode}
                  required={true}
                  onBlur={() => {
                    onBlur('zipCode');
                    getCep().then();
                  }}
                  onChange={(e) => onChange('zipCode', e)}
                  inputMode={'numeric'}
                  title={'CEP'}
                />
              </AddressCol>
              <AddressCol>
                <a
                  href={
                    'http://www.buscacep.correios.com.br/sistemas/buscacep/default.cfm'
                  }
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  Não sei o meu CEP
                </a>
              </AddressCol>
            </AddressRow>

            <AddressRow className={'reverse'}>
              <AddressCol className={'flex-2'}>
                <Input
                  value={form.city}
                  error={error.city}
                  required={true}
                  onBlur={() => onBlur('city')}
                  onChange={(e) => onChange('city', e)}
                  title={'Cidade'}
                />
              </AddressCol>
              <AddressCol>
                <Input
                  value={form.uf}
                  error={error.uf}
                  required={true}
                  onBlur={() => onBlur('uf')}
                  onChange={(e) => onChange('uf', e)}
                  title={'UF'}
                />
              </AddressCol>
            </AddressRow>

            <AddressRow>
              <AddressCol>
                <Input
                  value={form.street}
                  error={error.street}
                  required={true}
                  onBlur={() => onBlur('street')}
                  onChange={(e) => onChange('street', e)}
                  title={'Endereço'}
                />
              </AddressCol>
            </AddressRow>

            <AddressRow>
              <AddressCol>
                <Input
                  value={form.number}
                  error={error.number}
                  required={true}
                  inputMode={'numeric'}
                  onBlur={() => onBlur('number')}
                  onChange={(e) => onChange('number', e)}
                  title={'Número'}
                />
              </AddressCol>
              <AddressCol className={'mb-flex-2'}>
                <Input
                  value={form.adjunct}
                  error={error.adjunct}
                  onBlur={() => onBlur('adjunct')}
                  onChange={(e) => onChange('adjunct', e)}
                  title={'Complemento'}
                />
              </AddressCol>
            </AddressRow>

            <AddressRow>
              <AddressCol>
                <Input
                  value={form.neighborhood}
                  error={error.neighborhood}
                  required={true}
                  onBlur={() => onBlur('neighborhood')}
                  onChange={(e) => onChange('neighborhood', e)}
                  title={'Bairro'}
                />
              </AddressCol>
            </AddressRow>

            <AddressRow className={'mb-col'}>
              <AddressCol>
                <Input
                  value={form.title}
                  error={error.title}
                  onBlur={() => onBlur('title')}
                  onChange={(e) => onChange('title', e)}
                  title={'Salvar este endereço como:'}
                  placeholder={'Ex.: Casa, Trabalho, etc.'}
                />
              </AddressCol>
              <AddressCol>
                <Input
                  value={form.receiver}
                  error={error.receiver}
                  required={true}
                  onBlur={() => onBlur('receiver')}
                  onChange={(e) => onChange('receiver', e)}
                  title={'Digite o nome de quem receberá o pedido'}
                  placeholder={'Ex: Fernanda (irmã)'}
                />
              </AddressCol>
            </AddressRow>

            <ActionsContainer>
              {edit.main !== 1 && edit.main !== '1' && (
                <CheckboxContainer>
                  <Checkbox
                    checked={form.main === '1'}
                    onChange={(check: boolean) =>
                      setValueForm('main', check ? '1' : '0')
                    }
                  >
                    Este é endereço principal
                  </Checkbox>
                </CheckboxContainer>
              )}

              {Object.entries(edit).length > 0 &&
              edit.main !== '1' &&
              edit.main !== 1 && (
                <ButtonTrash
                  onClick={() =>
                    showAlert({
                      type: 'error',
                      description:
                        'Tem certeza que deseja excluir este endereço?',
                      cancelText: 'Cancelar',
                      onSubmit: () => deleteAddress(),
                    })
                  }
                >
                  <i className="icon icon-trashcan"/>
                  <span>Excluir este endereço</span>
                </ButtonTrash>
              )}
            </ActionsContainer>
            <RequiredTip removePadding/>
            <ButtonContainer>
              <Button type={'submit'} color={'var(--purple)'} scale={'scale-3'}>
                {Object.entries(edit).length === 0
                  ? 'Adicionar endereço'
                  : 'Atualizar endereço'}
              </Button>
            </ButtonContainer>
          </AddressForm>
        </AddressContent>
      </AddressModal>
    </Container>
  );
};

export default ModalAddress;
