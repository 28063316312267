import React, { useState, useCallback, useEffect } from 'react';
import {
  Container,
  Content,
  InputCodeContainer,
  InputContainerTitle,
  InputCodeWrapper,
  CodeExample,
  PreFormater,
} from './styles';
import InputCode from '../../../components/InputCode';

const InputsCode = (): JSX.Element => {
  const [code, setCode] = useState('');
  const [status, setStatus] = useState('');

  const verifyCode = useCallback(async () => {
    try {
      if (code !== '0000') {
        setStatus('');
        setStatus('error');
        return false;
      }
      setStatus('success');
    } catch (err: any) {
      console.log(err);
    }
  }, [code]);

  useEffect(() => {
    code.length > 3 && verifyCode();
    // eslint-disable-next-line
  }, [code]);

  const fakeFunction = useCallback(
    (codeFake: string) => {
      setCode(codeFake);
    },
    [setCode]
  );

  return (
    <Container>
      <Content>
        {/* Normal */}
        <InputCodeContainer>
          <InputContainerTitle>Input de código normal</InputContainerTitle>
          <InputCodeWrapper>
            <InputCode validateCode={fakeFunction} status={status} />
          </InputCodeWrapper>
          <CodeExample>
            <PreFormater>
              {`
                import InputCode from "components/InputCode";

                <InputCode
                  validateCode={() => {}}
                />
              `}
            </PreFormater>
          </CodeExample>
        </InputCodeContainer>
        {/* Success */}
        <InputCodeContainer>
          <InputContainerTitle>Input de código Sucesso</InputContainerTitle>
          <InputCodeWrapper>
            <InputCode validateCode={() => {
              console.log('teste');
            }} status="success" />
          </InputCodeWrapper>
          <CodeExample>
            <PreFormater>
              {`
               import InputCode from "components/InputCode";

               <InputCode
                 validateCode={() => {}}
                 status="success"
               />
              `}
            </PreFormater>
          </CodeExample>
        </InputCodeContainer>
        {/* Erro */}
        <InputCodeContainer>
          <InputContainerTitle>Input de código erro</InputContainerTitle>
          <InputCodeWrapper>
            <InputCode validateCode={() => {
              console.log('teste');
            }} status="error" />
          </InputCodeWrapper>
          <CodeExample>
            <PreFormater>
              {`
               import InputCode from "components/InputCode";

               <InputCode
                 validateCode={() => {}}
                 status="error"
               />
              `}
            </PreFormater>
          </CodeExample>
        </InputCodeContainer>
      </Content>
    </Container>
  );
};

export default InputsCode;
