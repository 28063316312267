import styled from 'styled-components';

interface Props {
  background: string
}

export const Container = styled.div`
  max-width: var(--container);
  margin: 0 auto;
  padding: 24px 0;
  display: flex;

  @media (max-width: 1279px) {
    padding: 0;
  }
`;

export const Cols = styled.div`
  flex: 1;

  &:first-child {
    max-width: 291px;
    min-width: 291px;
    margin-right: 33px;

    @media (max-width: 1279px) {
      max-width: 80px;
      margin-right: 24px;
      display: none;
    }
  }
`;

export const MenuContainer = styled.div`
  padding: 32px 0;
  width: 100%;
  background: ${(props) => props.theme.colors.greyLight3};

  @media (max-width: 1279px) {
    height: 100%;
    position: fixed;
    width: 80px;
    left: 0;
    top: 0;
  }

  @media (max-width: 1279px) {
    width: 80px;
  }

  @media (max-width: 607px) {
    width: 52px;
  }
`;

export const MenuHeader = styled.div`
  width: 100%;
  padding: 0 44px;
  display: flex;
  align-items: center;
  gap: 20px;
  div:nth-child(2) {
    > h3 {
      font-size: 18px;
      font-weight: 700;
      line-height: 21.09px;
    }

    > p {
      font-size: 12px;
      color: ${(props) => props.theme.colors.greyMedium3};
      line-height: 14.06px;
      margin-top: 8px;
    }
  }


  @media (max-width: 1279px) {
    display: none;
  }
`;

export const MenuList = styled.ul`
  width: 100%;
  margin-top: 33px;
  outline: 0;

  > li {
    width: 100%;

    .accordion:hover {
      background: ${(props) => props.theme.colors.greyLight2};
    }

    a,
    .accordionItem {
      display: flex;
      align-items: center;
      padding: 10px 0 10px 48px;
      text-decoration: none;
      color: ${(props) => props.theme.colors.grey};

      .sub-item {
        margin-left: 46px;
        font-size: 14px;
      }

      > div {
        width: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
      }

      @media (max-width: 1279px) {
        > span {
          display: none;
        }

        > div {
          width: auto;
          margin-right: 0;
        }

        padding: 10px 0;
        justify-content: center;
      }

      &:hover,
      &.active {
        background: ${(props) => props.theme.colors.greyLight2};

        > span {
          color: ${(props) => props.theme.colors.purple};
        }
      }

      cursor: pointer;
    }

    i {
      font-size: 20px;
      color: ${(props) => props.theme.colors.greyMedium4};
    }

    > span {
      font-size: 1rem;
    }

    @media (max-width: 1279px) {
      margin-bottom: 30px;
    }
  }

  @media (max-width: 1279px) {
    height: 100%;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .desktop-only {
      display: none;
    }
  }
`;

export const Title = styled.h1`
  font-size: 20px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.greyDark};
  padding-top: 28px;
  margin-bottom: 55px;
  @media screen and (max-width: 607px) {
    font-size: 18px;
    margin-bottom: 42px;
  }
  @media screen and (max-width: 1279px) {
    margin-bottom: 24px;
  }
`;

export const NoUserPhoto = styled.div<Props>`
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 1px solid var(--grey-light-2);
  background-size: cover;
  background-image: url(${props => props.background});
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const CameraIconContainer = styled.div`
  position: absolute;
  bottom: -2px;
  right: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--grey-light-3);
  box-shadow: 0 2px 4px 0 rgba(96, 97, 112, 0.1) ;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CameraIcon = styled.div`
  font-size: 10px;
  color: var(--grey-light-1);
`;

export const HiddenInputFile = styled.input`
  display: none;
`;
