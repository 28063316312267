import styled from 'styled-components';

interface Props {
  isOpen?: boolean;
  error?: boolean;
  status?: number;
}

export const Container = styled.div<Props>`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  justify-content: flex-end;
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow-x: hidden;
  z-index: 9999;
  top: 0;
  right: 0;

  .backDrop {
    position: absolute;
    width: 100vw;
    height: 100vh;
  }

  .sideCart {
    max-width: 415px;
    border-left: 1px solid ${(props) => props.theme.colors.greyLight2};
    width: 100%;
    height: 100%;
    background-color: var(--white);
    overflow-y: auto;
    transform: translateX(0px);
    transform: ${(props) =>
    props.isOpen ? 'translateX(0px)' : 'translateX(415px)'};
    z-index: 100;

    @media (max-width: 607px) {
      max-width: 100vw;
      border-left: none;
    }
  }
`;

export const ModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 36px;
  background-color: var(--black);

  > p {
    font-size: 14px;
    font-weight: 500;
    color: var(--white);

    > span {
      margin-left: 10px;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  .inputContainer {
    width: 145px;
    display: flex;
    flex-direction: column;
  }

  .buttonContainer {
    flex: 1;
    margin-left: 48px;
  }

  .buttonFreightContainer {
    margin-left: 0;
  }

  .linkFreightContainer {
    margin-top: 4px;

    > a {
      font-size: 10px;
      font-weight: 500;
      text-decoration: underline;
      color: var(--greyDark);
    }
  }

  @media (min-width: 380px) and (max-width: 607px) {
    .inputContainer {
      width: 50%;
      display: flex;
      flex-direction: column;
    }
  }
`;

export const ButtonClose = styled.button`
  position: absolute;
  right: 12px;
  outline: none;
  color: var(--white);
  font-size: 26px;
  font-weight: 600;
  cursor: pointer;
  z-index: 2;
`;

export const ModalContent = styled.div`
  padding: 32px 44px 44px;

  @media (max-width: 607px) {
    padding: 12px 24px 24px;
  }
`;

export const ProductsContainer = styled.div`
  padding-bottom: 10px;

  > div {
    display: flex;
    justify-content: space-between;

    > strong {
      margin-top: 34px;
      font-weight: 500;
      font-size: 12px;
    }
  }
`;

export const Wrapper = styled.div`
  border-bottom: 1px solid var(--grey-light-2);

  > div {
    &:nth-child(1) {
      margin-bottom: 20px;
    }

    > h4 {
      font-weight: 500;
      font-size: 12px;
      margin-bottom: 12px;
    }

    &:nth-child(1) {

    }

    &:nth-child(2) {
      .radioContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .freightRadio {
          span:first-child {
            color: var(--purple);
          }
        }

        span {
          font-size: 12px;
          font-weight: 500;
        }

        .spanPrice {
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 400;
        }

        .freightInfo {
          display: flex;
          flex-direction: column;
        }

        .freightType {
          font-size: 12px;
          font-weight: 500;
          color: ${(props) => props.theme.colors.greyDark};
        }

        .freightDescription {
          font-size: 10px;
          font-weight: 400;
          color: ${(props) => props.theme.colors.greyMedium4};
        }
      }
    }
  }
`;

export const InputSolid = styled.input`
  height: 40px;
  outline: 0;
  border: 1px solid var(--grey-light-2);
  border-radius: 5px;
  padding: 0 10px;
  width: 100%;
`;

export const InputMessage = styled.span<Props>`
  color: ${(props) => props.theme.colors.redDark};
  visibility: ${(props) => props.error ? 'visible' : 'hidden'};
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  margin-left: 4px;
`;

export const InputMessageDiscount = styled.span<Props>`
  color: ${(props) =>
    props.status === 1 ? props.theme.colors.green : props.theme.colors.redDark};
  visibility: ${(props) => (props.status !== 0 ? 'visible' : 'hidden')};
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  margin-left: 4px;
`;

export const ButtonContainer = styled.div`
  margin-top: 45px;

  > a {
    text-decoration: none;
  }

  > button {
    &:nth-child(2) {
      margin-top: 8px;
    }
  }
`;

export const Details = styled.div`
  margin-top: 10px;

  > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    > span {
      line-height: 18px;

      &:nth-child(1) {
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0.2px;
      }

      &:nth-child(2) {
        font-size: 12px;
        font-weight: 400;
      }
    }

    .text-muted {
      color: ${(props) => props.theme.colors.greyMedium4};
    }
  }
`;
