import React from 'react';

import {
  Container,
  Wrapper,
  StepContainer,
  Line,
  StepsWrapper,
  StepCircle,
  StepText,
  Icon,
  Content,
  Footer,
} from './styles';

interface StepProps {
  active?: number;
  canceled?: boolean;
  stepCanceled?: boolean;
  order?: string;
  payment?: string;
  separate?: string;
  delivery?: string;
  awaitDelivery?: string;
}

const StepBar: React.FC<StepProps> = ({
  active = 0, 
  canceled = false,
  stepCanceled = false,
  order = 'Pedido realizado',
  payment = 'Pagamento aprovado',
  separate = 'A separar',
  delivery = 'A entregar',
  awaitDelivery = 'Entrega realizada',
  }) => {

  const steps = [
    { text: order, icon: 'icon-success' },
    { text: payment, icon: 'icon-credit-card' },
    { text: separate, icon: 'icon-stock' },
    { text: delivery, icon: 'icon-truck' },
    { text: awaitDelivery, icon: 'icon-home' },
  ];

  const isCompleted = (index: number) => {
    if (index > active) return 'waiting';
    if (index < active || canceled || index === 0) return 'completed';
    if (index === active) return 'active';
  };

  const isCanceled = (icon: string, index: number) => {
    if (canceled && index === 0) return 'icon-exit'
    if (stepCanceled && index === active) return 'icon-exit';
    if (index < active || index === 0) return 'icon-success';
    else return icon;
  };

  const verifyCanceled = (index: number) => index !== 0 && canceled;

  return (
    <Container>
      <Wrapper>
        <Line className={canceled ? 'canceled' : ''} />
        <StepsWrapper>
          {steps.map((step, index) => (
            <StepContainer key={index}>
              <Content>
                <StepCircle className={`${isCompleted(index)} ${stepCanceled && index === active ? 'stepCanceled' : ''} ${verifyCanceled(index) ? 'canceled' : ''}`}>
                  <Icon className={`icon ${isCanceled(step.icon, index)}`} />
                </StepCircle>
              </Content>
              <Footer className={isCompleted(index)}>
                <StepText>{verifyCanceled(index) ? 'Não processado' : step.text}</StepText>
              </Footer>
            </StepContainer>
          ))}
        </StepsWrapper>
      </Wrapper>
    </Container>
  );
};

export default StepBar;
