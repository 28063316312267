import styled from 'styled-components';

interface Props {
  exchangedOrReturned?: boolean
}

export const Container = styled.div<Props>`
  background-color: var(--grey-light-3);
  padding: 32px 24px;

  ${(props) => props.exchangedOrReturned && 'opacity: 75%;'}

  &.devolution {
    opacity: 15%;
  }

  @media (max-width: 1279px) {
    padding: 10px 18px;
  }

  @media (max-width: 607px) {
    padding: 16px 0 31px;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 607px) {
    justify-content: stretch;
    flex-direction: column;
  }
`;

export const Row = styled.div`
  display: flex;
  flex: 1;

  &:first-child {
    margin-right: 139px;
    max-width: 281px;

    @media (max-width: 1279px) {
      max-width: 195.68px;
      margin-right: 40px;
    }


    @media (max-width: 607px) {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 51px;
    }
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;

  @media (max-width: 1279px) {
    .storeInfo {
      margin-bottom: 10px;
    }
  }

`;

export const ProfileContainer = styled.div`
  width: 83px;
  height: 117px;
  background-color: var(--white);

  margin-right: 26px;

  > img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 1279px) {
    width: 42.76px;
    height: 59.75px;

    margin-right: 8px;
  }

  @media (max-width: 607px) {
    width: 57px;
    height: 80px;
    margin-right: 16px;
  }
`;

export const Title = styled.p`
  font-weight: 700;
  margin-bottom: 12px;
  font-size: 16px;

  white-space: nowrap;
  max-width: 145px;
  overflow: hidden;
  text-overflow: ellipsis;

  color: var(--grey-dark);

  @media (max-width: 1279px) {
    font-size: 12px;
  }

  &.noMb {
    margin-bottom: 0;
  }
`;

export const Text = styled.p`
  color: var(--grey-dark);
  font-size: 16px;
  white-space: nowrap;
  max-width: 145px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 1279px) {
    font-size: 10px;
  }
`;

export const ProductUnavailable = styled.div`
  margin: auto;
  text-align: center;
  padding-top: 40%;
  height: 100%;
`

export const InfoCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LinkRow = styled.div`
  display: flex;
  align-items: center;

  position: absolute;
  bottom: 0;
  right: 0;

  a {
    font-size: 12px;
    color: var(--purple);
    margin: 0 15px;
    white-space: nowrap;

    &.devolution {
      color: var(--grey-medium-4);
      cursor: default;
    }

    @media (max-width: 1279px) {
      font-size: 10px;
      margin: 0 15px 0 0;
    }
  }

  @media (max-width: 1279px) {
    display: none;
  }
`;


export const LinkRowMobile = styled(LinkRow)`
  display: none;
  margin-bottom: 8px;
  position: static;

  > a {
    font-size: 10px;
    margin-right: 8px;
  }


  @media (max-width: 1279px) {
    display: flex;
  }
`;

export const TooltipContainer = styled.div`
  margin: 20px;
  width: 287px;
  font-size: 20px;
  color: var(--grey);

  > div:first-child {
    margin-bottom: 20px;
  }

  @media (max-width: 1279px) {
    font-size: 1rem;
    width: 230px;
    margin: 14px;
  }

  @media (max-width: 607px) {
    font-size: 10px;
    width: 150px;
    margin: 0;
  }
`;

export const DevolutionButton = styled.button`
  width: 121px;
  height: 26px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--grey-light-2);
  font-size: 12px;

  margin-top: 10px;

  &.mb {
    display: none;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 16px;
    height: 16px;
    border-radius: 50%;

    margin-right: 10px;
    background-color: var(--white);
  }

  @media(max-width: 607px){
    display: none;

    &.mb {
     display: flex;
    }
  }
`;

export const ReplaceLink = styled.span`
  font-size: 12px;
  color: var(--purple);
  white-space: nowrap;
  text-decoration: underline;
  cursor: pointer;
`;
