import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  flex: 1;
  min-height: calc(100vh - 394px);

  @media (max-width: 1279px) {
    min-height: calc(100vh - 563.58px) ;
  }

  @media (max-width: 607px) {
    min-height: calc(100vh - 638.67px) ;
  }
`;
