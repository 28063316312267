import {api} from '../services/api';
import secure from '../config/secureLS';
import { useToast } from './useToast';

interface UseCartInterface {
  addToCart: (sellerProfileUrl: string, variantId: number) => Promise<boolean>;
  updateItem: (itemId: number, quantity: number) => Promise<boolean>;
  deleteItem: (itemId: number, showToast?: boolean) => Promise<boolean>;
}

export const useCart = (): UseCartInterface => {
  const { toast } = useToast();

  const addToCart = async (sellerProfileUrl: string, variantId: number) => {
    try {
      const { data } = await api.post('carts/products', {
        variantId,
        sellerProfileUrl,
      });

      if (data.sessionId) {
        secure.set('@houpa:session_id', { data: data.sessionId });
      }

      return true;
    } catch (error: any) {
      return false;
    }
  };

  const updateItem = async (itemId: number, quantity: number) => {
    try {
      const { data } = await api.put(`/carts/products/${itemId}`, { quantity });

      toast({
        description: data.message,
        type: 'success',
      });

      return true;
    } catch (error: any) {
      toast({
        description: 'Não foi possível atualizar o item',
        type: 'error',
      });

      return false;
    }
  };

  const deleteItem = async (itemId: number, showToast = true as boolean) => {
    try {
      const { data } = await api.delete(`/carts/products/${itemId}`);

      if(showToast) {
        toast({
          description: data.message,
          type: 'success',
        });
      }

      return true;
    } catch (error: any) {
      console.log('error', error);

      toast({
        description: 'Não foi possível deletar o item',
        type: 'error',
      });

      return false;
    }
  };

  return { addToCart, updateItem, deleteItem };
};
