import React, {InputHTMLAttributes, KeyboardEvent, useCallback, useEffect, useState} from 'react';
import { Container, Input, SearchIcon } from './styles';
import {useLocation, useNavigate} from 'react-router-dom';
import {useQuery} from '../../hooks/useQuery';

const Search: React.FC<InputHTMLAttributes<HTMLInputElement>> = ({placeholder}) => {
  const navigate = useNavigate();
  const query = useQuery();
  const location = useLocation();

  const searchParam = query.get('s');

  const [search, setSearch] = useState('');

  const handleSearch = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if(e.key === 'Enter') {
      e.preventDefault();

      navigate(`pesquisa?s=${search}&p=1`);
    }
  }, [navigate, search]);

  useEffect(() => {
    if(searchParam !== null && location.pathname.includes('pesquisa')) {
      setSearch(searchParam);
    }

  }, [location.pathname, searchParam]);

  return (
    <Container>
      <Input
        type="text"
        value={search}
        onKeyDown={(e) => handleSearch(e)}
        onChange={(e) => setSearch(e.target.value)}
        placeholder={placeholder ?? 'Buscar por...'}
      />
      <SearchIcon className="icon icon-search" />
    </Container>
  );
};

export default Search;
