import styled from 'styled-components';

import Background from '../../assets/png/back-b2c.png';

export const Container = styled.div`
  min-height: 100vh;
  background-image: url(${Background});
  background-size: cover;

  @media (max-width: 1279px) {
    background-image: none;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: var(--container);
  min-height: 100vh;

  margin: 0 auto;
  padding: 36px 0;
`;

export const Logo = styled.div`
  cursor: pointer;
  max-height: 38px;

  > i {
    font-size: 36px;
    color: ${(props) => props.theme.colors.white};
  }

  @media (max-width: 1279px) {
    display: none;
  }
`;

export const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 524px;
  padding: 40px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 10px;

  > h2 {
    font-family: var(--poppins);
    font-size: 24px;
    margin-top: 38px;
    font-weight: 700;

    @media (max-width: 1279px) {
      margin-top: 68px;
    }

    @media (max-width: 607px) {
      margin-top: 29px;
    }
  }

  @media (max-width: 1279px) {
    width: 100%;
    padding: 0;
  }
`;

export const ButtonBack = styled.button`
  outline: none;
  background: transparent;
  display: flex;
  align-items: center;
  font-size: 1rem;
  outline: 0;
  cursor: pointer;

  > i {
    margin-right: 5px;
    color: ${({ theme }) => theme.colors.purple};
  }
`;

export const RegisterContent = styled.div`
  margin-top: 5px;
  height: 100%;

  > p {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.grey};

    @media (max-width: 1279px) {
      margin-bottom: 99px;
    }

    @media (max-width: 607px) {
      font-size: 12px;
      margin-bottom: 72px;
    }
  }

  form {
    @media (max-width: 1279px) and (min-width: 607px) {
      max-width: 453px;
      margin: 0 auto;
    }
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  margin-top: 24px;
`;

export const ButtonContainer = styled.div`
  margin: 89px auto 74px;
  max-width: 214px;

  @media screen and (min-width: 607px) and (max-width: 1279px) {
    max-width: 296px;
  }
`;

export const LoginLink = styled.p`
  display: flex;
  justify-content: center;
  margin-top: 26px;

  > a {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.greyDark};
    margin-left: 5px;
  }
`;

export const CodeContainer = styled.div`
  margin-top: 25px;
  height: 100%;
`;

export const CodeInfo = styled.p`
  color: ${({ theme }) => theme.colors.grey};
  line-height: 18.75px;
  margin-bottom: 108px;

  > p {
    font-weight: 700;
  }

  @media (max-width: 1279px) {
    margin-bottom: 212px;
  }

  @media (max-width: 607px) {
    margin-bottom: 72px;
  }
`;

export const ResendingEmail = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 67px;

  > button {
    outline: 0;
    border: none;
    background: transparent;
    font-size: 1rem;
    margin-left: 5px;

    text-decoration: underline;
    color: ${(props) => props.theme.colors.greyDark};
    font-weight: 700;

    cursor: pointer;
  }

  @media (max-width: 607px) {
    margin-top: 21px;
  }
`;

export const InputCodeContainer = styled.div`
  width: 100%;

  @media (max-width: 1279px) and (min-width: 607px) {
    width: 450px;
    margin: 0 auto;
  }
`;

export const TermsContainer = styled.div`
  > p {
    color: ${({ theme }) => theme.colors.redDark};
    font-size: 12px;
  }
`;

export const TermsLink = styled.button`
  display: contents;
  cursor: pointer;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.blue};
`;
