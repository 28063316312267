import styled from 'styled-components';

interface ProductImageProps {
  smallColumn: number;
  bigColumn: number;
  isFavorite?: boolean;
}

export const ProductImages = styled.div<ProductImageProps>`
  display: grid;
  grid-template-columns: ${({ smallColumn, bigColumn }) => `${smallColumn}px ${bigColumn}px`};
  grid-column-gap: 16px;

  height: ${({ bigColumn }) => (bigColumn * 1.3)}px;

  img {
    width: 100%;
    height: 100%;
  }

  .all-images {
    width: 100%;
    height: 100%;
    padding-right: 10px;

    overflow-y: auto;
    overflow-x: hidden;

    display: flex;
    flex-direction: column;

    .small-image {
      width: ${({ smallColumn }) => smallColumn}px;
      height: ${({ smallColumn }) => (smallColumn * 1.3)}px;

      margin-bottom: 16px;

      cursor: pointer;
    }

    .image-not-selected {
      opacity: 0.4;
    }

    .image-selected {
      box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1);
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
    }

    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .big-image-container {
    position: relative;

    .arrow-button-prev {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      border-radius: 50%;
      cursor: pointer;
      padding: 30px;
    }

    .arrow-button-next {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      border-radius: 50%;
      cursor: pointer;
      padding: 30px;
    }

    .image-sold-out{
      opacity: 0.4;
    }

    .alert-sold-out {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #E9E9E9;
      border-radius: 5px;
      padding: 10px;
      text-align: center;
      width: 194.32px;
      height: 40px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15), inset 0px 1px 2px rgba(0, 0, 0, 0.1);
      z-index: 1;

      > span{
        color:#A9A1A1;
        font-weight: normal;
        font-size: 14px;
      }
    }

    @media (max-width: 607px) {
      .arrow-button-prev {
        display: none;
      }

      .arrow-button-next {
        display: none;
      }
    }

    .favorite-button {
      width: 48px;
      height: 48px;
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;

      background: ${props => props.isFavorite ? props.theme.colors.redLight : props.theme.colors.white};
      box-shadow: 0 2px 4px rgba(40, 41, 61, 0.1);

      cursor: pointer;

      position: absolute;
      top: 24px;
      right: 24px;

      i {
        font-size: 18px;
        color: ${props => props.isFavorite ? props.theme.colors.white : props.theme.colors.redLight};
      }

      @media (max-width: 607px) {
        width: 25px;
        height: 25px;

        top: 12px;
        right: 12px;

        i {
          font-size: 9px;
        }
      }
    }
  }
`;
