import styled from 'styled-components';

export const Button = styled.button`
  width: max-content;
  height: max-content;

  margin-left: 16px;

  cursor: pointer;

  position: relative;

  > i {
    font-size: 18px;
    color: ${props => props.theme.colors.greyDark};
  }

  #share-modal {
    display: block;

    .modal-content{
      position: absolute;
      right: 0;
      top: 30px;
      z-index: 1;

      cursor: auto;

      width: 235px;
      height: 202px;

      padding: 22px;

      background: ${props => props.theme.colors.white};
      box-shadow: 0 2px 4px rgba(40, 41, 61, 0.1);

      border-radius: 5px;

      display: flex;
      flex-direction: column;

      .modal-title {
        font-size: 18px;
        text-align: center;

        color: ${props => props.theme.colors.grey};

        margin-bottom: 25px;
      }

      .underline {
        text-decoration: underline;
      }

      .modal-item {
        display: flex;
        margin-bottom: 24px;

        cursor: pointer;

        align-items: center;

        text-decoration: none;

        > i {
          font-size: 20px;
          color: ${props => props.theme.colors.greyDark};

          margin-right: 12px;
        }

        > p {
          font-size: 12px;
          color: ${props => props.theme.colors.greyMedium1};
        }
      }
    }
  }

`;
