import React from 'react';
import {ColorContainer, Container} from './styles';

interface Props {
  color?: string;
  onClick?: () => void;
}

const ButtonFilters: React.FC<Props> = ({ color, onClick, children }) => {
  return (
    <Container>
      {color && <ColorContainer color={color} />}
      <p>{children}</p>
      <i className="icon icon-exit" onClick={onClick}/>
    </Container>
  );
};

export default ButtonFilters;
