import React, {FormEvent, useCallback, useEffect, useState} from 'react';
import {
  ButtonBack,
  ButtonContainer,
  CodeContainer,
  CodeInfo,
  Container,
  Content,
  InputCodeContainer,
  InputContainer,
  LoginLink,
  Logo,
  RegisterContainer,
  RegisterContent,
  ResendingEmail,
  TermsContainer,
  TermsLink
} from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import useForm from '../../hooks/useForm';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import InputCode from '../../components/InputCode';
import Checkbox from '../../components/Checkbox';
import {useToast} from '../../hooks/useToast';
import {api} from '../../services/api';
import {useAuth} from '../../hooks/useAuth';
import {handleErrors} from '../../helpers/utils';
import { useLoading } from '../../hooks/useLoading';

interface FinishRegister {
  id: null,
  email: string,
  password: string,
}

const Register: React.FC = () => {
  const location = useLocation();
  const {showLoading, closeLoading} = useLoading();


  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const query = useQuery();

  const state = location.state as FinishRegister;

  const [stage, setStage] = useState(query.get('code') === 'true' ? 'code' : 'register');
  const [errorTerms, setErrorTerms] = useState(false);
  const [terms, setTerms] = useState(false);
  const [status, setStatus] = useState('');
  const [userId, setUserId] = useState(null);

  const {toast} = useToast();
  const navigate = useNavigate();
  const {signIn} = useAuth();


  const {form, error, setError, onBlur, onChange, validateForm, setValueForm} = useForm({
    name: {
      type: 'fullname',
      required: true
    },
    email: {
      type: 'email',
      required: true
    },
    password: {
      type: 'password',
      required: true
    },
    confirmPassword: {
      type: 'password',
      required: true
    }
  });

  function goBack() {
    switch (stage) {
    case 'register':
      navigate('/');
      break;
    case 'code':
      setStage('register');
      break;
    }
  }

  const handleSubmit = useCallback(async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      let status = validateForm();

      if (!terms) {
        setErrorTerms(true);
        status = false;
      }

      if (!status) return false;

      if (form.password !== form.confirmPassword) {
        error.confirmPassword = 'As senhas precisam ser iguais';
        setError({...error});

        return false;
      }

      showLoading();

      const {data: {data}} = await api.post('/users', form);
      setUserId(data.userId);
      setStage('code');

      closeLoading();
    } catch (e: any) {
      setError(handleErrors(e, error));
      closeLoading();
    }
  }, [error, form, setError, terms, validateForm, showLoading, closeLoading]);

  const resendingEmail = useCallback(async () => {
    try {
      await api.post('/access-code', {userId: userId || state.id});

      toast({
        type: 'success',
        description: 'Email reenviado com sucesso!'
      });
    } catch (e: any) {
      toast({
        type: 'error',
        description: e.message
      });

      setError(handleErrors(e, error));
    }
    // eslint-disable-next-line
  }, [error, setError, toast, userId]);

  const handleSignIn = useCallback(async () => {
    await signIn(form);
  }, [form, signIn]);

  const validateCode = useCallback(async (code: string) => {
    try {
      await api.put(`/access-code/${code}`, { userId });

      setStatus('success');
      await handleSignIn();
    } catch (error: any) {
      setStatus('');
      setStatus('error');
    }
  }, [handleSignIn, userId]);

  useEffect(() => {
    if(state){
      setValueForm('email', state.email);
      setValueForm('password', state.password);
      setUserId(state.id);

      resendingEmail().then();
    }
    // eslint-disable-next-line
  }, [state]);

  const RegisterStage = () => {
    return (
      <RegisterContent>
        <p>Preencha os dados abaixo para concluir seu cadastro.</p>
        <form onSubmit={handleSubmit}>
          <InputContainer>
            <Input title={'Nome e sobrenome'} value={form.name} onBlur={() => onBlur('name')}
              onChange={(e) => onChange('name', e)} error={error.name} required
              placeholder={'Insira o seu nome'}/>
          </InputContainer>

          <InputContainer>
            <Input title={'E-mail'} value={form.email} onBlur={() => onBlur('email')}
              onChange={(e) => onChange('email', e)}
              error={error.email} required placeholder={'exemplo@exemplo.com'}/>
          </InputContainer>

          <InputContainer>
            <Input title={'Senha'} value={form.password} onBlur={() => onBlur('password')}
              onChange={(e) => onChange('password', e)} error={error.password} type={'password'}
              required placeholder={'********'}/>
          </InputContainer>

          <InputContainer>
            <Input title={'Confirmar Senha'} value={form.confirmPassword} onBlur={() => onBlur('confirmPassword')}
              onChange={(e) => onChange('confirmPassword', e)} error={error.confirmPassword}
              type={'password'} required placeholder={'********'}/>
          </InputContainer>

          <ButtonContainer>
            <Button expand type={'submit'} color={'var(--purple)'} scale={'scale-4'}>Confirmar</Button>
          </ButtonContainer>

          <TermsContainer>
            <Checkbox checked={terms} onChange={(check: boolean) => {
              if (check) setErrorTerms(false);
              setTerms(check);
            }} scale={'scale-1'}>
              Aceito os <TermsLink onClick={() => navigate('/termos')} type={'button'}>Termos e condições</TermsLink> e autorizo o uso de meus
              dados de acordo
              com a <TermsLink onClick={() => navigate('/politicas-privacidade')} type={'button'}>Declaração de privacidade</TermsLink>.
            </Checkbox>
            {errorTerms && <p>Necessário aceitar os termos de uso para prosseguir</p>}
          </TermsContainer>
        </form>
      </RegisterContent>
    );
  };

  return (
    <Container>
      <Content>
        <Logo onClick={() => navigate('/')}>
          <i className={'icon icon-houpa'} />
        </Logo>
        <RegisterContainer>
          {!state && (
            <ButtonBack onClick={goBack}>
              <i className={'icon icon-arrow-left'} />
              voltar
            </ButtonBack>
          )}
          <h2>Criar sua conta</h2>
          {stage === 'register' && RegisterStage()}
          {stage === 'code' && (
            <CodeContainer>
              <CodeInfo>
                Insira o código de 4 dígitos que enviamos para o e-mail &nbsp;
                <b>{form.email}</b>
              </CodeInfo>

              <InputCodeContainer>
                <InputCode
                  status={status}
                  validateCode={validateCode}
                />
              </InputCodeContainer>

              <ResendingEmail>
                Se você não receber o código{' '}
                <button onClick={resendingEmail}>Clique aqui</button>
              </ResendingEmail>
            </CodeContainer>
          )}

          <LoginLink>
            Já possui conta? <Link to={'/login'}>Faça login</Link>
          </LoginLink>
        </RegisterContainer>
      </Content>
    </Container>
  );
};

export default Register;
