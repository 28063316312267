import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  background-color: var(--white);
  outline: none;
  width: 846px;
  border-radius: 16px;
  overflow-y: auto;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    width: 606px;
    border-radius: 11px;
  }
  @media screen and (max-width: 607px){
    width: 100vw;
    height: 100vh;
    border-radius: 0;
  }
`;

export const CloseIcon = styled.div` 
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  i {
    color: var(--grey-light-1);
    font-size: 20px;
  }
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    i {
      font-size: 14px;
    }
    top: 17px;
    right: 17px;
  }
  @media screen and (max-width: 607px) {
    display: none;
  }
`;

export const Header = styled.div`
  padding: 40px 56px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    padding: 28px 40px;
  }
  @media screen and (max-width: 607px) {
    padding: 24px;
  }
`;

export const Title = styled.h2`
  font-weight: 700;
  color: var(--black);
  font-size: 30px;
  @media screen and (max-width: 1279px) {
    font-size: 20px;
  }
`;

export const Return = styled.div` 
  display: none;
  column-gap: 9px;
  margin-bottom: 16px;
  cursor: pointer;
  width: fit-content;
  @media screen and (max-width: 607px) {
    display: flex;
  }
`;

export const ReturnIcon = styled.i`
  color: var(--purple);
`;