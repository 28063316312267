import styled from 'styled-components';

interface Props {
  align?: string;
  height?: string;
  width?: string;
}

export const PaginationContainer = styled.div<Props>`
  width: 100%;
  display: flex;
  justify-content: ${({ align }) => (align ? align : 'center')};
  align-items: center;
  height: ${({ height }) => (height ? height : '200px')};
  font-weight: 500;
  font-size: 14px;
`;

export const PaginationButtonContainer = styled.div`
  display: flex;

  margin-left: 15px;
  span {
    margin: 0 5px;
  }
`;
