import React, {useState} from 'react';

import {AccordionIcon, AccordionTitle, Container} from './styles';

interface Props {
  title: string;
  expandContent?: boolean;
  border?: boolean;
}

const Accordion: React.FC<Props> = ({ title, children, expandContent = false, border = true}) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <Container
      expandContent={expandContent}
      withBorder={border}
    >
      <AccordionTitle
        onClick={() => setOpen(!isOpen)}
      >
        <div>{title}</div>
        <AccordionIcon className={isOpen ? 'open' : ''}>
          <i className="icon icon-arrow-menu"/>
        </AccordionIcon>
      </AccordionTitle>
      <div className={`accordion-item ${!isOpen ? 'collapsed' : ''}`}>
        {isOpen && <div className="accordion-content">{children}</div>}
      </div>
    </Container>
  );
};

export default Accordion;
