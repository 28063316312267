import styled, {keyframes} from 'styled-components';
import {Modal} from '@material-ui/core';

export const Container = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const animeRight = keyframes`
  from {
    opacity: 1;
    transform: translateX(405px);
  }
`;

export const Body = styled.div`
  max-width: 739px;
  width: 100%;
  padding: 44px 64px;

  border-radius: 16px;

  background: ${props => props.theme.colors.white};
  outline: none;

  position: relative;

  overflow-x: hidden;

  .back-button {
    display: none;
  }

  @media (max-width: 1279px) and (min-width: 608px) {
    max-width: 567px;
    padding: 42px 54px;
  }

  @media (max-width: 607px) {
    width: 100%;
    max-width: 100%;
    height: 100%;

    padding: 20px;

    border-radius: 0;

    transform: translateX(0);
    animation: ${animeRight} 0.2s ease-out;
    overflow-y: auto;

    .back-button {
      display: flex;
      align-items: center;

      margin-bottom: 33px;

      i {
        font-size: 18px;
        color: ${props => props.theme.colors.purple};
        margin-right: 15px
      }

      span {
        font-size: 16px;
        line-height: 18.75px;
      }
    }
  }
`;

export const Title = styled.h1`
  font-size: 30px;
  line-height: 34px;
  font-weight: 700;

  margin-bottom: 35px;

  @media (max-width: 607px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const ButtonClose = styled.button`
  width: 24px;
  height: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 24px;
  right: 24px;

  cursor: pointer;

  i {
    font-size: 20px;
    color: ${props => props.theme.colors.greyLight1};
  }

  @media (max-width: 607px) {
    display: none;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;


  .container-button {
    width: 200px;
    height: 40px;
    margin-top: 64px;

    align-self: center;
  }

  @media (max-width: 607px) {
    .container-button {
      margin-top: 40px;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child){
    margin-bottom: 28px;
  }

  @media(max-width: 1279px){
    &.last{
      flex-direction: column;

      > div {
        width: 100%;
        margin-right: 0;

        &:first-child{
          margin-bottom: 28px;
        }
      }
    }
  }
`;

export const Col = styled.div`
  display: flex;
  position: relative;
  flex: 1;

  &:first-child {
    margin-right: 34px;
  }

  @media(max-width: 1279px){
    &:first-child {
      margin-right: 24px;
    }
  }

  @media(max-width: 607px){
    &:first-child {
      margin-right: 10px;
    }
  }
`;

export const AreaIcon = styled.div`
  top: 10%;
  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  right: 0;

  i {
    font-size: 27px;
    color: ${props => props.theme.colors.greyLight1};
  }

  img {
    width: 100%;
    height: 100%;
  }

  @media(max-width: 607px){
    width: 30px;
    height: 30px;

    top: 25%;

    > i {
      font-size: 18px;
    }
  }
`;

export const TooltipCard = styled.div`
  width: 188.13px;

  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 1279px) {
    width: 126.55574817445977px;
  }

  @media (max-width: 607px) {
    width: 73.79px;
  }
`;

export const ValidInput = styled.div`
  width: 100px;
  margin-right: 16px;

  @media(max-width: 1279px){
    width: 162px;
  }
  @media(max-width: 607px){
    margin-right: 10px;
  }
`;

