import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { Button } from './styles';
import { useToast } from '../../hooks/useToast';

const ShareModal: FunctionComponent = () => {
  const { toast } = useToast();
  const shareUrl = useMemo(() => process.env.REACT_APP_PUBLIC_URL + window.location.pathname, []);

  const [modalVisible, setModalVisible] = useState(false);

  const toggleModal = useCallback(() => setModalVisible(!modalVisible), [modalVisible]);

  const copyUrl = async () => {
    await navigator.clipboard.writeText(shareUrl);
    toast({ type: 'success', description: 'Link copiado com sucesso.' });
    toggleModal();
  };

  return (
    <Button className="share-button">
      <i className="icon icon-share" onClick={() => toggleModal()} />

      {modalVisible && (
        <div id="share-modal">
          <div className="modal-content">
            <p className="modal-title">Compartilhar</p>

            <a href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`} target='_blank' rel="noreferrer" className="modal-item">
              <i className="icon icon-logo-facebook" />
              <p>Facebook</p>
            </a>

            <a href={`https://api.whatsapp.com/send?text=${shareUrl}`} target='_blank' rel="noreferrer" className="modal-item">
              <i className="icon icon-logo-whatsapp" />
              <p>Whatsapp</p>
            </a>

            <button onClick={copyUrl} className="modal-item">
              <i className="icon icon-copy" />
              <p className={'underline'}>Copiar link</p>
            </button>
          </div>
        </div>
      )}
    </Button>
  );
};

export default ShareModal;
