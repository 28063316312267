import React, {FormEvent, useCallback} from 'react';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import useForm from '../../../hooks/useForm';
import {Link} from 'react-router-dom';

import {
  PanelFooter,
  PanelFooterText,
  Container,
  ButtonContainer,
  Form,
} from './styles';
import {api} from '../../../services/api';
import {useToast} from '../../../hooks/useToast';

interface Props {
  setEmail: (email: string) => void;
  setUserId: (userId: number) => void;
}

const Email: React.FC<Props> = ({setEmail, setUserId}) => {
  const {form, error, setError, onChange, validateForm, onBlur} = useForm({
    email: {
      type: 'email',
      required: true,
      limit: 100,
    },
  });

  const {toast} = useToast();

  const validateEmail = async (e: FormEvent) => {
    e.preventDefault();

    validateForm();
    if (!error.email && form.email) {
      await handleSendEmail();
    }
  };

  const handleSendEmail = useCallback(async () => {
    try {
      const {data: {data: {userId}}} = await api.post('/recover-password', form);

      setUserId(userId);
      setEmail(form.email);
    } catch (e: any) {
      if (e.type) {
        toast({
          type: 'error',
          description: e.message
        });

        return false;
      }

      setError({...error, email: e.message});
    }
  }, [toast, form, error, setEmail, setUserId, setError]);

  return (
    <Container>
      <Form onSubmit={validateEmail}>
        <Input
          title={'E-mail'}
          value={form.email}
          onChange={(e) => onChange('email', e)}
          placeholder={'digite o e-mail da sua conta'}
          error={error.email}
          onBlur={() => onBlur('email')}
          required
        />
        <ButtonContainer>
          <Button scale={'scale-4'} expand color={'var(--purple)'}>
            Enviar
          </Button>
        </ButtonContainer>
      </Form>
      <PanelFooter>
        <PanelFooterText>Não sabe seu e-mail?</PanelFooterText>
        <Link to="/esqueci-meu-email">Clique aqui</Link>
      </PanelFooter>
    </Container>
  );
};

export default Email;
