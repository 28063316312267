import styled from 'styled-components';

export const LineBreak = styled.br`
  display: none;
  &.desktop {
    display: block;
  }
  @media screen and (max-width: 1279px) {
    &.tablet {
      display: block;
    }
  }
  @media screen and (max-width: 607px) {
    &.mobile {
      display: block;
    }
  }
`;

export const Container = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  row-gap: 42px;
  flex-wrap: wrap;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    margin-top: 40px;
  }
  @media screen and (max-width: 607px) {
    margin-top: 32px;
    row-gap: 32px;
  }
`;

export const PaymentMethodsWrapper = styled.div`

`;

export const PaymentMethodsTitle = styled.p`
  font-size: 16px;
  color: var(--grey-dark-2);
  font-weight: bold;
  margin-bottom: 16px;
  @media screen and (max-width: 607px) {
    font-size: 12px;
  }
`;

export const PaymentMethods = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    gap: 24px;
  }
`;

export const Address = styled.div`
  font-size: 16px;
  color: var(--grey-dark);
  line-height: 18px;
  @media screen and (max-width: 607px) {
    line-height: 14px;
    font-size: 12px;
  }
`;

export const SafetySSL = styled.div`
  display: flex;
  gap: 8px;
`;

export const SafetyImg = styled.div`
  width: 32px;
  height: 40px;
`;

export const SafetyText = styled.p`
  font-size: 18px;
  color: var(--black);
`;

export const SSL = styled.span`
  font-weight: 700;
  color: var(--purple);
`;

export const LinkMaps = styled.a`
  text-decoration: none;
`;
