import styled from 'styled-components';
import { ReactComponent as checkIcon } from '../../assets/svg/icon-check.svg';

interface Props {
  checked?: boolean;
  color?: string;
  scale?: string;
  background?: string;
}

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  min-height: 16px;
  min-width: 16px;
`;

export const StyledCheckbox = styled.div<Props>`
  margin-right: 12px;
  border: ${({ checked }) =>
    checked ? 'none' : '1px solid #0000001a'};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ checked, color, background }) =>
    checked
      ? color
        ? color
        : 'var(--purple)'
      : background
        ? background
        : 'var(--white)'};
  border-radius: 3px;
  transition: all 150ms;

  ${({ scale }) =>
    scale === 'scale-1'
      ? `min-width: 24px;
    min-height: 24px;`
      : `min-width: 16px;
    min-height: 16px;`};

  svg {
    z-index: 0;
    ${({ scale }) =>
    scale === 'scale-1'
      ? `width: 12px;
    height: 12px;`
      : `width: 8px;
    height: 8px;`};
    opacity: ${(props) => (props.checked ? 1 : 0)};
  }
`;

export const Icon = styled(checkIcon)`
  fill: var(--white);
`;
