import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  &.disappear {
    display: none;
  }
  display: none;
  position: absolute;
  top: 30px;
  right: 0px;
  width: 224px;
  padding: 32px 24px;
  background-color: var(--grey-light-3);
  cursor: default;
  @media screen and (max-width: 1279px) {
    display: block;
  }
  @media screen and (max-width: 607px) {
    width: 131px;
  }
`;
export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: var(--grey);
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  div:first-child {
    > span {
      text-decoration: none;
      color: var(--grey);
      font-size: 16px;
      cursor: pointer;
      line-height: 24px;
    }
  }
`;
