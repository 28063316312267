import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin-top: 12px;
`;

export const ImageContainer = styled.div`
  display: flex;

  > img {
    height: 80px;
    max-height: 80px;
  }

  > span {
    position: relative;
    height: 22px;
    right: 14px;
    top: -2px;
    font-size: 20px;
    color: ${(props) => props.theme.colors.greyMedium1};
    user-select: none;
    cursor: pointer;
  }
`;

export const Info = styled.div`
  margin-left: 32px;
  display: flex;
  flex-direction: column;
  flex: 1;

  > span {
    font-size: 12px;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 4px;
    color: ${(props) => props.theme.colors.greyDark};
  }
`;

export const Details = styled.div`
  display: flex;
  justify-content: space-between;

  div {
    &:nth-child(1) {
      display: flex;
      flex-direction: column;

      > span {
        font-size: 10px;
        color: ${(props) => props.theme.colors.greyDark};
        line-height: 16px;

        span {
          color: ${(props) => props.theme.colors.greyMedium4};
        }

        &:nth-child(2), &:nth-child(3) {
          display: flex;

          div {
            span {
              margin-left: 2px;
            }
          }
        }
      }
    }

    &:nth-child(2) {
      display: flex;
      align-items: center;

      > span {
        font-size: 12px;
        color: ${(props) => props.theme.colors.greyDark};
        line-height: 16px;
        font-weight: 500;
      }
    }
  }
`;

export const Price = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;

`;

export const OriginalPrice = styled.span`
  text-decoration: line-through;
  color: var(--grey-medium-4);
  font-size: 10px;
  opacity: 0.6;
`;