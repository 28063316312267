import React from 'react';
import { CardInfo, CardHeader, CardBody, Content, Container } from './styles';

import Tooltip from '../../../components/Tooltip';

const Tooltips: React.FC = () => {
  return (
    <Container>
      <Content>
        <h2>Tooltips</h2>
        <p></p>
        <CardInfo>
          <CardHeader>
            <h1>Utilização:</h1>
          </CardHeader>
          <CardBody>
            <p>import Tooltip from "components/Tooltip"</p>
          </CardBody>
          <CardHeader>
            <h1>Tooltip com texto e imagem:</h1>
            <Tooltip>
              <div>
                <p>Teste</p>
                <div style={{ width: '100px' }}>
                  <img
                    src="https://marcas-logos.net/wp-content/uploads/2020/03/Mastercard-Logo-1.png"
                    alt=""
                  />
                </div>
              </div>
            </Tooltip>
          </CardHeader>
          <CardBody>
            <div>
              <pre>
                {`
                <Tooltip>
                  <div>
                    <p>Teste</p>
                    <div style={{ width: "100px" }}>
                      <img
                        src="https://marcas-logos.net/wp-content/uploads/2020/03/Mastercard-Logo-1.png"
                        alt=""
                      />
                    </div>
                  </div>
                </Tooltip>
                `}
              </pre>
            </div>
          </CardBody>
          <CardHeader>
            <h1>Tooltip (Somente texto):</h1>
            <Tooltip>
              <div style={{ width: '300px' }}>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
              </div>
            </Tooltip>
          </CardHeader>
          <CardBody>
            <div>
              <pre>
                {`
                <Tooltip>
                  <div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
                  </div>
                </Tooltip>
                `}
              </pre>
            </div>
          </CardBody>
          <CardHeader>
            <h1>Tooltip (Somente Imagem):</h1>
            <Tooltip>
              <div style={{ width: '100px' }}>
                <img
                  src="https://marcas-logos.net/wp-content/uploads/2020/03/Mastercard-Logo-1.png"
                  alt=""
                />
              </div>
            </Tooltip>
          </CardHeader>
          <CardBody>
            <div>
              <pre>
                {`
                <Tooltip>
                  <div style={{ width: "100px" }}>
                    <img
                      src="https://marcas-logos.net/wp-content/uploads/2020/03/Mastercard-Logo-1.png"
                      alt=""
                    />
                  </div>
                </Tooltip>
                `}
              </pre>
            </div>
          </CardBody>
        </CardInfo>
      </Content>
    </Container>
  );
};

export default Tooltips;
