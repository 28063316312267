import React, { useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useAuth} from '../../../../hooks/useAuth';
import {
  Container,
  ListWrapper,
  LinkStyled,
  IconWrapper,
  UserPhoto,
  CloseIconWrapper,
  Logout,
  CameraIcon,
  CameraIconContainer,
  MenuHeader,
  MenuInfo,
  NoPhotoIcon
} from './styles';
import UserPhotoModal from '../../../UserPhotoModal';
import { format } from 'date-fns';


interface Props {
  userLoggedMenu: boolean;
  setUserLoggedMenu: (arg: boolean) => void;
  logOut: () => void;
}

const UserLoggedMenu: React.FC<Props> = ({
  userLoggedMenu,
  setUserLoggedMenu,
  logOut,
}) => {
  const [userPhotoModalOpen, setUserPhotoModalOpen] = useState<boolean>(false);
  const {pathname} = useLocation();

  const handleClose = () => {
    setUserLoggedMenu(false);
  };

  const openUserPhotoModal = () => {
    setUserPhotoModalOpen(true);
  };

  const menuRef = useRef<HTMLDivElement>(null);

  const {user} = useAuth();

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (
        menuRef.current &&
        !menuRef.current.contains(target) &&
        userLoggedMenu
      ) {
        setUserLoggedMenu(false);
      }
    };
    document.body.addEventListener('click', handler);
    return () => {
      document.body.removeEventListener('click', handler);
    };
  });

  useEffect(() => {
    if (userLoggedMenu && window.innerWidth < 608) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [userLoggedMenu]);

  return (
    <>
      <UserPhotoModal
        userPhotoModalOpen={userPhotoModalOpen}
        setUserPhotoModalOpen={setUserPhotoModalOpen}
      />

      <Container
        ref={menuRef}
        className={`${!userLoggedMenu ? 'disappear' : ''}`}
      >
        <div>
          <CloseIconWrapper onClick={handleClose}>
            <i className="icon icon-exit" />
          </CloseIconWrapper>
          <div>
            <MenuHeader>
              {user.image ? (
                <UserPhoto background={user.image} onClick={openUserPhotoModal}>
                  <CameraIconContainer>
                    <CameraIcon className="icon icon-photo-camera" />
                  </CameraIconContainer>
                </UserPhoto>
              ) : (
                <NoPhotoIcon className="icon icon-followers" onClick={openUserPhotoModal}>
                  <CameraIconContainer>
                    <CameraIcon className="icon icon-photo-camera" />
                  </CameraIconContainer>
                </NoPhotoIcon>
              )}
              <MenuInfo>
                <h3>Olá, {user.name}</h3>
                <p>
                  No houpa desde{' '}
                  {user.registerDate &&
                    format(new Date(user.registerDate), 'yyyy')}
                </p>
              </MenuInfo>
            </MenuHeader>

            <ListWrapper>
              <li className="web" onClick={handleClose}>
                <LinkStyled
                  className={pathname === '/minha-conta' ? 'active' : ''}
                  to={'/minha-conta'}
                >
                  <IconWrapper>
                    <i className="icon icon-user" />
                  </IconWrapper>
                  <span>Minha conta</span>
                </LinkStyled>
              </li>
              <li onClick={handleClose}>
                <LinkStyled
                  className={pathname.includes('enderecos') ? 'active' : ''}
                  to={'/minha-conta/enderecos'}
                >
                  <IconWrapper>
                    <i className="icon icon-location" />
                  </IconWrapper>
                  <span>Meus Endereços</span>
                </LinkStyled>
              </li>
              <li onClick={handleClose}>
                <LinkStyled
                  className={pathname.includes('pedidos') ? 'active' : ''}
                  to={'/minha-conta/pedidos'}
                >
                  <IconWrapper>
                    <i className="icon icon-box" />
                  </IconWrapper>
                  <span>Meus Pedidos</span>
                </LinkStyled>
              </li>
              <li onClick={handleClose}>
                <LinkStyled
                  className={pathname.includes('pagamentos') ? 'active' : ''}
                  to={'/minha-conta/pagamentos'}
                >
                  <IconWrapper>
                    <i className="icon icon-credit-card" />
                  </IconWrapper>
                  <span>Formas de Pagamento</span>
                </LinkStyled>
              </li>
              <li onClick={handleClose}>
                <LinkStyled
                  className={pathname.includes('meu-cadastro') ? 'active' : ''}
                  to={'/minha-conta/meu-cadastro'}
                >
                  <IconWrapper>
                    <i className="icon icon-user-text" />
                  </IconWrapper>
                  <span>Meu Cadastro</span>
                </LinkStyled>
              </li>
              <li onClick={handleClose}>
                <LinkStyled
                  className={pathname.includes('ajuda') ? 'active' : ''}
                  to={'/ajuda'}
                >
                  <IconWrapper>
                    <i className="icon icon-chat-internal" />
                  </IconWrapper>
                  <span>Ajuda</span>
                </LinkStyled>
              </li>
              <Logout
                className={'web'}
                onClick={() => {
                  logOut();
                  handleClose();
                }}
              >
                <IconWrapper>
                  <i className="icon icon-logout" />
                </IconWrapper>
                <span>Sair</span>
              </Logout>
            </ListWrapper>
          </div>
        </div>
      </Container>
    </>
  );
};

export default UserLoggedMenu;
