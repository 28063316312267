import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.footer` 
  background-color: var(--grey-light-2);
  min-width: 292px;
`;

export const Wrapper = styled.div`  
  background-color: transparent;
  max-width: 1300px;
  padding: 52px 10px;
  margin: 0 auto;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    max-width: 628px;
  }
  @media screen and (max-width: 607px) {
    max-width: 332px;
  }
  @media screen and (max-width: 1279px) {
    padding: 32px 10px;
  }
`;

export const NavWrapper = styled.div`  
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    justify-content: initial;
    column-gap: 70px;
    row-gap: 56px;
  }
  @media screen and (max-width: 607px) {
    row-gap: 35px;
  }
`;

export const Title = styled.p` 
  color: var(--grey-dark-2);
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  margin-bottom: 12px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    font-size: 16px;
    line-height: 18px;
  }
  @media screen and (max-width: 607px) {
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 14px;
  }
`;

export const Nav = styled.ul` 
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media screen and (max-width: 607px) {
    gap: 0px;
  }
`;

export const LinkStyled = styled(Link)` 
  text-decoration: none;
  color: var(--grey-dark-2);
  font-size: 16px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    font-size: 14px;
  }
  @media screen and (max-width: 607px) {
    font-size: 10px;
  }
`;

export const DownloadApp = styled.ul` 
  display: flex;
  align-items: flex-start;
  gap: 16px;
  @media screen and (max-width: 607px) {
    gap: 10px;
  }
`;

export const AppStoreWrapper = styled.li` 
  max-width: 88px;
  @media screen and (max-width: 607px) {
    max-width: 66px;
  }
`;

export const GooglePlayWrapper = styled.li` 
  max-width: 96px;
  @media screen and (max-width: 607px) {
    max-width: 72px;
  }
`;

export const SocialMedia = styled.ul` 
  display: flex;
  gap: 20px;
  @media screen and (max-width: 607px) {
    gap: 10px;
  }
`;

export const SocialMediaIcon = styled.li` 
  @media screen and (max-width: 607px) {
    width: 16px;
    height: 16px;
  }
`;

export const LineBreak = styled.br` 
  display: none;
  &.desktop {
    display: block;
  }
  @media screen and (max-width: 1279px) {
    &.tablet {
      display: block;
    }
  }
  @media screen and (max-width: 607px) {
    &.mobile {
      display: block;
    }
  }
`;
