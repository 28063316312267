import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Main from './Main';
import Details from './Details';
const Orders: React.FC = () => {

  return (
    <Routes>
      <Route path={'/'} element={<Main />}/>
      <Route path={'/detalhes/:id'} element={<Details />}/>
    </Routes>
  );
};

export default Orders;
