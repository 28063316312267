import React from 'react';
import {
  Container,
  ImageContainer,
  Info,
  Details,
  Price,
  OriginalPrice,
} from './styles';
import { useCart } from '../../../../hooks/useCart';
import { useToast } from '../../../../hooks/useToast';
import { api } from '../../../../services/api';
import { ProductInterface, CartData } from '../../../../interfaces/productCart';

interface OwnProps {
  product: ProductInterface;
  setCartData: (cardData: CartData) => void;
  setIsOpen: (open: boolean) => void;
}

const Product: React.FC<OwnProps> = ({ product, setCartData, setIsOpen }) => {
  const { deleteItem } = useCart();
  const { toast } = useToast();

  const getProducts = async () => {
    try {
      const pullCartData = await api.get('/carts');
      const { data } = pullCartData.data;
      setCartData(data);

      if (data.products.length < 1) {
        setIsOpen(false);
        toast({
          type: 'success',
          description: 'Todos os produtos foram removidos do carrinho',
        });
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const removeProductToCart = async () => {
    const { cartProductId } = product;
    if (!cartProductId) {
      return;
    }
    await deleteItem(cartProductId, false).then(async () => {
      await getProducts();
    });
  };

  return (
    <Container>
      <ImageContainer>
        <img src={product.image} alt={product.productName} />
        <span onClick={removeProductToCart}>&#215;</span>
      </ImageContainer>
      <Info>
        <span>{product.productName}</span>
        <Details>
          <div>
            <span>
              Cor: <span>{product.colorName}</span>
            </span>
            <span>
              Tamanho:{' '}
              <div>
                <span>{product.sizeName}</span>
              </div>
            </span>
            <span>
              Quantidade:{' '}
              <div>
                <span>{product.quantity}</span>
              </div>
            </span>
          </div>
          <Price>
            <OriginalPrice>{product.originalPrice}</OriginalPrice>
            <span>{product.price}</span>
          </Price>
        </Details>
      </Info>
    </Container>
  );
};

export default Product;
