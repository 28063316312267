import React from 'react';
import {
  CardInfo,
  CardHeader,
  CardBody,
  Content,
  Container,
  CodeBlock,
} from '../ProductCardExample/styles';
import Snackbar from '../../../components/Snackbar';


const SnackbarExample: React.FC = () => {
  return (
    <Container>
      <Content>
        <h2>Cards de endereço e pagamento</h2>
        <p></p>
        <CardInfo>
          <CardHeader>
            <Snackbar>Importante: Seu pedido pode conter mais de uma loja entregando e portanto,
              pode chegar em dias diferentes. Não se preocupe, eles vão chegar no endereço de entrega
              escolhido.</Snackbar>
          </CardHeader>
          <CardBody>
            <p>import ProductCard from "components/Snackbar"</p>
            <CodeBlock>
              <h1>Snackbar</h1>
              <pre>{`
                <Snackbar>
                    Importante: Seu pedido pode conter mais de uma loja entregando e portanto,
                    pode chegar em dias diferentes. Não se preocupe, eles vão chegar no endereço de entrega escolhido.
              </Snackbar>
              `}</pre>
            </CodeBlock>
          </CardBody>
        </CardInfo>
      </Content>
    </Container>
  );
};

export default SnackbarExample;
