import React from 'react';
import {Container, IconContainer} from './styles';

interface OwnProps {
  children?: string;
  background?: string;
}

const Snackbar: React.FC<OwnProps> = ({background, children}) => {

  return (
    <Container background={background}>
      <IconContainer>!</IconContainer>

      <p>{children}</p>
    </Container>
  );
};

export default Snackbar;
