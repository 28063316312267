import {MoipCreditCard, MoipValidator} from 'moip-sdk-js';
import JSEncrypt from 'node-jsencrypt';
import Moip from '../utils/moip';
import {removeMask} from './removeMasks';

interface IDefault {
  card: string;
  cvc: string
}

interface IEncrypt extends IDefault {
  validate: string
}

export const validationCVC = ({card, cvc}: IDefault): boolean => {
  return MoipValidator.isSecurityCodeValid(
    card,
    cvc
  );
};

export const encryptCard = async ({card, validate, cvc}: IEncrypt): Promise<string | number> => {
  try {
    const [month, year] = validate.split('/');

    const creditCard = {
      number: removeMask(card, 'card'),
      cvc: cvc,
      expirationMonth: month,
      expirationYear: year,
    };

    return await MoipCreditCard.setEncrypter(JSEncrypt, 'node').setPubKey(Moip.pub_key).setCreditCard(creditCard).hash();
  } catch (err: any) {
    return 'error';
  }
};
