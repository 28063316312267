import styled from 'styled-components';

export const ButtonToTop = styled.button`
  background-color: var(--grey-light-2);
  color: var(--grey-medium-1);
  padding: 5px 15px;
  font-size: 16px;
  border-radius: 2px;
  box-shadow: 0 -1px 12px 0 rgb(0 0 0 / 20%);

  i{
    font-size: 10px;
  }
`;

export const Container = styled.div`
  width: 100%;
  float: left;
  position: fixed;
  bottom: 0;
  left: 0;
`;

export const Content = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: var(--container);
`;
