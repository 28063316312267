import React, { useCallback, useEffect, useState } from 'react';
import {
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  Icon,
} from './styles';

interface Props {
  onChange?: (checked: boolean) => void;
  color?: string;
  checked?: boolean;
  scale?: string;
  background?: string;
  className?: string;
}

const Checkbox: React.FC<Props> = ({
  checked,
  background,
  color,
  onChange,
  scale,
  children,
  className,
}) => {
  const [check, handleChecked] = useState(checked ?? false);
  const handleCheckboxChange = (event: React.FormEvent<HTMLInputElement>) => {
    handleChecked(event.currentTarget.checked);
    if (onChange) {
      onChange(event.currentTarget.checked);
    }
  };
  const checkedFunction = useCallback(() => {
    handleChecked(checked ?? false);
  }, [checked]);

  useEffect(() => {
    checkedFunction();
  }, [checkedFunction]);

  return (
    <CheckboxContainer className={className}>
      <HiddenCheckbox
        type="checkbox"
        checked={check}
        onChange={handleCheckboxChange}
      />
      <StyledCheckbox
        checked={check}
        color={color}
        scale={scale}
        background={background}
      >
        <Icon style={{ opacity: check ? 1 : 0 }} />
      </StyledCheckbox>
      {children}
    </CheckboxContainer>
  );
};

export default Checkbox;
