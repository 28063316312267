import React from 'react';
import GridBlocks from '../../../components/GridBlocks';

import { Content, Container } from './styles';

const options = [
  {
    key: 'Grid 2 Colunas',
    columns: 2,
  },
  {
    key: 'Grid 3 Colunas',
    columns: 3,
  },
  {
    key: 'Grid 4 Colunas',
    columns: 4,
  }
];

const GridBlocksExample: React.FC = () => {

  const [grid, setGrid] = React.useState('Grid 2 Colunas');

  return (
    <Container>
      <Content>
        <GridBlocks changeGrid={setGrid} value={grid} options={options} />
        <h1>Colunas: {grid}</h1>
      </Content>
    </Container>
  );
};

export default GridBlocksExample;
