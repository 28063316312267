import styled from 'styled-components';

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  > span {
    font-size: 12px;
  }
`;

export const SelectStyled = styled.select`
  width: 100%;
  padding: 10px 5px;
  border: 1px solid var(--grey-light-2);
  border-radius: 4px;
  font-size: 12px;
  color: var(--grey-medium-1);
  outline: none;
`;

export const Option = styled.option`
  font-size: 14px;
  color: var(--grey);
  padding: 14px 0;
  &:disabled {
    color: var(--grey-light-1);
  }
`;

export const RequiredTip = styled.span`
  color: var(--red-dark);
`;

export const TipContainer = styled.div`
  position: absolute; 
  max-height: 17px;
`;

export const TipMessage = styled.p`
  font-size: 12px;
  color: var(--red-dark);
  margin-top: 3px;
`;
