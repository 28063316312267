import styled from 'styled-components';

export const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 1280px) {
    gap: 50px;
  }
`;

export const Wrapper = styled.div`
  margin-top: 82px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 608px) {
    align-self: center;
    width: 200px;
  }
`;

export const SuccessContainer = styled.div`
  flex: 1;
  height: 100%;
  top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  align-self: center;

  i {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: var(--green);
  }

  @media (max-width: 608px) {
    gap: 5px;
    i {
      font-size: 18px;
    }
  }
`;

export const TextSuccess = styled.p`
  font-weight: 700;
  font-size: 24px;
  font-family: var(--poppins);
  text-align: center;
  line-height: 36px;
  color: var(--green);

  @media (max-width: 608px) {
    font-size: 18px;
    line-height: 30px;
  }
`;

export const ButtonContainer = styled.div`
  width: 214px;
  align-self: center;
  padding: 55px 0 55px 0;

  @media (max-width: 608px) {
    max-width: 152px;
    padding: 0;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 100%;

  @media (max-width: 1280px) {
    margin-top: 126px;
    max-width: 452px;
  }

  @media (max-width: 608px) {
    margin-top: 10px;
    gap: 40px;
  }
`;
