import React, {
  KeyboardEvent,
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useRef,
  useState,
  useEffect,
} from 'react';

import { Container, Content, Code, ValidContainer } from './styles';

interface OwnProps {
  status?: string;
  validateCode: (value: string) => void;
}

interface Codes {
  [key: string]: string;
}

type TypeCodes = 'code1' | 'code2' | 'code3' | 'code4';

type Props = OwnProps;

const InputCode: FunctionComponent<Props> = ({ status, validateCode }) => {
  const [codes, setCodes] = useState<Codes>({} as Codes);

  const inputCode1 = useRef<HTMLInputElement>(null);
  const inputCode2 = useRef<HTMLInputElement>(null);
  const inputCode3 = useRef<HTMLInputElement>(null);
  const inputCode4 = useRef<HTMLInputElement>(null);

  const navigateInput = useRef({
    code1: () => {
      if (!inputCode1?.current) {
        return;
      }

      if (inputCode1.current?.value.length <= 0) {
        inputCode1.current.focus();
        return;
      }

      if (isNaN(Number(inputCode1.current?.value))) {
        inputCode1.current.focus();
        return;
      }

      inputCode2.current?.focus();
    },

    code2: () => {
      if (!inputCode2?.current) {
        return;
      }

      if (inputCode2.current?.value.length <= 0) {
        inputCode1.current && inputCode1.current.focus();
        return;
      }

      if (isNaN(Number(inputCode2.current?.value))) {
        inputCode2.current.focus();
        return;
      }

      inputCode3.current?.focus();
    },
    code3: () => {
      if (!inputCode3?.current) {
        return;
      }

      if (inputCode3.current?.value.length <= 0) {
        inputCode2.current && inputCode2.current.focus();

        return;
      }

      if (isNaN(Number(inputCode3.current?.value))) {
        inputCode3.current.focus();
        return;
      }

      inputCode4.current?.focus();
    },
    code4: () => {
      if (!inputCode4?.current) {
        return;
      }

      if (inputCode4.current?.value.length <= 0) {
        inputCode3.current && inputCode3.current.focus();

        return;
      }
    },
  }).current;

  const inputFocus = useRef({
    code1: () => {
      inputCode1.current?.focus();
      return;
    },
    code2: () => {
      inputCode1.current?.focus();
      return;
    },
    code3: () => {
      inputCode2.current?.focus();
      return;
    },
    code4: () => {
      inputCode3.current?.focus();
      return;
    },
  }).current;

  const verifyCodes = useCallback(() => {
    return codes.code1 !== '' && codes.code2 !== '' && codes.code3 !== '';
  }, [codes]);

  const handleChangeInputCode = useCallback(
    (event: ChangeEvent<HTMLInputElement>, input: TypeCodes) => {
      if (!event?.currentTarget || event?.currentTarget?.value === '') {
        return;
      }

      event?.preventDefault();

      const value = event.currentTarget.value
        .trim()
        .replace(/\D/g, '')
        .slice(-1);

      const _codes = { ...codes };

      _codes[input] = value;

      setCodes({ ..._codes });

      navigateInput[input]();

      if (input === 'code4' && value && verifyCodes()) {
        inputCode4.current?.blur();
        validateCode(
          `${codes.code1 || ''}${codes.code2 || ''}${codes.code3 || ''}${
            value || ''
          }`
        );
      }
    },
    [codes, navigateInput, verifyCodes, validateCode]
  );

  const handleDeleteInputCode = useCallback(
    (event: KeyboardEvent<HTMLInputElement>, code: TypeCodes) => {
      if (event.key === 'Backspace' || event.key === 'Delete') {
        const _codes = { ...codes };
        _codes[code] = '';
        setCodes({ ..._codes });
        inputFocus[code]();
      }
    },
    [codes, inputFocus]
  );

  const clearCode = useCallback(() => {
    const _codes = {
      'code1': '',
      'code2': '',
      'code3': '',
      'code4': '',
    };
    setCodes(_codes);
    inputCode1.current?.focus();
  }, []);

  useEffect(() => {
    status === 'error' && clearCode();
  }, [status, clearCode]);

  return (
    <Container>
      <h3>Insira o código abaixo</h3>
      <Content className={status ?? ''}>
        <Code
          ref={inputCode1}
          inputMode="numeric"
          value={codes.code1}
          onKeyDown={(event) => handleDeleteInputCode(event, 'code1')}
          onChange={(event) => handleChangeInputCode(event, 'code1')}
        />
        <Code
          ref={inputCode2}
          inputMode="numeric"
          value={codes.code2}
          onKeyDown={(event) => handleDeleteInputCode(event, 'code2')}
          onChange={(event) => handleChangeInputCode(event, 'code2')}
        />
        <Code
          ref={inputCode3}
          inputMode="numeric"
          value={codes.code3}
          onKeyDown={(event) => handleDeleteInputCode(event, 'code3')}
          onChange={(event) => handleChangeInputCode(event, 'code3')}
        />
        <Code
          ref={inputCode4}
          inputMode="numeric"
          value={codes.code4}
          onKeyDown={(event) => handleDeleteInputCode(event, 'code4')}
          onChange={(event) => handleChangeInputCode(event, 'code4')}
        />
      </Content>

      {status && (
        <ValidContainer className={status ?? ''}>
          {status === 'success' && <i className={'icon icon-success-circle'} />}
          {status && (
            <p>
              {status === 'error'
                ? 'Código inválido'
                : 'Código verificado com sucesso!'}
            </p>
          )}
        </ValidContainer>
      )}
    </Container>
  );
};

export default InputCode;
