import React, { useEffect } from 'react';
import {
  Label,
  SelectStyled,
  Option,
  RequiredTip,
  TipContainer,
  TipMessage,
} from './styles';

interface Props {
  label?: string;
  disabledOption?: string;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: Array<any>;
  labelName?: string;
  valueName?: string;
  required?: boolean;
  selectError: boolean;
  setselectError: (arg: boolean) => void;
  selectedValue: string;
  setSelectedValue: (arg: string) => void;
  errorMessage?: string;
}

const Select: React.FC<Props> = ({
  label,
  disabledOption,
  options,
  required,
  selectError,
  setselectError,
  selectedValue,
  setSelectedValue,
  errorMessage,
  labelName = 'name',
  valueName = 'id',
}) => {
  const handleSelectChange = (value: string) => {
    setSelectedValue(value);
  };

  const handleBlur = () => {
    if(!disabledOption) return;
    if (!selectedValue && required) {
      setselectError(true);
    } else {
      setselectError(false);
    }
  };

  useEffect(() => {
    if (selectedValue) {
      setselectError(false);
    }
  }, [selectedValue, setselectError]);

  return (
    <>
      {label && (
        <Label>
          <span>
            {label}
            {required && <RequiredTip>*</RequiredTip>}
          </span>
        </Label>
      )}
      <SelectStyled
        className={`select ${selectError ? 'error' : ''}`}
        onBlur={handleBlur}
        value={selectedValue}
        onChange={(e) => handleSelectChange(e.target.value)}
      >
        {disabledOption !== undefined && (
          <Option value="" disabled>
            {disabledOption}
          </Option>
        )}
        {options.map((option, index) => (
          <Option key={index} value={option[valueName]}>
            {option[labelName]}
          </Option>
        ))}
      </SelectStyled>
      {selectError && (
        <TipContainer>
          <TipMessage>
            {errorMessage ? errorMessage : 'Campo obrigatório'}
          </TipMessage>
        </TipContainer>
      )}
    </>
  );
};

export default Select;
