import React, { useState } from 'react';
import {
  Container,
  PageTitle,
  Content,
  ModalWrapper,
  ModalItems,
  ModalBody,
  Button,
  ModalSuccess,
  PreFormater,
  ModalError,
  ImportText,
} from './styles';
import AlertModal from '../../../components/AlertModal';

const AlertsModal = (): JSX.Element => {
  const [modalSuccess, setModalSuccess] = useState<boolean>(false);
  const [modalError, setModalError] = useState<boolean>(false);
  const [modalOneButton, setModalOneButton] = useState<boolean>(false);

  const openSuccessModal = () => {
    setModalSuccess(true);
  };
  const openErrorModal = () => {
    setModalError(true);
  };
  const openModalOneButton = () => {
    setModalOneButton(true);
  };

  return (
    <Container>
      <Content>
        <PageTitle>Modais de alerta</PageTitle>
        <p></p>
        <ModalWrapper>
          <ModalItems>
            <div>
              <AlertModal
                type="success"
                open={modalSuccess}
                description="Mensagem do modal de sucesso "
                close={() => setModalSuccess(false)}
                submitText="Enviar"
                cancelText="Cancelar"
              />
              <Button onClick={openSuccessModal}>Abrir modal de sucesso</Button>
            </div>

            <div>
              <AlertModal
                type="error"
                open={modalError}
                description="Mensagem do modal de erro"
                close={() => setModalError(false)}
                cancelText="Cancelar"
              />
              <Button onClick={openErrorModal}>Abrir modal de erro</Button>
            </div>

            <div>
              <AlertModal
                type="error"
                open={modalOneButton}
                description="Modal com um botão"
                close={() => setModalOneButton(false)}
              />
              <Button onClick={openModalOneButton}>
                Abrir modal com um botão
              </Button>
            </div>
          </ModalItems>
          <ModalBody>
            <ImportText>
              import AlertModal from "components/AlertModal"
            </ImportText>
            <div>
              <ModalSuccess>Modal de sucesso</ModalSuccess>
              <PreFormater>
                {`
                  <AlertModal
                    open={false}
                    type="success"
                    description="Mensagem do modal de sucesso" ou JSX.Element
                    submitText="Enviar" //valor padrão: Confirmar
                    cancelText="Cancelar"
                    onSubmit={() => {}}
                    onCancel={() => {}}
                    close={() => {}}
                  />`}
              </PreFormater>
            </div>
            <div>
              <div>
                <ModalError>Modal de erro</ModalError>
                <PreFormater>
                  {`
                    <AlertModal
                      open={false}
                      type="error"
                      description="Mensagem do modal de alerta" ou JSX.Element
                      submitText="Enviar" //valor padrão: Confirmar
                      cancelText="Cancelar"
                      onSubmit={() => {}}
                      onCancel={() => {}}
                      close={() => {}}
                    />
                `}
                </PreFormater>
              </div>
            </div>

            <div>
              <div>
                <ModalError>Modal com um botão</ModalError>
                <PreFormater>
                  {`
                 <AlertModal
                  type="error"
                  open={false}
                  description="Modal com um botão"
                  close={() => {}}
                />
                `}
                </PreFormater>
              </div>
            </div>
          </ModalBody>
        </ModalWrapper>
      </Content>
    </Container>
  );
};

export default AlertsModal;
