import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 35px;
`;

export const PanelFooter = styled.div`
  display: flex;
  font-family: var(--roboto);
  font-size: 16px;
  justify-content: center;
  margin-top: 70px;

  button {
    color: var(--grey-dark);
    font-weight: 700;
    margin-left: 4px;
    text-decoration: underline;
    font-size: 16px;
    cursor: pointer;
  }

  @media (max-width: 608px) {
    &, button {
      font-size: 14px;
    }
  }
`;

export const PanelFooterText = styled.p`
  color: var(--grey-dark);
`;

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (min-width: 1280px) {
    margin-top: 60px;
  }

  @media (max-width: 1280px) {
    max-width: 452px;
    align-self: flex-start;
    margin-top: 66px;
  }

  @media (max-width: 608px) {
    margin-top: 42px;
  }
`;

export const EmailContainer = styled.div`
  font-size: 16px;
  font-family: var(--roboto);
  font-weight: 400;
  line-height: 19px;
  color: var(--grey);

  @media (max-width: 608px) {
    font-size: 14px;
  }
`;
