import React from 'react';
import {
  BoxOrder,
  BoxMessage,
  IconAlert,
  InfoOrder,
  Mobile,
  NoOrders,
  OrderNumber,
  ProductContainer,
  Products,
  TitleInfo,
  OrderStatus,
  Icon,
} from './styles';

import { useNavigate } from 'react-router-dom';
import ALERT from '../../../../../../assets/svg/alert.svg';
import ProductCard from '../../../../../../components/ProductCard';
import Pagination from '../../../../../../components/Pagination';
import { OrderRequestInterface } from '../..';
import { ProductOrders } from '../../../../../../interfaces/orderProduct';

interface Props {
  orders: OrderRequestInterface;
  products: ProductOrders[];
  page: number;
  nextPage: () => void;
  previousPage: () => void;
  tab: string;
}

const ListOrders: React.FC<Props> = ({ orders, products, page, nextPage, previousPage, tab }) => {
  const navigate = useNavigate();

  const goToDetails = (order_number: number) => {
    navigate(`/minha-conta/pedidos/detalhes/${order_number}`);
  };

  return (
    <>
      {orders.results > 0 ? (
        <>
          {tab === 'inProgress' && (
            <BoxMessage>
              <IconAlert>
                <img src={ALERT} alt="" />
              </IconAlert>
              <div>
                <p>
                  Importante: Seu pedido pode conter mais de uma loja entregando
                  e portanto, pode chegar em dias diferentes. Não se preocupe,
                  eles vão chegar no endereço de entrega escolhido.
                </p>
              </div>
            </BoxMessage>
          )}
          {orders.orders.map((order) => (
            <BoxOrder key={order.order_number}>
              <InfoOrder>
                <OrderNumber>
                  <div>nº do pedido</div>
                  <div>
                    <strong>{order.order_number}</strong>
                  </div>
                </OrderNumber>
              </InfoOrder>
              <InfoOrder>
                <TitleInfo>Resumo da Compra</TitleInfo>
                <p>Data do pedido: {order.request_order}</p>
                <p>Qtd.: {order.quantity} Itens</p>
                <p>Valor Total: {order.total}</p>
                <p>Forma de Pagamento: {order.payment_method}</p>
              </InfoOrder>
              <InfoOrder className="status">
                <TitleInfo>Status do pedido</TitleInfo>
                <OrderStatus>
                  {order.stageTitle}
                  {order.stage === 1 && <Icon className="icon icon-success" />}
                  {order.stage === 2 && <Icon className="icon icon-exit" />}
                </OrderStatus>
                {order.stage === 1 && (
                  <p>Entrega feita em: {order.order_delivered}</p>
                )}
                <Mobile>
                  <TitleInfo>Resumo da Compra</TitleInfo>
                  <p>Data do pedido: {order.request_order}</p>
                  <p>Qtd.: {order.quantity} Itens</p>
                  <p>Valor Total: {order.total}</p>
                  <p>Forma de Pagamento: {order.payment_method}</p>
                </Mobile>
              </InfoOrder>
              <InfoOrder>
                <button onClick={() => goToDetails(order.order_number)}>
                  Ver detalhes
                </button>
                <i className="icon icon-arrow-right" />
              </InfoOrder>
            </BoxOrder>
          ))}
          <Pagination
            index={page <= 9 ? `0${page.toString()}` : page.toString()}
            max={orders.pages.toString()}
            onClick={(direction: string) =>
              direction === 'next' ? nextPage() : previousPage()
            }
          />
        </>
      ) : (
        <>
          <NoOrders>
            <h2>
              Você ainda não tem pedidos realizados! Faça sua primeira compra.
            </h2>
          </NoOrders>
          <ProductContainer>
            <h1>Mais Vendidos</h1>
            <Products>
              {products.map((product) => (
                <ProductCard
                  key={product.id}
                  productId={Number(product.id)}
                  isFavorite={product.isFavorite}
                  productSlug={product.productSlug}
                  profileUrl={product.sellerProfileUrl}
                  image={product.mainImage}
                  hoverImage={product.mainImage}
                  title={product.name}
                  price={product.price}
                  pricePromotion={product.originalPrice}
                  selloffPercentage={product.selloffPercentage}
                  parcel={product.paymentCondition}
                />
              ))}
            </Products>
          </ProductContainer>
        </>
      )}
    </>
  );
};

export default ListOrders;
