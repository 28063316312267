import React from 'react';
import {
  Container,
  PaymentMethodsTitle,
  PaymentMethods,
  PaymentMethodsWrapper,
  Address,
  SafetySSL,
  SafetyImg,
  SafetyText,
  SSL,
  LinkMaps
} from './styles';
import { LineBreak } from '../styles';
import AmericanExpress from '../../../assets/svg/footer-americanexpress.svg';
import cielo from '../../../assets/svg/footer-cielo.svg';
import mastercard from '../../../assets/svg/footer-mastercard.svg';
import Visa from '../../../assets/svg/footer-visa.svg';
import HiperCard from '../../../assets/svg/footer-hipercard.svg';
import Safety from '../../../assets/svg/safetygrey.svg';

const HoupaInfo: React.FC = () => {
  return (
    <Container>
      <LinkMaps title="Google Maps" target="_blank" href="https://www.google.com/maps/place/Rua+Professor+Cesare+Lombroso,+305+-+Cj+6+-+3%C2%BA+andar+-+Bom+Retiro,+S%C3%A3o+Paulo+-+SP,+01122-021/@-23.5299643,-46.6434971,17z/data=!3m1!4b1!4m8!1m2!2m1!1sRua+Professor+Cesare+Lombroso,+305+-+Cj+6+-+3%C2%BA+andar,+Bom+Retiro+-+S%C3%A3o+Paulo%2FSP+Tel.:+9+7288-3125!3m4!1s0x94ce5867d9454d87:0xe1420cf876d546d8!8m2!3d-23.5299643!4d-46.6413084">
        <Address>
          Rua Professor Cesare Lombroso,
          <LineBreak className='mobile' /> 305 - Cj 6 - 3º andar, Bom Retiro - São
          Paulo/SP
          <LineBreak className='desktop' />
          Tel.: (11) 99129-9223
        </Address>
      </LinkMaps>
      <PaymentMethodsWrapper>
        <PaymentMethodsTitle>Formas de pagamento</PaymentMethodsTitle>
        <PaymentMethods>
          <div>
            <img src={AmericanExpress} alt='AmericanExpress card' />
          </div>
          <div>
            <img src={cielo} alt='Cielo' />
          </div>
          <div>
            <img src={mastercard} alt='Mastercard card' />
          </div>

          <div>
            <img src={Visa} alt='Visa card' />
          </div>
          <div>
            <img src={HiperCard} alt='HiperCard card' />
          </div>
        </PaymentMethods>
      </PaymentMethodsWrapper>
      <SafetySSL>
        <SafetyImg>
          <img src={Safety} alt='Escudo de segurança' />
        </SafetyImg>
        <SafetyText>
          Certificados
          <LineBreak className='desktop' />
          <SSL>SSL</SSL>
        </SafetyText>
      </SafetySSL>
    </Container>
  );
};

export default HoupaInfo;
