import styled from 'styled-components';

export const Container = styled.button`
  outline: none;
  background: transparent;
  display: flex;
  align-items: center;
  font-size: 1rem;
  outline: 0;
  cursor: pointer;

  > i {
    margin-right: 5px;
    color: ${({theme}) => theme.colors.purple};
  };
`;
