import React, {useState, useRef, useLayoutEffect, useCallback} from 'react';
import {
  Container,
  CardContainer,
  CardHeader,
  CardContent,
  HoverOpacity,
  Chip,
  CircleButton,
  Wrapper,
  TextContainer,
  Title,
  TitleBrand,
  Price,
  PricePromotion,
  Parcel,
} from './styles';
import Button from '../../components/Button';
import SideCart from '../SideCart';
import ModalProduct from '../ModalProduct';
import {useToast} from '../../hooks/useToast';
import {api} from '../../services/api';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../../hooks/useAuth';
import useMedia from '../../hooks/useMedia';

interface Props {
  productId?: number;
  productSlug?: string;
  profileUrl?: string;
  image?: string;
  hoverImage?: string;
  title?: string;
  price?: string;
  pricePromotion?: string;
  parcel?: string;
  selloffPercentage?: number;
  brandName?: string;
  isFavorite?: number;
  inProfile?: boolean;
  searchParam?: string | null;
}


function useModalProduct() {
  const [modalVisible, setModalVisible] = useState(false);

  const toggleModal = useCallback(() => setModalVisible(!modalVisible), [
    modalVisible,
  ]);

  const closeModal = () => setModalVisible(false);

  const renderModal = useCallback(
    (isVisible, closeModal, productSlug, profileUrl, callCart) => {
      if (!isVisible) {
        return null;
      }

      return (
        <ModalProduct
          modalVisible={isVisible}
          closeModal={closeModal}
          profileUrl={profileUrl}
          productSlug={productSlug}
          callCart={callCart}
        />
      );
    },
    [],
  );
  return [modalVisible, {renderModal, toggleModal, closeModal}] as const;
}

const ProductCard: React.FC<Props> = ({
  productId,
  productSlug,
  profileUrl,
  image,
  hoverImage,
  title,
  price,
  pricePromotion,
  parcel,
  selloffPercentage,
  brandName,
  isFavorite,
  inProfile,
  searchParam,
}) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState(0);
  const [cartIsOpen, setCartIsOpen] = useState<boolean>(false);
  const [productFavorite, setProductFavorite] = useState(isFavorite);
  const [
    modalVisible,
    {renderModal, toggleModal, closeModal},
  ] = useModalProduct();

  const navigate = useNavigate();
  const { toast } = useToast();
  const { user } = useAuth();

  const mobile = useMedia('(max-width: 1279px)');

  const callCart = () => {
    closeModal();
    setCartIsOpen(true);
  };

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions(targetRef.current?.offsetWidth);
    }
  }, [dimensions]);

  const addFavoriteProduct = async () => {
    try {
      if(!user){
        navigate(`/login?ReturnUrl=produto/${profileUrl}/${productSlug}`);
        return;
      }

      await api.post(`/favorites-products/${productId}`);
      setProductFavorite(1);

      toast({
        type: 'success',
        description: 'Produto favoritado com sucesso!',
      });
    } catch (err: any) {
      toast({
        type: 'error',
        description: err.message ?? "",
      });
    }
  };

  function gotToUrl() {
    let param = '';
    
    param += inProfile ? '?o=profile' : ''
    param += searchParam ? `?s=${searchParam}` : ''

    navigate(`/produto/${profileUrl}/${productSlug}${param}`)
  }

  const goToProduct = useCallback(() => {
      if (!mobile) return

      gotToUrl();
    }, [navigate, mobile, profileUrl, productSlug]);


  return (
    <>
      {cartIsOpen && <SideCart isOpen={cartIsOpen} setIsOpen={setCartIsOpen} />}

      <Container
        onClick={goToProduct}
        id={'product'}
        ref={targetRef}
      >
        <CardContainer src={image} hoverImage={hoverImage} width={dimensions}>
          <HoverOpacity width={dimensions}>
            <CardHeader>
              {selloffPercentage !== undefined && selloffPercentage !== 0 && (
                <Chip className="chip" width={dimensions}>
                  {' '}
                  {selloffPercentage}% off
                </Chip>
              )}
              <Wrapper className="wrapper">
                <CircleButton
                  className={`favorite ${productFavorite ? 'active' : ''}`}
                  onClick={
                    productFavorite ? addFavoriteProduct : addFavoriteProduct
                  }
                >
                  <i className="icon icon-favorite"/>
                </CircleButton>
              </Wrapper>
            </CardHeader>
            <CardContent className={'card_container'} width={dimensions}>
              <Button
                outline
                color={'var(--white)'}
                expand
                onClick={() => gotToUrl() }
              >
                Ver produto
              </Button>
              <Button
                onClick={() => toggleModal()}
                outline
                color={'var(--white)'}
                expand
              >
                Adicionar ao carrinho
              </Button>
            </CardContent>
          </HoverOpacity>
        </CardContainer>
        <TextContainer>
          <TitleBrand>{brandName}</TitleBrand>
          <Title>{title}</Title>
          <div>
            {pricePromotion && (
              <PricePromotion>{pricePromotion}</PricePromotion>
            )}
            <Price>{price}</Price>
          </div>
          <Parcel>{parcel}</Parcel>
        </TextContainer>

        {renderModal(
          modalVisible,
          closeModal,
          productSlug,
          profileUrl,
          callCart,
        )}
      </Container>
    </>
  );
};

export default ProductCard;
