import React from 'react';
import { Container, Block, Blocks } from './styles';

interface Block{
  key: string,
  columns: number,
}

interface Props{
  options: Block[],
  changeGrid: (key: string) => void,
  value: string
}

const GridBlocks: React.FC<Props> = ({
  options,
  changeGrid,
  value
}) => {

  function renderBlock(range: number){
    const rows = [];

    for (let i = 0; i < range; i++) {
      rows.push(<Block key={i}/>);
    }

    return rows;
  }

  return (
    <Container>
      {options.map((el, key) => (
        <Blocks onClick={() => changeGrid(el.key)} id={el.key} key={key} active={value === el.key || (!value && el.columns === 4)}>
          {
            renderBlock(el.columns)
          }
        </Blocks>
      ))}
    </Container>
  );
};

export default GridBlocks;
