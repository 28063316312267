import React from 'react';

import { CardInfo, CardHeader, CardBody, Content, Container } from './styles';
import Checkbox from '../../../components/Checkbox';

const Checkboxes: React.FC = () => {
  return (
    <Container>
      <Content>
        <h2>Houpa Checkbox</h2>
        <p></p>

        <CardInfo>
          <CardHeader>
            <Checkbox
              color="var(--purple-business)"
              onChange={(checked: boolean) => console.log(checked)}
              checked
              scale="scale-0"
            >
              Texto.
            </Checkbox>
            <Checkbox
              color="var(--purple)"
              onChange={(checked: boolean) => console.log(checked)}
              checked
              scale="scale-0"
            >
              Texto.
            </Checkbox>

            <Checkbox
              color="var(--purple-business)"
              onChange={(checked: boolean) => console.log(checked)}
              checked
              scale="scale-1"
            >
              Texto.
            </Checkbox>
            <Checkbox
              color="var(--purple)"
              onChange={(checked: boolean) => console.log(checked)}
              checked
              scale="scale-1"
            >
              Texto.
            </Checkbox>
          </CardHeader>
          <CardBody>
            <p>Import Checkbox from './src/components/forms/Checkbox'</p>
            <div>
              <p>
                {
                  '<Checkbox checked color=\'var(--purple-business)\' onChange={(checked:boolean) => {}}> Texto </Checkbox>'
                }
              </p>
              <p>
                {
                  '<Checkbox checked color=\'var(--purple)\' onChange={(checked:boolean) => {}}> Texto </Checkbox>'
                }
              </p>
              <p>
                {
                  '<Checkbox scale=\'scale-1\' checked color=\'var(--purple-business)\' onChange={(checked:boolean) => {}}> Texto </Checkbox>'
                }
              </p>
              <p>
                {
                  '<Checkbox scale=\'scale-1\' checked color=\'var(--purple)\' onChange={(checked:boolean) => {}}> Texto </Checkbox>'
                }
              </p>
            </div>
          </CardBody>
        </CardInfo>
      </Content>
    </Container>
  );
};

export default Checkboxes;
