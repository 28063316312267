import React, {useCallback, useEffect, useState} from 'react';

import {PaginationButtonContainer, PaginationContainer} from './styles';

import ButtonPagination from './components/ButtonPagination';

interface Props {
  max?: string;
  index?: string;
  onClick?: (direction: string) => void;
  align?: string;
  height?: string;
  color?: string;
}

const Pagination: React.FC<Props> = ({max, index, onClick, align, height, color}) => {
  const [indexView, setIndexView] = useState(index || '01');
  const [maxView, setMaxView] = useState(max || '01');

  const formatIndex = useCallback(
    () => {
      const indexNum = Number(index || 1);
      setIndexView(`0${indexNum.toString()}`.slice(-2));
    },
    [index]
  );

  const formatMax = useCallback(
    () => {
      const maxNum = Number(max);
      setMaxView(`0${maxNum.toString()}`.slice(-2));
    },
    [max]
  );

  useEffect(() => {
    formatIndex();
    formatMax();
  }, [index, max, formatIndex, formatMax]);

  return (
    <PaginationContainer align={align} height={height}>
      {indexView} de {maxView}
      <PaginationButtonContainer>
        <ButtonPagination
          page={indexView}
          color={color}
          onClick={(direction: string) => {
            if (onClick) {
              onClick(direction);
            }
          }}
        />
      </PaginationButtonContainer>
    </PaginationContainer>
  );
};

export default Pagination;
