import styled from 'styled-components';
import {ReactComponent as EmptyPayment} from '../../../assets/svg/empty-payments.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  @media(max-width: 607px){
    margin-bottom: 64px;
  }
`;

export const ListCards = styled.div`
  display: grid;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  grid-template-columns: repeat(3, 310px);

  margin: 32px 0;

  .card-box {
    max-width: max-content;
  }

  @media (max-width: 1279px) and (min-width: 608px) {
    grid-template-columns: repeat(2, 296px);
  }

  @media (max-width: 607px) {
    grid-template-columns: repeat(1, 312px);
  }
`;

export const EmptyPayments = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`;

export const IconCard = styled(EmptyPayment)`
  margin-bottom: 30px;
`;

export const TextEmpty = styled.h1`
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  text-align: center;
  color: var(--grey);
  margin-bottom: 31px;

  @media (max-width: 607px) {
    font-size: 18px;
    line-height: 25px;
  }
`;
