import React from 'react';

import Lottie from 'react-lottie';
import successAnimation from '../../assets/animations/check_verde.json';
import errorAnimation from '../../assets/animations/warning_red.json';
import warningAnimation from '../../assets/animations/warning_alert.json';

import {
  CustomModal,
  Container,
  Header,
  Content,
  IconContainer,
  Description,
  ButtonsContainer,
  IconButton,
} from './styles';

import Button from '../Button';
import { AlertTypes } from '../../interfaces/alert';

interface Props {
  open: boolean;
  type: AlertTypes;
  description: JSX.Element | string;
  submitText?: string;
  cancelText?: string;
  onSubmit?: () => void;
  onCancel?: () => void;
  close: () => void;
  isBottom?: boolean;
}

const AlertModal: React.FC<Props> = ({
  open,
  type,
  description,
  submitText,
  cancelText,
  onSubmit,
  onCancel,
  close,
  isBottom,
}) => {
  const switchAnimation = () => {
    switch (type) {
    case 'success':
      return successAnimation;
    case 'error':
      return errorAnimation;
    case 'warning':
      return warningAnimation;
    }
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: switchAnimation(),
  };

  const handleSubmit = () => {
    onSubmit && onSubmit();
    close();
  };

  const handleCancel = () => {
    onCancel && onCancel();
    close();
  };

  return (
    <CustomModal open={open} onClose={close} isBottom={isBottom}>
      <Container isBottom={isBottom}>
        <Header>
          <IconButton onClick={close}>
            <i className={'icon icon-exit'} />
          </IconButton>
        </Header>
        <Content>
          <IconContainer type={type}>
            <div>
              <Lottie options={defaultOptions} />
            </div>
          </IconContainer>
          <Description>{description}</Description>
          <ButtonsContainer>
            {cancelText && (
              <Button
                color={'var(--grey-light-2)'}
                style={{ color: 'var(--grey-medium-1)' }}
                outline
                onClick={handleCancel}
              >
                {cancelText}
              </Button>
            )}

            <Button color={'var(--purple)'} onClick={handleSubmit}>
              {submitText ? submitText : 'Confirmar'}
            </Button>
          </ButtonsContainer>
        </Content>
      </Container>
    </CustomModal>
  );
};

export default AlertModal;
