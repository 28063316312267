import styled from 'styled-components';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

export const FormControlStyled = styled(FormControl)``;

export const Label = styled.label`
  font-weight: bold;
  span {
    color: var(--red-dark);
    font-weight: normal;
  }
`;

export const SelectStyled = styled(Select)`
  .MuiMenu-paper {
    background-color: red;
  }
  transition: none;
  top: -5px;
  border: 0.5px solid var(--grey-light-1);
  border-radius: 8px;

  > div {
    padding: 12px 24px;
    background-color: var(--white);
    outline: none;
    border-radius: 8px;
    color: var(--grey-light-1);
    &:focus {
      border-radius: 8px;
      background-color: var(--white);
    }
  }
  &::before,
  &::after {
    content: '';
    display: none;
  }
`;
