import styled from 'styled-components';
import {Link} from 'react-router-dom';

interface Props {
  background: string
}

export const Container = styled.div`
  &.disappear {
    display: none;
  }

  position: absolute;
  top: 40px;
  right: 0;
  width: 300px;
  background-color: var(--grey-light-3);
  overflow-y: auto;
  cursor: default;
  padding: 42px 0;

  @media screen and (max-width: 607px) {
    position: fixed;
    min-width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;
  }
`;

export const MenuHeader = styled.div`
  width: 100%;
  padding: 0 32px;
  display: flex;
  align-items: center;
  gap: 23px;
`;

export const MenuInfo = styled.div`
  > h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 21.09px;
  }

  > p {
    font-size: 12px;
    color: ${(props) => props.theme.colors.greyMedium3};
    line-height: 14.06px;
    margin-top: 8px;
  }
`;

export const UserPhoto = styled.div<Props>`
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 1px solid var(--grey-light-2);
  background-image: url(${props => props.background});
  background-size: cover;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media(max-width: 607px){
    width: 86px;
    height: 86px;
  }
`;

export const CameraIconContainer = styled.div`
  position: absolute;
  bottom: -2px;
  right: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--grey-light-3);
  box-shadow: 0 2px 4px 0 rgba(96, 97, 112, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CameraIcon = styled.div`
  font-size: 10px;
  color: var(--grey-light-1);
`;

export const HiddenInputFile = styled.input`
  display: none;
`;


export const ListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  > li {
    display: none;

    &.web {
      display: initial;
    }

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    @media(max-width: 1279px){
      display: initial;
    }
  }

  @media screen and (max-width: 607px) {
    padding: 20px 0;
    gap: 28px;

    .myAccount {
      display: none;
    }
  }
`;

export const LinkStyled = styled(Link)`
  display: grid;
  padding: 8px 32px;
  font-size: 16px;
  grid-template-columns: 22px auto;
  gap: 31px;
  align-items: center;
  color: var(--grey-medium-1);
  text-decoration: none;

  &:hover {
    background-color: var(--grey-light-2);
    color: rgba(127, 28, 239, 0.7);
  }

  &.active {
    color: var(--purple);
  }

  @media screen and (max-width: 607px) {
    padding: 12px 50px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  &.sales i {
    font-size: 25px;
  }

  i {
    font-size: 21px;
    color: var(--grey-medium-4);
  }
`;

export const MyAccount = styled.div`
  display: none;
  @media screen and (max-width: 607px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 50px;
    gap: 16px;
  }
`;

export const CloseIconWrapper = styled.div`
  display: none;
  @media screen and (max-width: 607px) {
    display: block;
    position: absolute;
    right: 16px;
    top: 16px;
    padding: 4px;
    cursor: pointer;
    i {
      font-size: 16px;
      color: var(--grey);
    }
  }
`;

export const Logout = styled.li`
  display: grid !important;
  font-size: 16px;
  grid-template-columns: 22px auto;
  gap: 31px;
  align-items: center;
  color: var(--grey-medium-1);
  text-decoration: none;
  cursor: pointer;
  padding: 8px 32px;

  &:hover {
    background-color: var(--grey-light-2);
    color: rgba(127, 28, 239, 0.7);

  }

  @media screen and (max-width: 607px) {
    padding: 12px 50px;
  }
`;

export const NoPhotoIcon = styled.i`
  font-size: 21px;
  position: relative;
  padding: 20px;
  border: 1px solid var(--grey-medium-1);
  border-radius: 50%;
  color: var(--grey-medium-1);
  cursor: pointer;
`;
