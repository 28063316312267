import styled from 'styled-components';
import {Title} from '../../../../styles';

export const TitleNoOrders = styled(Title)`
  @media (max-width: 1279px) {
    text-align: center;
    font-size: 18px;
  }

  @media (max-width: 607px) {
    font-size: 14px;
  }
`;

export const BoxMessage = styled.div`

  background-color: var(--grey-light-3);
  width: 98%;
  max-width: 800px;
  margin: 40px auto;
  color: rgba(34, 34, 34, 0.5);
  padding: 30px;
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 14px;

  @media (max-width: 1279px) {

    width: 600px;
    font-size: 12px;

  }

  @media (max-width: 680px) {

    width: 459px;

  }

  @media (max-width: 607px) {

    width: 271px;
    font-size: 10px;

  }

`;

export const IconAlert = styled.div`

  background-color: var(--white);
  flex: 1;
  min-width: 40px;
  min-height: 40px;
  border-radius: 1000px;
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

`;

export const BoxOrder = styled.div`

  position: relative;
  width: 98%;
  max-width: 956px;
  margin: 0 auto 40px auto;
  padding: 30px 20px;
  background-color: var(--grey-light-3);
  color: var(--grey-dark);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 57px;

  @media (max-width: 1279px) {
    margin-top: 22px;
    width: 600px;

  }

  @media (max-width: 680px) {
    width: 459px;

  }


  @media (max-width: 607px) {
    margin-top: 53px;
    width: 279px;
    padding: 20px 0;

  }

`;

export const OrderNumber = styled.div`

  & > div:first-of-type {

    font-size: 16px;
    margin-bottom: 10px;

  }

  & > div:last-of-type {

    font-size: 20px;

  }

  @media (max-width: 1279px) {
    text-align: center;

    & > div:first-of-type {

      font-size: 10px;

    }

    & > div:last-of-type {

      font-size: 14px;

    }
  }

  @media (max-width: 607px) {
    position: static;
    display: flex;
    justify-content: space-between;

    & > div:last-of-type {

      font-size: 10px;

    }
  }

`;

export const InfoOrder = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;

  &.status {
    align-self: flex-start;
  }

  @media (max-width: 760px) {

    flex: 1;

  }

  p {
    font-size: 14px;
    margin-bottom: 4px;

    @media (max-width: 1279px) {

      font-size: 10px;

    }

  }

  button {
    background-color: transparent;
    border: none;
    color: var(--purple);
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
  }

  &:last-of-type {

    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: 0;
    font-size: 14px;

    .icon {

      color: var(--purple);
      margin-left: 10px;

    }

    @media (max-width: 1279px) {

      font-size: 10px;

    }

  }

  @media (max-width: 607px) {

    padding: 0 20px;
    margin-right: 0;

    &:first-of-type {

      min-width: 100%;
      margin-bottom: 20px;
      border-bottom: 1px solid #EDEDED;

    }

    &:nth-of-type(2) {

      display: none;

    }

  }

`;

export const TitleInfo = styled.h2`

  font-size: 16px;
  font-family: Roboto-Medium;
  font-weight: normal;
  color: var(--grey-dark);
  margin-bottom: 10px;

  @media (max-width: 1279px) {

    font-size: 12px;

  }

  @media (max-width: 607px) {

    font-size: 10px;

  }

`;

export const ResumeBox = styled.div`

  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  & div:first-of-type {

    margin-right: 20px;

  }

`;

export const Mobile = styled.div`

  display: none;
  margin-top: 20px;

  @media (max-width: 607px) {
    display: block;
  }

`;

export const NoOrders = styled.div`
  margin: 28px 0 52px;
  text-align: center;

  h2 {
    margin: 0 auto;
    font-size: 18px;
    font-weight: normal;
    line-height: 32px;
    max-width: 326px;
  }

  @media (max-width: 1279px) {
    margin: 32px 0 52px;

    > h2 {
      font-size: 14px;
      line-height: 24px;
      max-width: 281px;
    }
  }

  @media (max-width: 607px) {
    margin: 40px 0 46px;

    > h2 {
      font-size: 12px;
      max-width: 241px;
    }
  }
`;

export const ProductContainer = styled.div`
  padding-bottom: 28px;

  > h1 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 32px;
    font-weight: 400;
  }

  @media (max-width: 1279px) {
    max-width: 451px;
    margin: 0 auto;

    h1 {
      font-size: 14px;
    }
  }

  @media (max-width: 607px) {
    max-width: 217px;

    h1 {
      margin-bottom: 18px;
    }
  }
`;

export const Products = styled.div`
  display: grid;
  grid-template-columns:  1fr 1fr 1fr;
  column-gap: 16px;

  @media (max-width: 1279px) {
    grid-template-columns:  1fr 1fr;
    & > div:last-of-type {
      display: none;
    }
  }

  @media (max-width: 607px) {
    display: flex;
    flex-direction: column;

    > div {
      margin-bottom: 32px;
    }

  }
`;

export const OrderStatus = styled.p`
  display: flex;
  align-items: center;
  font-size: 14.76px;
  column-gap: 8px;
`;

export const Icon = styled.i`
  &.icon-success {
    color: var(--green);
  }
  &.icon-exit {
    color: var(--red-medium);
  }
`;
