import {createGlobalStyle} from 'styled-components';


export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    width: 100%;
  }

  *, button, input {
    border: 0;
    background: 0;
    font-family: var(--roboto)
  }

  button:focus {
    outline: none;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    margin: 0;
  }

  ul,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  img {
    display: block;
    max-width: 100%;
  }

  :root {
    --container: 1280px;

    --poppins: "Poppins-Regular";
    --roboto: 'Roboto-Regular';


    --purple: ${props => props.theme.colors.purple};
    --purple-hover: ${props => props.theme.colors.purpleHover};
    --purple-medium: ${props => props.theme.colors.purpleMedium};
    --purple-business: ${props => props.theme.colors.purpleBusiness};
    --purple-business-dark: ${props => props.theme.colors.purpleBusinessDark};

    --white: ${props => props.theme.colors.white};
    --black: ${props => props.theme.colors.black};

    --grey: ${props => props.theme.colors.grey};
    --grey-dark: ${props => props.theme.colors.greyDark};
    --grey-dark-2: ${props => props.theme.colors.greyDark2};
    --grey-medium-1: ${props => props.theme.colors.greyMedium1};
    --grey-medium-2: ${props => props.theme.colors.greyMedium2};
    --grey-medium-3: ${props => props.theme.colors.greyMedium3};
    --grey-medium-4: ${props => props.theme.colors.greyMedium4};
    --grey-light-1: ${props => props.theme.colors.greyLight1};
    --grey-light-2: ${props => props.theme.colors.greyLight2};
    --grey-light-3: ${props => props.theme.colors.greyLight3};

    --red-dark: ${props => props.theme.colors.redDark};
    --red-medium: ${props => props.theme.colors.redMedium};
    --red-light: ${props => props.theme.colors.redMedium};

    --yellow-gold: ${props => props.theme.colors.yellowGold};
    --yellow-dark: ${props => props.theme.colors.yellowDark};
    --yellow-medium: ${props => props.theme.colors.yellowMedium};
    --yellow-light: ${props => props.theme.colors.yellowLight};

    --green-dark: ${props => props.theme.colors.greenDark};
    --green: ${props => props.theme.colors.green};

    --blue: ${props => props.theme.colors.blue};
    --rose: ${props => props.theme.colors.rose};

    /* Hovers */
    --grey-dark-hover: ${props => props.theme.colors.greyDarkHover};
    --houpa-purple-hover: ${props => props.theme.colors.houpaPurpleHover};
    --houpa-business-purple-hover: ${props => props.theme.colors.houpaBusinessPurpleHover};
    --blue-hover: ${props => props.theme.colors.blueHover};
    --grey-light-hover-1: ${props => props.theme.colors.greyLightHover1};
    --yellow-dark-hover: ${props => props.theme.colors.yellowDarkHover};
    --green-hover: ${props => props.theme.colors.greenHover};
    --red-dark-hover: ${props => props.theme.colors.redDarkHover};
    --white-hover: ${props => props.theme.colors.whiteHover};

    @media (max-width: 1279px) {
      --container: 608px;
    }

    @media (max-width: 607px) {
      --container: 312px;
    }
  }

  .visible-takeBlip{
    @media (max-width: 607px) {
      overflow: hidden !important;
    }
  }
`;
