import React, { useState } from 'react';
import Email from './Email';
import Code from './Code';
import NewPassword from './NewPassword';

import { Link, useNavigate } from 'react-router-dom';
import {
  Container,
  Wrapper,
  Header,
  ButtonArrow,
  Panel,
  PanelTop,
  PanelContent,
  PanelFooter,
  PanelTitle,
  PanelFooterText,
} from './styles';

const RecoverPassword: React.FC = () => {
  const [page, setPage] = useState('email');
  const [email, setEmail] = useState('');
  const [userId, setUserId] = useState(0);

  const navigate = useNavigate();

  const codeSuccess = () => {
    setPage('password');
  };

  const changeEmail = (email: string) => {
    setEmail(email);
    setPage('code');
  };

  const changeUserId = (userId: number) => {
    setUserId(userId);
  };

  const backPage = () => {
    switch (page) {
    case 'password':
      setPage('code');
      break;
    case 'code':
      setPage('email');
      break;
    case 'email':
      navigate('/');
      break;
    }
  };

  return (
    <Container>
      <Wrapper>
        <Header onClick={() => navigate('/')}>
          <i className="icon icon-houpa" />
        </Header>
        <Panel>
          <PanelTop>
            <ButtonArrow onClick={backPage}>
              <i className="icon icon-arrow-left" />
              <span>Voltar</span>
            </ButtonArrow>
            <PanelTitle>
              {page === 'password'
                ? 'Digite uma nova senha'
                : 'Recupere sua senha'}
            </PanelTitle>
          </PanelTop>
          <PanelContent>
            {page === 'email' && <Email setEmail={changeEmail} setUserId={changeUserId} />}
            {page === 'code' && <Code callback={codeSuccess} email={email} userId={userId} />}
            {page === 'password' && <NewPassword email={email} userId={userId} />}
          </PanelContent>
          <PanelFooter>
            <PanelFooterText>Já possui conta?</PanelFooterText>
            <Link to="/login">Faça login</Link>
          </PanelFooter>
        </Panel>
      </Wrapper>
    </Container>
  );
};

export default RecoverPassword;
