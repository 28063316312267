import React, {FormEvent, useCallback, useState} from 'react';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import useForm from '../../../hooks/useForm';

import {
  Form,
  Wrapper,
  TextSuccess,
  SuccessContainer,
  ButtonContainer,
  InputContainer,
} from './styles';
import {api} from '../../../services/api';
import {useAuth} from '../../../hooks/useAuth';

interface Props {
  userId: number;
  email: string;
}

const NewPassword: React.FC<Props> = ({userId, email}) => {
  const [success, setSuccess] = useState(false);

  const {signIn} = useAuth();

  const {form, error, setError, onChange, onBlur, validateForm} = useForm({
    password: {
      type: 'password',
      required: true,
      limit: 100,
    },
    confirmPassword: {
      type: 'password',
      required: true,
      limit: 100,
    },
  });

  const validatePassword = async (e: FormEvent) => {
    e.preventDefault();

    if (form.password !== form.confirmPassword) {
      error.confirmPassword = 'As senhas precisam ser iguais';
      setError({...error});

      return false;
    }

    if (validateForm()) {
      await handleRecoverPassword();
    }
  };

  const handleRecoverPassword = useCallback(async () => {
    try {
      await api.put(`/recover-password/${userId}`, form);

      setSuccess(true);

      await signIn({email, password: form.password});
    } catch (error: any) {
      setError({...error, confirmPassword: error.message});
    }
  }, [userId, form, signIn, email, setError]);

  return (
    <Form onSubmit={validatePassword}>
      <InputContainer>
        <Input
          title={'Nova senha'}
          value={form.password}
          onChange={(e) => onChange('password', e)}
          placeholder={'********'}
          error={error.password}
          onBlur={() => onBlur('password')}
          type="password"
          required
        />

        <Input
          title={'Confirme sua nova senha'}
          value={form.confirmPassword}
          onChange={(e) => onChange('confirmPassword', e)}
          placeholder={'********'}
          error={error.confirmPassword}
          onBlur={() => onBlur('confirmPassword')}
          type="password"
          required
        />
      </InputContainer>

      {!success ? (
        <ButtonContainer>
          <Button scale={'scale-4'} expand color={'var(--purple)'}>
            Alterar senha
          </Button>
        </ButtonContainer>
      ) : (
        <Wrapper>
          <SuccessContainer>
            <i className="icon icon-success"/>
            <TextSuccess>Senha alterada com sucesso</TextSuccess>
          </SuccessContainer>
        </Wrapper>
      )}
    </Form>
  );
};

export default NewPassword;
