import {Modal} from '@material-ui/core';
import styled, {keyframes} from 'styled-components';

interface Image {
  src?: string;
  hoverImage?: string;
  width?: number;
}

export const Container = styled.div``;

const animeRight = keyframes`
  from {
    opacity: 1;
    transform: translateX(405px);
  }
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const CardContent = styled.div<Image>`
  display: none;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  max-width: 167px;
  margin: 0 auto;
  margin-bottom: ${props => props.width && props.width < 180 ? '20px' : '56px'};
  gap: 14px;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  justify-content: flex-end;
`;

export const Title = styled.h1`
  font-weight: 300;
  font-size: 16px;
`;
export const PricePromotion = styled.s`
  color: var(--grey-medium-4);
  font-size: 12px;
`;
export const Parcel = styled.span`
  font-size: 10px;
`;

export const ModalContainer = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3000 !important;
  outline: none;
`;

export const ModalContent = styled.div`
  overflow: auto;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: var(--white);

  transform: translateX(0);
  animation: ${animeRight} 0.2s ease-out;

  outline: none;
  position: relative;

  > button {
    background-color: transparent;
    border: none;
    display: none;
    position: absolute;

    right: 17px;
    top: 17px;

    cursor: pointer;

    > i {
      color: var(--grey-light-1);
      font-size: 1rem;
    }
  }

  @media (min-width: 37.9375em) {
    max-width: 562px;
    height: auto;
    border-radius: 14.6713px;

    animation: normal;
    transform: none;

    > button {
      display: initial;
    }
  }

  @media (min-width: 79.9375em) {
    max-width: 818px;
    padding-top: 40px;

    > button {
      right: 20px;
      top: 20px;

      > i { font-size: 20px}
    }
  }
`;

export const ModalDivMain = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 37.9375em) {
    flex-direction: row;
  }
`;

export const GridContainer = styled.div`
  &.web {
    display: none;
  }

  > div {
    grid-template-columns: 63px 241px;
    grid-column-gap: 8px;
    height: 348px;

    .all-images .small-image {
      width: 63px;
      height: 78px;
    }


    @media (min-width: 37.9375em) {
      grid-template-columns: 52px 191px;
      height: 274px;

      .all-images .small-image {
        width: 52px;
        height: 65px;
      }
    }

    @media (min-width: 79.9375em) {
      grid-template-columns: 88px 322.14px;
      height: 466px;

      .all-images .small-image {
        width: 68px;
        height: 110px;
      }
    }
  }

  @media (min-width: 37.9375em) {
    margin-right: 16px;
  }

  @media (min-width: 79.9375em) {
    margin-right: 27px;
  }

`;

export const HeaderModal = styled.div`
  display: flex;

  @media (min-width: 37.9375em) {
    display: none;
  }
`;

export const BodyModal = styled.div`
  padding: 20px 0;

  .ds-flex {
    display: flex;
    flex-direction: column;

    @media (min-width: 37.9375em) {
      flex-direction: row;
    }
  }
`;

export const TitleModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 20px;

  h1 {
    font-size: 16px;
    color: #222222;
    font-weight: normal;
    @media screen and (min-width: 607px) and (max-width: 1279px) {
      font-size: 10px;
    }
  }

  i {
    color: #000;
  }


  @media (min-width: 37.9375em) {
    margin-bottom: 10px;
  }
`;

export const Back = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;

  i {
    color: var(--purple);
    font-size: 16px;
    margin-right: 10px;
  }
`;

export const BrandName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--grey-dark);
  margin-bottom: 20px;

  span {
    font-size: 12px;
  }

  @media (min-width: 37.9375em) {
    margin-bottom: 5px;
  }
`;

export const Launch = styled.div`
  font-size: 10px;
  padding: 5px 10px;
  color: var(--grey-medium-1);
  background-color: var(--grey-light-2);
  margin-right: 10px;
`;

export const SoldBy = styled.div`
  font-size: 12px;

  p {
    display: inline-block;
    margin-right: 30px;
  }

  span {
    color: var(--grey-light-1);
  }

  strong {
    text-decoration: underline;
    color: ${props => props.theme.colors.black};
    cursor: pointer;

    > a {
      text-decoration: none;
      color: ${props => props.theme.colors.black};
    }
  }


  @media (min-width: 37.9375em) {
    margin-bottom: 11px;

    > p {
      margin-right: 19px;
    }
  }
`;

export const PriceModal = styled.div`
  margin-bottom: 20px;

  .price {
    font-size: 24px;
  }

  &.web {
    display: none;
  }

  @media (min-width: 37.9375em) {
    display: none;

    &.web {
      display: block;
    }
  }
`;

export const Details = styled.div`
  margin: 16px 0;
  cursor: pointer;

  .details {
    color: var(--grey);
    text-decoration-line: underline;
    text-align: center;
    font-size: 10px;
  }

  &.web {
    display: none;
  }

  @media (min-width: 37.9375em) {
    display: none;
    height: 24px;
    margin: 8px;

    &.web {
      display: initial;
    }
  }
`;

export const Description = styled.div`
  margin-bottom: 20px;

  .description {
    font-size: 10px;
    color: var(--grey-dark);
  }
`;


interface SizeInterface {
  available: boolean;
  active?: boolean;
}

export const Size = styled.div<SizeInterface>`
  width: 40px;
  height: 40px;

  margin-right: 11px;

  border-radius: 50%;
  border: 1.5px solid ${props => props.active ? props.theme.colors.greyLight1 : props.theme.colors.white};

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${props => props.theme.colors.white};

  cursor: pointer;

  .size-content {
    width: 32px;
    height: 32px;

    border-radius: 50%;
    border: 1px solid ${props => props.theme.colors.greyLight1};

    display: flex;
    align-items: center;
    justify-content: center;

    background: ${props => props.theme.colors.greyLight3};

    position: relative;

    > span {
      font-size: 12px;
      color: ${props => props.theme.colors.greyDark};
      text-transform: uppercase;
    }

    .disabled {
      display: ${props => !props.available ? 'flex' : 'none'};
      width: 100%;
      height: 100%;

      border-radius: 50%;

      background: rgba(220, 220, 220, .8);

      z-index: 1;

      position: absolute;

      align-items: center;
      justify-content: center;

      .line {
        width: 100%;
        border: 1px solid ${props => props.theme.colors.greyLight1};
        transform: rotate(-60deg);
      }
    }
  }
`;


interface ColorInterface {
  hex?: string;
  active?: boolean;
}

export const Color = styled.button<ColorInterface>`
  width: 32px;
  height: 32px;
  margin-right: 12px;

  border-radius: 50%;

  cursor: pointer;

  border: 1.5px solid ${props => props.active ? props.theme.colors.greyLight1 : props.theme.colors.white};

  display: flex;
  align-items: center;
  justify-content: center;

  .color-content {
    width: 24px;
    height: 24px;

    border: 1px solid ${props => props.theme.colors.greyLight1};
    border-radius: 50%;

    background: ${(props) => props.hex !== undefined ? props.hex : props.theme.colors.white};

    > img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }
`;

export const VariantsCol = styled.div`
  margin-bottom: 17px;

  > p {
    margin-bottom: 8px;
    font-size: 12px;
  }
`;

export const VariantsRows = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ButtonModalContainer = styled.div`
  display: block;
  width: 198px;
  margin: 0 auto;

  .cart {
    display: none
  }

  > button:first-child {
    margin-bottom: 12px;
  }

  @media (min-width: 37.9375em) {
    width: 219px;
    .cart {
      display: initial
    }
  }

  @media (min-width: 79.9375em) {
    width: 100%;
    .cart {
      display: initial
    }
  }
`;

export const HeaderProduct = styled.div`
  &.web {
    display: none;
  }

  @media (min-width: 79.9375em) {
    display: none;

    &.web {
      display: initial;
    }
  }
`;

export const SelectASize = styled.span`
  font-family: var(--roboto);
  font-size: 12px;
  color: var(--red-dark);
`;