import styled from 'styled-components';

interface BlockProps{
  active: boolean
}

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Blocks = styled.div<BlockProps>`
  display: flex;
  cursor: pointer;
  margin-right: 10px;

  &:last-of-type {
    margin-right: 0;
  }

  & > div:last-of-type {
    margin-right: 0;
  }

  & > div {
    transition: 0.3s;
    background-color: ${props => props.active === true ? 'var(--grey-medium-4)' : 'var(--white)'};
  }
`;

export const Block = styled.div`
  margin-right: 3px;
  border: 2px solid var(--grey-medium-4);
  width: 9.98px;
  height: 13.63px;
`;
