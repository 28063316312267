import React from 'react';
import { LabelStyle, Form } from './styles';
import Radio from '@material-ui/core/Radio';

interface Props {
  label?: string;
  className?: string;
  onCheck?: () => void;
  checked?: boolean;
}

const RadioButtonsGroup: React.FC<Props> = ({
  label,
  checked = false,
  onCheck,
}) => {
  return (
    <Form>
      <LabelStyle onClick={onCheck}>
        <Radio checked={checked} />
        <span>{label}</span>
      </LabelStyle>
    </Form>
  );
};

export default RadioButtonsGroup;
