import React, { useEffect } from 'react';
import {
  Label,
  SelectStyled,
  Option,
  RequiredTip,
  TipContainer,
  TipMessage,
} from './styles';

interface Props {
  label: string;
  disabledOption: string;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: Array<any>;
  required?: boolean;
  selectError: boolean;
  setselectError: (arg: boolean) => void;
  selectedValue: string;
  setSelectedValue: (arg: string) => void;
  labelName?: string;
  valueName?: string;
  className?: string;
}

const Select: React.FC<Props> = ({
  label,
  disabledOption,
  options,
  required,
  selectError,
  setselectError,
  selectedValue,
  setSelectedValue,
  labelName = 'name',
  valueName = 'id',
  className,
}) => {
  const handleSelectChange = (value: string) => {
    setSelectedValue(value);
  };

  const handleBlur = () => {
    if (!selectedValue) {
      required && setselectError(true);
    } else {
      setselectError(false);
    }
  };

  useEffect(() => {
    if (selectedValue) {
      setselectError(false);
    }
  }, [selectedValue, setselectError]);

  return (
    <Label className={className}>
      <span>
        {label}
        {required && <RequiredTip>*</RequiredTip>}
      </span>
      <SelectStyled
        onBlur={handleBlur}
        value={selectedValue}
        onChange={(e) => handleSelectChange(e.target.value)}
      >
        <Option value="" disabled>
          {disabledOption}
        </Option>
        {options.map((option, index) => (
          <Option key={index} value={option[valueName]}>
            {option[labelName]}
          </Option>
        ))}
      </SelectStyled>
      <TipContainer>
        {selectError && <TipMessage>Campo obrigatório</TipMessage>}
      </TipContainer>
    </Label>
  );
};

export default Select;
