import React, {useEffect} from 'react';
import { SelectStyled, Label } from './styles';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '../Checkbox';
import {MenuProps} from '@material-ui/core';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
      width: 260,
    },
  }),
);

interface Props {
  options: Array<string>;
  label: string;
  onChange?: (selectedOptions: string[]) => void;
  required?: boolean;
}

const PropsMenu: Partial<MenuProps> = {
  PaperProps: {
    style: {
      transform: 'translate3d(0, 15px, 0)',
      maxHeight: 225,
      width: 260,
      borderRadius: '8px',
      boxShadow: '0px 3px 4px 1px rgba(0, 0, 0, 0.1)',
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

const SelectCheckbox: React.FC<Props> = ({ options, label, onChange, required }) => {
  const classes = useStyles();
  const [listOptions, setListOptions] = React.useState<string[]>([]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setListOptions(event.target.value as string[]);
  };

  useEffect(() => {
    if(onChange) {
      onChange(listOptions);
    }
  }, [listOptions, onChange]);

  return (
    <FormControl className={classes.formControl}>
      <Label>{label}{required ? <span>*</span> : ''}</Label>
      <SelectStyled
        id="demo-mutiple-checkbox"
        multiple
        value={listOptions}
        onChange={handleChange}
        input={<Input />}
        renderValue={(selected) => (selected as string[]).join(', ')}
        MenuProps={PropsMenu}
      >
        {options.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={listOptions.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </SelectStyled>
    </FormControl>
  );
};

export default SelectCheckbox;
