import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Title} from '../styles';
import {
  AddressAddButton,
  ButtonContainer,
  Container,
  Content,
  EmptyAddress,
  IconAdd,
  IconLocation,
  TextEmpty,
  TitleContainer,
} from './styles';
import ModalAddress from '../../../components/ModalAddress';
import Card from '../../../components/Card';
import {AddressInterface} from '../../../interfaces/address';
import {useAlert} from '../../../hooks/useAlert';
import {api} from '../../../services/api';
import {useToast} from '../../../hooks/useToast';
import Button from '../../../components/Button';
import Lottie from 'react-lottie';
import LoadingAnimation from '../../../assets/animations/loading.json';

const Address: React.FC = () => {
  const [address, setAddress] = useState<AddressInterface[]>([]);
  const [edit, setEdit] = useState<AddressInterface>({} as AddressInterface);
  const [modal, setModal] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [actualPage, setActualPage] = useState(1);
  const [limit] = useState(6);

  const [loadButton, setLoadButton] = useState(false);

  const {showAlert} = useAlert();
  const {toast} = useToast();

  const defaultOptions = useRef({
    loop: true,
    autoplay: true,
    animationData:
    LoadingAnimation,
  }).current;

  const getAddresses = useCallback(
    async () => {
      try {
        setLoadButton(true);
        const {data} = await api.get(
          `/addresses?page=${actualPage}&limit=${limit}`,
        );

        setTotalPages(data.totalPages);

        if (actualPage === 1) {
          setAddress(data.adresses);
          return;
        }

        setAddress((items) => [...items, ...data.adresses]);
      } catch (err: any) {
        toast({
          type: 'error',
          description: 'Não foi possível carregar seus endereços, tente novamente!'
        });
      } finally {
        setLoadButton(false);
      }
    }, [actualPage, limit, toast]);

  const nextPage = () => {
    setActualPage(actualPage + 1);
  };

  useEffect(() => {
    getAddresses().then();
    // eslint-disable-next-line
  }, [actualPage]);

  const updateMainAddress = useCallback(
    async (id: number) => {
      try {
        await api.patch(`/addresses/${id}`);
        const newAddress = [...address];
        newAddress.forEach((item) => {
          item.main = item.id === id ? 1 : 0;
        });

        setAddress(newAddress);
      } catch (err: any) {
        console.log(err);
      }
    },
    [address],
  );

  const deleteAddress = useCallback(async (index) => {
      const allAddresses = [...address];
      try {
        setAddress([]);
        setLoadButton(true);
        await api.delete(`/addresses/${index}`);

        if (actualPage !== 1) {
          setActualPage(1);
        } else {
          await getAddresses();
        }

        toast({
          type: 'success',
          description: 'Endereço excluido com sucesso!',
        });
      } catch (err: any) {
        setAddress(allAddresses);
        setLoadButton(false);
        toast({
          type: 'error',
          description: 'Erro ao tentar excluir, tente novamente!',
        });
      }
    },
    [actualPage, address, getAddresses, toast],
  );

  const updateAddress = (addressObj: AddressInterface) => {
    setEdit(addressObj);
    setModal(true);
  };

  const handleNewAddress = useCallback(
    async (form: Omit<AddressInterface, 'id'>) => {
      const allAddresses = [...address];
      try {
        setAddress([]);
        setLoadButton(true);

        await api.post('/addresses', form);

        if (actualPage !== 1) {
          setActualPage(1);
        } else {
          await getAddresses();
        }

        toast({
          type: 'success',
          description: 'Endereço cadastrado com sucesso!',
        });
      } catch (e: any) {
        setAddress(allAddresses);
        setLoadButton(false);

        toast({
          type: 'error',
          description: e.type === 'warning' ? e.message : 'Erro ao tentar cadastrar',
        });
      }

    },
    [actualPage, address, getAddresses, toast],
  );

  const handleUpdateAddress = useCallback(
    (newAddress: AddressInterface) => {
      let newAddresses = [...address];

      if (newAddress.main) {
        newAddresses = newAddresses.filter((address) => {
          address.main = 0;
          return address.id !== newAddress.id;
        });
        newAddresses.unshift(newAddress);
      } else {
        const index = newAddresses.findIndex(
          (address) => address.id === newAddress.id,
        );
        newAddresses.splice(index, 1, newAddress);
      }

      setAddress(newAddresses);
    },
    [address],
  );

  useEffect(() => {
    if (!modal) setEdit({} as AddressInterface);
  }, [modal]);

  return (
    <Container>
      <TitleContainer>
        <Title>Meus Endereços</Title>

        {address.length > 0 && (
          <AddressAddButton onClick={() => setModal(true)}>
            <IconAdd>+</IconAdd>
            Adicionar novo endereço
          </AddressAddButton>
        )}
      </TitleContainer>

      <Content>
        {address.length > 0 &&
        address.map((item, key) => (
          <Card
            key={key}
            headerTitle={item.title}
            type="address"
            onDelete={() => {
              showAlert({
                type: 'error',
                description: 'Tem certeza que deseja excluir este endereço?',
                cancelText: 'Cancelar',
                onSubmit: () => deleteAddress(item.id),
              });
            }}
            onEdit={() => updateAddress(item)}
            params={{
              receiver: item.receiver,
              street: item.street,
              number: item.number,
              neighborhood: item.neighborhood,
              adjunct: item.adjunct,
              city: item.city,
              uf: item.uf,
              zipCode: item.zipCode,
            }}
            onCheck={() => {
              updateMainAddress(item.id ?? 0).then();
            }}
            checked={item.main === 1 || item.main === '1'}
          />
        ))}
      </Content>

      {address.length === 0 && !loadButton && (
        <EmptyAddress>
          <IconLocation/>
          <TextEmpty>Você ainda não possui endereços cadastrados</TextEmpty>

          <AddressAddButton onClick={() => setModal(true)}>
            <IconAdd>+</IconAdd>
            Adicionar novo endereço
          </AddressAddButton>
        </EmptyAddress>
      )}

      <ButtonContainer>
        {actualPage < totalPages && totalPages > 1 && !loadButton && (
          <Button
            onClick={nextPage}
            color={'var(--purple)'}
            outline
            expand
            scale={'scale-5'}
          >
            Ver mais
          </Button>
        )}
        {loadButton && (
          <Lottie width={64} height={64} options={defaultOptions}/>
        )}
      </ButtonContainer>

      {modal && (
        <ModalAddress
          modal={modal}
          edit={edit}
          onAddAddress={handleNewAddress}
          onDeleteAddress={deleteAddress}
          onUpdateAddress={handleUpdateAddress}
          setModal={setModal}
        />
      )}
    </Container>
  );
};

export default Address;
