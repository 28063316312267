import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%
`;

export const Input = styled.input` 
  padding: 13px 13px 13px 39px;
  border: 1px solid var(--grey-light-2);
  border-radius: 4px;
  outline: none;
  width: 100%;
  @media screen and (max-width: 1279px) {
    padding: 10px 10px 10px 30px;
  }
`;

export const SearchIcon = styled.i` 
  position: absolute;
  left: 18px;
  top: 14px;
  color: var(--grey-medium-1);
  font-size: 16px;
  @media screen and (max-width: 1279px) {
    top: 10px;
    left: 10px;
  }
`;