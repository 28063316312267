import React, { useState, createContext, useContext } from 'react';
import secure from '../config/secureLS';
import { api } from '../services/api';
import { Form } from './useForm';
import firebase from '../utils/firebaseUtils';
import { useAnalytics } from './useAnalytics';

export interface UserInterface {
  name: string;
  image: string;
  registerDate: string;
}

interface UseAuthInterface {
  token: string;
  setToken: (userToken: string) => void;
  user: UserInterface;
  setUser: (user: UserInterface) => void;
  logOut: () => void;
  getUser: () => UserInterface;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  signIn: (form: Form, navigateTo?: string) => Promise<any>;
}

export const AuthContext = createContext<UseAuthInterface>({} as UseAuthInterface);

interface OwnProps {
  children?: React.ReactNode;
}

export const AuthProvider: React.FC<OwnProps> = ({ children }): JSX.Element => {
  const { sendEventAnalytics } = useAnalytics();

  const getToken = () => {
    const token = secure.get('houpa@access-token');

    return token?.data;
  };

  const getUser = () => {
    const user = secure.get('houpa@user');

    return user?.data;
  };

  const [token, setToken] = useState(getToken());
  const [user, setUser] = useState<UserInterface>(getUser());

  const saveToken = (userToken: string) => {
    secure.set('houpa@access-token', { data: userToken });
    setToken(userToken);
  };

  const saveUser = (user: UserInterface) => {
    secure.set('houpa@user', { data: user });
    setUser(user);
  };

  const logOut = async () => {
    secure.clear();

    await firebase.logout();
  };

  const signIn = async (form: Form, navigateTo?: string) => {
    try {
      const response = await api.post('/auth/login', form);

      if (!response) {
        return;
      }

      if (process.env['REACT_APP_FIREBASE'] === 'true') {
        await firebase.login(form.email, form.password);
      }

      const {
        data: { access_token, user },
      } = response;

      saveToken(access_token);
      saveUser(user);

      const session_id = secure.get('@houpa:session_id');

      if (session_id?.data) {
        secure.remove('@houpa:session_id');
      }

      // Enviando evento de Login para o Analytics
      sendEventAnalytics({
        category: 'usuário',
        action: 'Login Usuário',
        label: 'Login',
      });

      window.location.href =
        (navigateTo ? navigateTo : process.env.REACT_APP_PUBLIC_URL) || '';

      return true;
    } catch (e: any) {
      if (!e.errors && e.type !== 'invalidCode') {
        return {
          type: 'internal',
          message: 'Erro ao tentar entrar. tente novamente!',
        };
      }

      return e;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken: saveToken,
        user,
        setUser: saveUser,
        getUser,
        logOut,
        signIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): UseAuthInterface => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth deve ser usado com o AuthProvider');
  }

  return context;
};
