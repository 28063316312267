import styled from 'styled-components';
import {ReactComponent as IconEmptyCart} from '../../assets/svg/icon-empty-cart.svg';

export const Container = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  box-shadow: 0 2px 4px 0 rgba(96, 103, 112, 0.15);
`;

export const NavWrapper = styled.div`
  background-color: var(--grey-light-2);
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    position: relative;
    max-width: 628px;
    margin: 0 auto;
  }
  @media screen and (max-width: 607px) {
    position: initial;
  }
`;

export const Nav = styled.nav`
  max-width: 1300px;
  margin: 0 auto;
  padding: 8px 10px;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    display: none;
    background-color: var(--grey-light-3);
    justify-content: initial;
    align-items: initial;
    width: 224px;
    flex-direction: column;
    position: absolute;
    top: 48px;
    left: 10px;
    padding: 34px 24px;
    z-index: 10;
    &.menuActive {
      display: flex;
    }
  }
  @media screen and (max-width: 607px) {
    display: none;
    background-color: var(--grey-light-3);
    justify-content: initial;
    align-items: initial;
    width: 224px;
    flex-direction: column;
    position: absolute;
    top: 48px;
    left: 0;
    padding: 34px 24px;
    z-index: 10;
    &.menuActive {
      display: flex;
    }
  }
`;

export const UserType = styled.ul`
  display: flex;
  align-items: center;
  gap: 40px;
  @media screen and (max-width: 1279px) {
    align-items: initial;
    flex-direction: column;
    gap: 30px;
  }
`;

export const UserTypeOption = styled.li`
  font-size: 12px;
  cursor: pointer;
  @media screen and (max-width: 1279px) {
    font-size: 16px;
    color: var(--grey-medium-1);
  }
`;

export const Pages = styled(UserType)`
  gap: 20px;
  @media screen and (max-width: 1279px) {
    margin-top: 30px;
    gap: 30px;
  }
`;

export const PageOption = styled(UserType)`
  font-size: 10px;
  cursor: pointer;
  @media screen and (max-width: 1279px) {
    font-size: 16px;
    color: var(--grey-medium-1);
  }
`;

export const ContentWrapper = styled.div`
  background-color: var(--white);
`;

export const Content = styled.div`
  max-width: 1300px;
  min-width: 292px;
  margin: 0 auto;
  background-color: transparent;
  padding: 12px 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    max-width: 628px;
    grid-template-columns: auto 1fr;
    grid-template-rows: 2fr;
    row-gap: 13px;
  }
  @media screen and (max-width: 607px) {
    max-width: 332px;
    grid-template-columns: auto 1fr;
    grid-template-rows: 2fr;
    row-gap: 13px;
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const MenuHamburguer = styled.div`
  display: none;
  @media screen and (max-width: 1279px) {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    width: 26px;
    transition: all 0.2s;
    cursor: pointer;
  }
`;

export const HamburguerLines = styled.div`
  width: 24px;
  height: 3px;
  background-color: var(--purple);
  border-radius: 6px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 24px;
    height: 3px;
    background-color: var(--purple);
    border-radius: 6px;
    transform: translateY(-9px);
    transition: all 0.2s;
  }

  &::after {
    transform: translateY(9px);
  }

  &.menuActive {
    background-color: transparent;
  }

  &.menuActive::before,
  &.menuActive::after {
    transform: rotate(45deg);
  }

  &.menuActive::after {
    transform: rotate(-45deg);
  }
`;

export const LogoIcon = styled.i`
  color: var(--purple);
  font-size: 36px;
  cursor: pointer;
  @media screen and (max-width: 1279px) {
    font-size: 24px;
  }
`;

export const Form = styled.form`
  width: 532px;
  @media screen and (max-width: 1279px) {
    width: 100%;
    grid-column: 1/4;
    grid-row: 2;
  }
`;

export const Actions = styled.div`
  justify-self: end;
  display: flex;
  align-items: center;
  gap: 32px;
  padding-right: 7px;
`;

export const Dropdown = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  cursor: pointer;
  @media screen and (max-width: 1279px) {
    gap: 4px;
  }
`;

export const UserPhotoWrapper = styled(Dropdown)`
  display: flex;
  align-items: center;
`;

export const UserPhoto = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

export const WelcomeMessage = styled.span`
  font-size: 12px;
  color: var(--grey);
  padding-right: 8px;
  @media screen and (max-width: 1279px) {
    display: none;
  }
`;

export const ArrowIcon = styled.i`
  font-size: 8px;
  color: var(--purple);
`;

export const FavoriteIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const FavoriteIcon = styled.i`
  font-size: 18px;
  color: var(--grey-medium-2);
`;

export const CartIconWrapper = styled(FavoriteIconWrapper)`
  position: relative;
  cursor: pointer;

  .tooltip {
    display: none;
  }

  &:hover {
    .tooltip {
      display: flex;
    }
  }
`;

export const CartTooltip = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: var(--grey-light-3);
  width: 274px;
  min-height: 200px;
  height: auto;
  top: 200%;
  right: -16px;

  &::after {
    content: " ";
    position: absolute;
    bottom: 100%; /* At the top of the tooltip */
    right: 10px;
    margin-left: -5px;
    border-width: 15px;
    border-style: solid;
    border-color: transparent transparent var(--grey-light-3) transparent;
  }

  > p {
    font-family: Roboto-Medium, Poppins-Medium, Arial, sans-serif;
  }

  @media (max-width: 607px) {
    width: 312px;
    height: 149px;
    margin-right: 9px;

    &::after {
      right: 3px;
    }
  }
`;

export const IconCart = styled(IconEmptyCart)`
  width: 39px;
  height: 32px;
  margin-bottom: 12px;
`;

export const CartIcon = styled(FavoriteIcon)``;

export const CartQuantity = styled.span`
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: var(--purple);
  color: var(--white);
  font-size: 10px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Gap = styled.div`
  margin-bottom: 98px;
  @media screen and (max-width: 1279px) {
    margin-bottom: 99px;
  }
`;

export const NotLoggedWrapper = styled.div`
  display: none;
  @media screen and (max-width: 1279px) {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const NotLoggedIcon = styled.i`
  color: var(--grey-medium-1);
  font-size: 18px;
`;

export const LoginDesktop = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  a {
    font-family: Roboto-Medium, Poppins-Medium, Arial, sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: var(--purple);
    text-decoration: underline;
  }

  @media screen and (max-width: 1279px) {
    display: none;
  }
`;

export const ResetLinkStyle = styled.button`
  text-decoration: none;
`;


export const PhotoContainer = styled.div`
  position: relative;

  &:hover .change-photo {
    display: flex;
    z-index: 2;
  }
`;

export const ChangePhoto = styled.div`
  display: none;
  background-color: rgba(34, 34, 34, 0.5);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const ChangePhotoIcon = styled.i`
  color: var(--grey-light-3);
  font-size: 12px;
`;

export const NoPhotoIconContainer = styled.div`
  width: 32px;
  height: 32px;
  border: 1px solid var(--grey-medium-1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NoPhotoIcon = styled.i`
  font-size: 18px;
  color: var(--grey-medium-1);
`;

export const LinkCart = styled.div`
  margin-top: 10px;
`;
