import styled from 'styled-components';

import {ReactComponent as Location} from '../../../assets/svg/carbon-location.svg';

export const Container = styled.div`
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 28px;

  > h1 {
    padding: 0;
  }

  @media(max-width: 607px){
    flex-direction: column;
    align-items: start;

    > h1 {
      margin-bottom: 10px;
    }
  }
`;

export const AddressAddButton = styled.button`
  font-family: Roboto-Medium, Poppins-Medium, Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;

  color: var(--grey-dark);
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;

  @media (max-width: 607px) {
    font-size: 14px;
  }
`;

export const IconAdd = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;
  margin-right: 21px;

  border-radius: 50%;
  background-color: var(--purple);

  color: var(--white);
  font-size: 22px;


  @media (max-width: 607px) {
    margin-right: 15px;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  flex-wrap: wrap;
  margin-top: 33px;

  @media (max-width: 1279px) {
    grid-template-columns: 1fr 1fr;
    margin-top: 53px;
  }

  @media (max-width: 607px) {
    grid-template-columns: 1fr;
    margin-top: 24px;
  }
`;

export const EmptyAddress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`;

export const TextEmpty = styled.h1`
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  text-align: center;
  color: var(--grey);
  margin-bottom: 31px;

  @media (max-width: 607px) {
    font-size: 18px;
    line-height: 25px;
  }
`;

export const IconLocation = styled(Location)`
  margin-bottom: 30px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 120px;

  > button {
    font-weight: 500;
    font-size: 12px;
    padding: 13px 0;
    font-family: Roboto-Medium, Poppins-Medium, Arial, sans-serif;
    max-width: 618px;
    height: 52px;
    border-width: 1.625px;
  }

  @media(max-width: 1279px){
    margin-top: 35px;
    margin-bottom: 80px;
  }

  @media(max-width: 607px){
    margin-top: 43px;
    margin-bottom: 64px;
  }
`;
