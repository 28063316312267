import React, { createContext, useContext } from 'react';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { ProductCart } from '../interfaces/orderProduct';
import { CartResume } from '../pages/Checkout/interfaces';
interface EventProps{
  category: string,
  action: string,
  label?: string,
  value?: number,
  nonInteraction?: boolean
}
interface AnalyticsContextData{
  startAnalytics: () => void,
  sendEventAnalytics: (props: EventProps) => void,
  purchaseProduct: (cart: CartResume, products: ProductCart[]) => void,
}

const GTM_KEY = process.env.REACT_APP_GTM_KEY;
if(GTM_KEY){
  const tagManagerArgs = {
    gtmId: GTM_KEY
  };

  TagManager.initialize(tagManagerArgs);
}

const ANALYTICS_KEY = process.env.REACT_APP_ANALYTICS_KEY;

const AnalyticsContext = createContext<AnalyticsContextData>({} as AnalyticsContextData);

const AnalyticsProvider: React.FC = ({ children }) => {
  function startAnalytics(){
    if(!ANALYTICS_KEY) return null;

    const URL = window.location.pathname + window.location.search;

    ReactGA.initialize(ANALYTICS_KEY);
    ReactGA.pageview(URL);
    ReactGA.ga('require', 'ecommerce');
  }

  function sendEventAnalytics(props: EventProps){
    ReactGA.event(props);
  }

  function purchaseProduct(cart: CartResume, products: ProductCart[]){
    sendEventAnalytics({category: 'Pedido', action: 'Finalizou um pedido', label: 'Botão de finalizar pedido'});

    if(!products[0]) return;

    ReactGA.ga('ecommerce:addTransaction', {
      'id': products[0].cartProductId,       // Transaction ID. Required.
      // 'affiliation': 'Acme Clothing',   // Nome da loja.
      'revenue': cart.totalValue,          // Grand Total.
      // 'shipping': '5',                  // Custo do frete.
      // 'tax': '1.29'                     // Tax.
    });

    products.map((item) => {
      ReactGA.ga('ecommerce:addItem', {
        'id': item.cartProductId,
        'name': item.name,
        'sku': item.sku,
        'category': '',
        'price': item.price,
        'quantity': item.quantity
      });
    });

    ReactGA.ga('ecommerce:send');
  }

  return (
    <AnalyticsContext.Provider value={{ startAnalytics, sendEventAnalytics, purchaseProduct }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

function useAnalytics(): AnalyticsContextData {
  const context = useContext(AnalyticsContext);

  if (!context) {
    throw new Error('useLoading deve ser usado com o AnalyticsProvider');
  }

  return context;
}

export { AnalyticsProvider, useAnalytics };
