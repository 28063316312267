import styled from 'styled-components';

interface Props {
  color?: string;
}

export const Container = styled.div<Props>`
  background-color: ${({color}) => color ?? 'var(--purple)'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 82px;
  padding: 5px 12px;
  border-radius: 100px;

  button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus {
      outline: none;
    }
  }
`;

export const Button = styled.button`
  > i {
    color: var(--white);
  }
`;

export const Page = styled.div`
  color: var(--white);
  font-size: 14px;
`;

