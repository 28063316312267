import styled from 'styled-components';

export const Label = styled.label`
  display: flex;
  flex: 1;
  flex-direction: column;
  > span {
    font-size: 12px;
  }
`;

export const SelectStyled = styled.select`
  margin-top: 9px;
  padding-bottom: 3px;
  border-bottom: 1px solid var(--grey-medium-4);
  outline: none;
  font-size: 1rem;
  color: var(--grey-dark);
  @media screen and (min-width: 680px) and (max-width: 1279px) {
    font-size: 14px;
  }
  @media screen and (min-width: 680px) {
    font-size: 14px;
  }
`;

export const Option = styled.option`
  font-size: 14px;
  color: var(--grey);
  padding: 14px 0;
  &:disabled {
    color: var(--grey-light-1);
  }
`;

export const RequiredTip = styled.span`
  color: var(--red-dark);
`;

export const TipContainer = styled.div`
  height: 17px;
`;

export const TipMessage = styled.p`
  font-size: 12px;
  color: var(--red-dark);
  margin-top: 3px;
`;
