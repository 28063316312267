import React, {useCallback, useEffect, useRef, useState} from 'react';

import {Title} from '../styles';
import {Container, EmptyPayments, IconCard, ListCards, TextEmpty} from './styles';
import RegisterCardModal from './components/RegisterCardModal';
import Card from '../../../components/Card';
import {useAlert} from '../../../hooks/useAlert';
import {CardInterface, CardSaveInterface, cardTypesInterface} from '../../../interfaces/creditCard';
import AMEX from '../../../assets/svg/AMEX.svg';
import DINERS from '../../../assets/svg/DINERS.svg';
import ELO from '../../../assets/svg/ELO.svg';
import HIPER from '../../../assets/svg/HIPER.svg';
import HIPERCARD from '../../../assets/svg/HIPERCARD.svg';
import MASTERCARD from '../../../assets/svg/MASTERCARD.svg';
import VISA from '../../../assets/svg/VISA.svg';
import {AddressAddButton, ButtonContainer, IconAdd, TitleContainer} from '../Address/styles';
import Button from '../../../components/Button';
import {api} from '../../../services/api';
import Lottie from 'react-lottie';
import LoadingAnimation from '../../../assets/animations/loading.json';
import {useToast} from '../../../hooks/useToast';

const Payments: React.FC = () => {
  const [cards, setCards] = useState<CardInterface[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [load, setLoad] = useState(true);

  const {showAlert} = useAlert();
  const {toast} = useToast();

  const defaultOptions = useRef({
    loop: true,
    autoplay: true,
    animationData: LoadingAnimation,
  }).current;

  const cardTypes = useRef<cardTypesInterface>({
    AMEX,
    ELO,
    HIPERCARD,
    HIPER,
    MASTERCARD,
    DINERS,
    VISA
  }).current;

  const handleOpenModal = useCallback(
    () => {
      setModalOpen(!modalOpen);
    },
    [modalOpen],
  );

  const getCards = useCallback(async () => {
    try {
      setLoad(true);
      const {data} = await api.get(`/payments/cards?page=${page}&limit=6`);
      setTotalPages(data.totalPages);

      if (page == 1) {
        setCards(data.cards);
        return;
      }

      setCards([...cards, ...data.cards]);
    } catch (e: any) {
      toast({
        type: 'error',
        description: 'Não foi possível listar seus cartões'
      });
    } finally {
      setLoad(false);
    }
  }, [cards, page, toast]);

  const deleteCard = useCallback(async (id) => {
    const allCards = [...cards];
    try {
      setCards([]);
      setLoad(true);

      await api.delete(`/payments/cards/${id}`);

      if (page !== 1) {
        setPage(1);
      } else {
        await getCards();
      }

      toast({
        type: 'success',
        description: 'Cartão excluído com sucesso!'
      });

    } catch (e: any) {
      setCards(allCards);
      toast({
        type: 'error',
        description: 'Não foi possivel excluir o cartão, tente novamente!'
      });
    } finally {
      setLoad(false);
    }
  }, [cards, getCards, page, toast]);

  const handleDeleteCard = useCallback((id: string) => {
    showAlert({
      type: 'error',
      cancelText: 'Cancelar',
      description: 'Tem certeza que deseja excluir este cartão?',
      submitText: 'Excluir',
      onSubmit: () => deleteCard(id)
    });
  }, [deleteCard, showAlert]);

  const setMainCard = useCallback(async (id: string) => {
    try {
      const allCards = [...cards];

      await api.patch(`/payments/cards/${id}`);
      allCards.forEach((card) => card.main = card.id === id ? 1 : 0);

      setCards(allCards);

      toast({
        type: 'success',
        description: 'Cartão principal selecionado com sucesso!'
      });
    } catch (e: any) {
      toast({
        type: 'error',
        description: 'Não foi possivel selecionar o cartão principal'
      });
    }

  }, [toast, cards]);

  const onSaveCard = useCallback(async (card: CardSaveInterface) => {
    const allCards = [...cards];
    try {
      setCards([]);
      setLoad(true);

      await api.post('/payments/cards', card);

      if (page !== 1) {
        setPage(1);
      } else {
        await getCards();
      }

      toast({
        type: 'success',
        description: 'Cartão cadastrado com sucesso!'
      });
    } catch (e: any) {
      setCards(allCards);
      toast({
        type: 'error',
        description: 'Não foi possivel cadastrar o cartão, tente novamente!'
      });
    } finally {
      setLoad(false);
    }

  }, [cards, getCards, page, toast]);

  const nextPage = useCallback(async () => {
    if (page == totalPages) {
      return;
    }

    setPage(page + 1);
  }, [page, totalPages]);

  useEffect(() => {
    getCards();
    // eslint-disable-next-line
  }, [page]);

  return (
    <Container>
      <TitleContainer>
        <Title>Formas de Pagamento</Title>

        {
          cards.length > 0 &&
          <AddressAddButton onClick={handleOpenModal}>
            <IconAdd>
              +
            </IconAdd>
            Adicionar novo cartão
          </AddressAddButton>
        }
      </TitleContainer>

      <ListCards>
        {
          cards.map((card) => (
            <div key={card.id} className={'card-box'}>
              <Card
                headerTitle={'Crédito'}
                type="payment"
                params={{
                  lastNumbers: card.number.substr(-4, 4),
                  cardBanner: card.brand ? card.brand.toLowerCase() : '',
                  shelfLife: `${card.expirationMonth}/${card.expirationYear}`,
                  cardBannerImg: card.brand ? cardTypes[card.brand.toUpperCase()] : '',
                  cardBannerAlt: `Logo ${card.brand}`,
                }}
                checked={card.main === 1}
                onCheck={() => setMainCard(card.id)}
                onDelete={() => handleDeleteCard(card.id)}
              />
            </div>
          ))
        }

      </ListCards>
      {
        cards.length === 0 && !load &&
        <EmptyPayments>
          <IconCard/>
          <TextEmpty>Você ainda não possui cartões cadastrados</TextEmpty>
          <AddressAddButton onClick={handleOpenModal}>
            <IconAdd>
              +
            </IconAdd>
            Adicionar novo cartão
          </AddressAddButton>
        </EmptyPayments>
      }

      {
        !load && totalPages > 1 && page < totalPages &&
        <ButtonContainer>
          <Button onClick={nextPage} color={'var(--purple)'} outline expand scale={'scale-5'}>Ver mais</Button>
        </ButtonContainer>
      }

      {
        load && <Lottie width={64} height={64} options={defaultOptions}/>
      }

      <RegisterCardModal
        open={modalOpen}
        onClose={handleOpenModal}
        onSave={onSaveCard}
      />

    </Container>
  );
};

export default Payments;
