import axios from 'axios';
import {API_BASE_URL, API_NODE, API_NODE_TOKEN} from '../config/contants';
import secure from '../config/secureLS';

export const api = axios.create({
  baseURL: API_BASE_URL,
});

export const api_node = axios.create({
  baseURL: API_NODE,
  headers: {
    Authorization: API_NODE_TOKEN
  }
})

api.interceptors.request.use((config) => {
  const token = secure.get('houpa@access-token');
  if (token?.data) {
    config.headers = {
      Authorization: `Bearer ${token.data}`,
    };
  } else {
    const session_id = secure.get('@houpa:session_id');

    if (session_id?.data) {
      config.headers = {
        session_id: session_id.data,
      };
    }
  }
  return config;
});

api.interceptors.response.use(
  (res) => res,
  (error) => {
    const { response } = error;

    if (response) {
      const token = secure.get('houpa@access-token');

      if (response.status === 401 && token?.data) {
        secure.clear();
        window.location.href = process.env.REACT_APP_PUBLIC_URL || '';
      }

      throw { ...response.data, status: response.status };
    }
  },
);
