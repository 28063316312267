import React from 'react';
import {
  CardInfo,
  CardHeader,
  CardBody,
  Content,
  Container,
  CodeBlock,
} from './styles';

import ProductCard from '../../../components/ProductCard';

import Frente from '../../../assets/jpg/marron_frente.jpg';
import Traz from '../../../assets/jpg/marron_traz.jpg';

const ProductCardExample: React.FC = () => {
  return (
    <Container>
      <Content>
        <h2>Cards de endereço e pagamento</h2>
        <p></p>
        <CardInfo>
          <CardHeader>
            <ProductCard
              image={Frente}
              hoverImage={Traz}
              title={'BLUSA CAVA AMERICANA TRIC...'}
              price={'R$ 50,00'}
              pricePromotion={'R$99,90'}
              parcel={'2X R$ 25,00 s/juros'}
            />
          </CardHeader>
          <CardBody>
            <p>import ProductCard from "components/ProductCard"</p>
            <CodeBlock>
              <h1>ProductCard</h1>
              <pre>{`
                <ProductCard
                  image={url da imagem}
                  hoverImage={url da imagem de hover}
                  title={"BLUSA CAVA AMERICANA TRIC..."}
                  price={"R$ 50,00"}
                  pricePromotion={"R$99,90"}
                  parcel={"2X R$ 25,00 s/juros"}
                />
              `}</pre>
            </CodeBlock>
          </CardBody>
        </CardInfo>
      </Content>
    </Container>
  );
};

export default ProductCardExample;
