import React, { createContext, useContext, useState } from 'react';
import AlertModal from '../components/AlertModal';
import {AlertTypes} from '../interfaces/alert';
interface AlertContextData {
  showAlert: (params: Params) => void;
  closeAlert: () => void;
}

interface Params {
  type: AlertTypes;
  description: string | JSX.Element;
  cancelText?: string;
  submitText?: string;
  onCancel?: () => void;
  onSubmit?: () => void;
  isBottom?: boolean;
}

const AlertContext = createContext<AlertContextData>({} as AlertContextData);

const AlertProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [params, setParams] = useState({} as Params);

  const showAlert = (data: Params) => {
    setParams(data);
    setOpen(true);
  };

  const closeAlert = () => setOpen(false);

  return (
    <AlertContext.Provider value={{ showAlert, closeAlert }}>
      <AlertModal
        open={open}
        close={closeAlert}
        type={params.type}
        description={params.description}
        onSubmit={params?.onSubmit}
        submitText={params?.submitText}
        onCancel={params?.onCancel}
        cancelText={params?.cancelText}
        isBottom={params?.isBottom}
      />
      {children}
    </AlertContext.Provider>
  );
};

function useAlert(): AlertContextData {
  const context = useContext(AlertContext);

  if (!context) {
    throw new Error('useAlert deve ser usado com o AlertProvider');
  }

  return context;
}

export { AlertProvider, useAlert };
