import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;
export const Wrapper = styled.div`
  width: 100%;
  padding: 10px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Line = styled.div`
  height: 2px;
  background-color: var(--purple);
  width: 85%;
  position: relative;
  z-index: 0;
  top: 44px;
  &.canceled {
    background-color: var(--grey-medium-1);
  }

  @media (max-width: 1279px) {
    top: 30px;
  }

  @media (max-width: 607px) {
    top: 32px;
  }
`;

export const StepsWrapper = styled.div`
  position: relative;
  height: 20px;
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: space-between;
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100px;
  width: 140px;

  @media (max-width: 1279px) {
    height: 75px;
    width: 100px;
  }

`;

export const StepCircle = styled.div`
  min-width: 54px;
  min-height: 54px;
  background-color: var(--grey-light-2);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04),
    0px 2px 4px rgba(96, 97, 112, 0.16);

  &.completed {
    background-color: var(--white);
  }

  &.active {
    background-color: var(--purple);
    min-width: 64px;
    min-height: 64px;
    i {
      font-size: 26px;
    }

    @media (max-width: 1279px) {
      min-width: 48px;
      min-height: 48px;
      i {
        font-size: 21px;
      }
    }

    @media (max-width: 607px) {
      min-width: 40px;
      min-height: 40px;
      i {
        font-size: 17px;
      }
    }
  }

  &.canceled {
    background-color: var(--grey-light-1);
    i {
      color: var(--grey-medium-4);
    }
  }

  &.stepCanceled {
    background-color: var(--white);
  }

  @media (max-width: 1279px) {
    min-width: 36px;
    min-height: 36px;
  }

  @media (max-width: 1279px) {
    min-width: 32px;
    min-height: 32px;
  }

`;

export const StepText = styled.p`
  font-size: 14px;

  @media (max-width: 1279px) {
    font-size: 10px;
  }

  @media (max-width: 607px) {
    font-size: 9px;
  }
`;

export const Icon = styled.i`
  color: var(--white);
  font-size: 20px;
  line-height: 0px;

  @media (max-width: 1279px) {
    font-size: 16px;
  }

  @media (max-width: 607px) {
    font-size: 14px;
  }

  &.icon-success {
    color: var(--green);
    font-size: 15px;
    @media (max-width: 1279px) {
      font-size: 12px;
    }

    @media (max-width: 607px) {
      font-size: 10px;
    }
  }

  &.icon-exit {
    color: var(--red-medium);
  }
`;

export const Content = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 607px) {
    min-height: 61px;
  }
`;

export const Footer = styled.div`
  text-align: center;
  width: 100%;
  margin-top:0px;
  display:flex;
  justify-content:center;
  align-items:center;
  height:10px;
`;
