import styled from 'styled-components';

export const Form = styled.div`
  width: 100%;
`;

export const LabelStyle = styled.div`
  width: 100%;
  cursor: pointer;
  span:first-child {
    color: var(--purple);
  }
  span:nth-child(2) {
    font-size: 14px;
    width: 100%;
    color: var(--grey-dark);
    @media screen and (min-width: 607px) and (max-width: 1279px) {
      font-size: 13px;
    }
    @media screen and (max-width: 607px) {
      font-size: 12px;
    }
  }
  .addressColor span:nth-child(2) {
    color: var(--grey-medium-3);
  }
  span > span input {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`;
