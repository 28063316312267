import styled from 'styled-components';

export const Container = styled.footer` 
  background-color: var(--grey-light-2);
  min-width: 292px;
`;

export const Wrapper = styled.div`  
  background-color: transparent;
  max-width: 1300px;
  padding: 52px 10px;
  margin: 0 auto;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    max-width: 628px;
  }
  @media screen and (max-width: 607px) {
    max-width: 332px;
  }
  @media screen and (max-width: 1279px) {
    padding: 32px 10px;
  }
`;

export const LineBreak = styled.br` 
  display: none;
  &.desktop {
    display: block;
  }
  @media screen and (max-width: 1279px) {
    &.tablet {
      display: block;
    }
  }
  @media screen and (max-width: 607px) {
    &.mobile {
      display: block;
    }
  }
`;

