import styled from 'styled-components';
import Background from '../../assets/png/back-b2c.png';

export const Container = styled.div`
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;

  @media (min-width: 1279px) {
    background-image: url(${Background});
    padding: 40px 0;
  }

  @media (max-width: 1279px) {
    background-image: none;
    background-color: var(--white);
  }
`;

export const Header = styled.div`
  cursor: pointer;
  max-height: 38px;

  @media (min-width: 1279px) {
    i {
      color: var(--white);
      font-size: 40px;
    }
  }

  @media (max-width: 1279px) {
    display: none;
  }
`;

export const ButtonArrow = styled.button`
  display: flex;
  align-items: center;
  margin-bottom: 39px;
  cursor: pointer;

  i {
    color: var(--purple);
    font-size: 20px;
  }

  span {
    font-size: 16px;
    margin-left: 9px;
  }

  @media (max-width: 608px) {
    margin-bottom: 101px;
  }
`;

export const Wrapper = styled.div`
  width: var(--container);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  min-height: 600px;
  height: calc(100vh - 80px);
`;

export const Panel = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 40px;

  @media (min-width: 1279px) {
    width: 524px;
    border-radius: 10px;
  }

  @media (max-width: 1279px) {
    width: 100%;
  }

  @media (max-width: 608px) {
    padding: 24px 10px;
  }
`;

export const PanelTop = styled.div`
  margin-bottom: 42px;
  @media (max-width: 608px) {
    margin-bottom: 50px;
  }
`;

export const PanelContent = styled.div`
  height: 100%;
`;

export const PanelTitle = styled.h1`
  font-size: 24px;
  font-family: var(--poppins);
  line-height: 36px;

  @media (max-width: 608px) {
    line-height: 0;
  }
`;

export const PanelFooter = styled.div`
  padding-top: 10px;
  display: flex;
  font-family: var(--roboto);
  font-size: 16px;
  justify-content: center;

  a {
    color: var(--grey-dark);
    font-weight: 700;
    margin-left: 4px;
    text-decoration: underline;
  }

  @media (max-width: 1279px) {
    font-size: 14px;
  }
`;

export const PanelFooterText = styled.p`
  color: var(--grey-dark);
`;
