import React, {useCallback, useEffect, useState} from 'react';
import {
  Body, 
  ButtonClose, 
  Container, 
  DeliveryData, 
  RequiredTipContainer,
  TipChar
} from './styles';
import {Backdrop, Fade} from '@material-ui/core';
import RadioButtonsGroup from '../../../../../../../components/Radio';
import SelectCheckbox from '../../../../../../../components/SelectCheckbox';
import Button from '../../../../../../../components/Button';
import {useAlert} from '../../../../../../../hooks/useAlert';
import {
  ProductsInterface,
  OrderDetailsInterface,
  ReasonsAndTypesInterface,
} from '../../../../../Orders/Details';
import { RequiredTip } from '../../../../../../../components/Input'


interface OwnProps {
  open: boolean;
  onClose?: () => void;
  onSave?: (data: RequestReplacementInterface) => void;
  product: ProductsInterface | null;
  orderId?: number;
  freightData?: OrderDetailsInterface | null;
  reasonsAndTypes?: ReasonsAndTypesInterface | null;
}

export interface RequestReplacementInterface {
  order_id: number | undefined;
  item_id: number | undefined;
  type_id: number;
  reasons: (number | false)[];
  description: string;
}

const ReplacementModal: React.FC<OwnProps> = ({
  open,
  onClose,
  onSave,
  product,
  orderId,
  freightData,
  reasonsAndTypes,
}) => {
  const { showAlert } = useAlert();
  const [
    selectedType,
    setSelectedType,
  ] = useState<number>(1);
  const [
    reasons,
    setReasons,
  ] = useState<string[]>([]);
  const [
    selectedReasons,
    setSelectedReasons,
  ] = useState<string[]>([]);

  const [comment, setComment] = useState<string>('');

  const cleanStates = () => {
    setSelectedType(0);
    setSelectedReasons([]);
    setComment('');
  };

  const verifyFields = useCallback(() => {
    if (selectedReasons.length === 0) {
      return false;
    }

    if (selectedType === null) {
      return false;
    }

    const reasons = reasonsAndTypes?.reasons.map(
      (reason) => selectedReasons.includes(reason.name_reason) && reason.id,
    );

    return reasons?.filter((reason) => reason !== false);
  }, [reasonsAndTypes, selectedType, selectedReasons]);

  const save = useCallback(() => {
    const reasonsArray = verifyFields();
    if (!reasonsArray) {
      showAlert({
        description:
          'Preencha todas as informações para solicitar uma troca ou devolução.',
        type: 'error',
        submitText: 'Ok',
      });
      return;
    }
    const data: RequestReplacementInterface = {
      order_id: orderId,
      item_id: product?.item_id,
      type_id: selectedType,
      reasons: reasonsArray,
      description: comment,
    };
    onClose && onClose();
    onSave && onSave(data);
    cleanStates();
  }, [comment, onClose, onSave, orderId, product, selectedType, showAlert, verifyFields]);

  useEffect(() => {
    const reasons = reasonsAndTypes?.reasons.map(
      (reason) => reason.name_reason,
    );
    Array.isArray(reasons) && setReasons(reasons);
  }, [reasonsAndTypes]);

  if (!product) {
    return null;
  }

  return (
    <Container
      open={open}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <Body>
          <ButtonClose onClick={() => onClose && onClose()}>
            <i className="icon icon-exit" />
          </ButtonClose>

          <button
            className={'back-button'}
            onClick={() => onClose && onClose()}
          >
            <i className={'icon icon-arrow-left'} />
            <span>Voltar</span>
          </button>

          <div className={'title'}>
            Preencha o formulário para solicitar uma troca ou devolução
          </div>

          <div className="wrapper">
            <div className={'product'}>
              <div className="product-image">
                <img src={product.product_image} alt="Imagem do produto" />
              </div>

              <div className="product-info">
                <p className="mb16">
                  n° do pedido{' '}
                  <span className="product-title order-number">{orderId}</span>
                </p>
                <p>
                  <span className="product-title">Loja: </span>
                  {product.brand}
                </p>
                <p>{product.product_name}</p>

                <p>
                  <span className="product-title">Tam: </span>
                  {product.product_size}
                </p>
                <p>
                  <span className="product-title">Cor: </span>
                  {product.product_color}
                </p>
                <p>
                  <span className="product-title">SKU: </span>
                  {product.SKU}
                </p>
              </div>
            </div>
            <DeliveryData>
              <h1>Dados de Entrega</h1>
              {freightData?.receiver && (
                <p>
                  <strong>Nome: </strong>
                  {freightData?.receiver}
                </p>
              )}
              {freightData?.email && (
                <p>
                  <strong>E-mail: </strong>
                  {freightData?.email}
                </p>
              )}
              {freightData?.cellphone && (
                <p>
                  <strong>Telefone: </strong>
                  {freightData?.cellphone}
                </p>
              )}
              {freightData?.street && (
                <p>
                  <strong>Endereço: </strong>
                  {freightData?.street}, {freightData?.number}
                  {freightData?.complement &&
                    ` , ${freightData?.complement}`} - {freightData?.city} -{' '}
                  {freightData?.state} - CEP {freightData?.zipcode}
                </p>
              )}
              {freightData?.district && (
                <p>
                  <strong>Bairro: </strong> {freightData?.district}
                </p>
              )}
            </DeliveryData>
            <div className="form">
              <p className="subtitle">
                O que você deseja na substituição do seu produto<TipChar>*</TipChar>
              </p>

              <div className="radio-group">
                {reasonsAndTypes?.types.map((type) => (
                  <RadioButtonsGroup
                    key={type.id}
                    onCheck={() => setSelectedType(type.id)}
                    checked={type.id === selectedType && true}
                    label={type.name_type}
                  />
                ))}
              </div>

              <div className="select-container">
                <SelectCheckbox
                  options={reasons}
                  label="Motivo da solicitação"
                  onChange={(selectedOptions) =>
                    setSelectedReasons(selectedOptions)
                  }
                  required
                />
              </div>

              <div className="text-area-container">
                <label>
                  Escreva aqui mais informações da sua solicitação, se desejar:
                  <textarea
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </label>
              </div>
              <RequiredTipContainer>
                <RequiredTip removePadding/>
              </RequiredTipContainer>
              <div className="footer">
                <Button
                  onClick={save}
                  scale="scale-2"
                  color="var(--purple)"
                  expand
                >
                  Enviar solicitação
                </Button>
              </div>
            </div>
          </div>
        </Body>
      </Fade>
    </Container>
  );
};

export default ReplacementModal;
