import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  text-align: center;
  .icon:hover ~ .tipWrapper {
    display: block;
  }
`;

export const TipWrapper = styled.div`
  position: absolute;
  z-index: 9999;
  bottom: 10px;
  transform: translate3d(calc(-50% + 8px), 0, 0);
  width: fit-content;
  height: fit-content;
  padding: 10px;
  display: none;
  &:hover {
    display: block;
  }
`;

export const Tip = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  padding: 10px;
  min-width: 30px;
  bottom: 10px;
  background: var(--white);
  margin-bottom: 8px;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0px;
    width: 0px;
    height: 0px;
    margin-bottom: -10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid var(--white);
  }
`;

export const Icon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background: var(--grey-light-1);
  border-radius: 50%;
  font-weight: bold;
  color: var(--white);
  font-family: 'IBM Plex Mono', monospace;
  font-size: 12px;
  cursor: help;
`;
