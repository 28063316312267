import {Modal} from '@material-ui/core';
import styled from 'styled-components';

interface Image {
  src?: string;
  hoverImage?: string;
  width?: number;
}

export const Container = styled.div``;

export const HoverOpacity = styled.div<Image>`
  padding: ${props => props.width && props.width < 180 ? '8px' : '16px'};

  height: 100%;
  width: 100%;
  transition: 0.3s;
  display: flex;
  flex-direction: column;

  &:hover {
    background-color: rgba(34, 34, 34, 0.5);
    transition: 0.3s;
  }
`;

export const CardContainer = styled.div<Image>`
  width: 100%;
  border-radius: 3px;
  height: ${(props) => (props.width ? `${props.width * 1.3}px` : '0px')};

  overflow: hidden;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &:hover {
    background-image: url(${({ src, hoverImage }) =>
    hoverImage ? hoverImage : src});
    transition: 0.3s;

    .card_container {
      display: flex;
    }
  }
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Chip = styled.div<Image>`
  background-color: var(--white);
  padding: 6px 14px;
  box-shadow: 0 0 0.55px rgba(40, 41, 61, 0.04),
  0 1.11px 2.23px rgba(96, 97, 112, 0.16);
  border-radius: 22.36px;

  font-size: 12px;
  line-height: 12px;
  color: var(--grey-medium-4);

  height: 24px;
  width: ${props => props.width && props.width < 180 ? '40px' : '70px'};
  min-width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;

`;

export const CircleButton = styled.button`
  cursor: pointer;
  height: 32px;
  width: 32px;
  padding: 10px;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;

  &.hanger {
    color: var(--grey-medium-4);
  }

  &.favorite {
    color: var(--red-light);
  }

  &:hover,
  &.active {
    background: ${(props) => props.theme.colors.redLight};
    /* &:first-child {
      background-color: ${(props) => props.theme.colors.purple};
    }

    &:last-child {
      background-color: ${(props) => props.theme.colors.redLight};
    } */

    i {
      color: var(--white);
    }
  }
`;

export const CardContent = styled.div<Image>`
  display: none;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  max-width: 167px;
  margin: 0 auto;
  margin-bottom: ${props => props.width && props.width < 180 ? '20px' : '56px'};
  gap: 14px;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  justify-content: flex-end;
`;

export const TextContainer = styled.div`
  margin-top: 16px;

  div {
    display: flex;
    align-items: flex-end;
    margin-top: 6px;
    gap: 12px;
  }
`;
export const TitleBrand = styled.h1`
  font-weight: 500;
  font-size: 16px;
  margin: 10px 0;
`;

export const Title = styled.h1`
  width: 100%;
  font-weight: 300;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const Price = styled.b`
  font-size: 14px;
`;
export const PricePromotion = styled.s`
  color: var(--grey-medium-4);
  font-size: 12px;
`;
export const Parcel = styled.span`
  font-size: 10px;
`;

export const ModalContainer = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3000 !important;
  outline: none;
`;

export const Launch = styled.div`
  font-size: 10px;
  padding: 5px 10px;
  color: var(--grey-medium-1);
  background-color: var(--grey-light-2);
  margin-right: 10px;
`;

export const SoldBy = styled.div`
  font-size: 12px;

  p {
    display: inline-block;
    margin-right: 30px;
  }

  span {
    color: var(--grey-light-1);
  }

  strong {
    text-decoration: underline;
  }


  @media (min-width: 37.9375em) {
    margin-bottom: 11px;

    > p {
      margin-right: 19px;
    }
  }
`;

export const PriceModal = styled.div`
  margin-bottom: 20px;

  .price {
    font-size: 24px;
  }

  &.web {
    display: none;
  }

  @media (min-width: 37.9375em) {
    display: none;

    &.web {
      display: block;
    }
  }
`;

export const Details = styled.div`
  margin: 16px 0;
  cursor: pointer;

  .details {
    color: var(--grey);
    text-decoration-line: underline;
    text-align: center;
    font-size: 10px;
  }

  &.web {
    display: none;
  }

  @media (min-width: 37.9375em) {
    display: none;
    height: 24px;
    margin: 8px;

    &.web {
      display: initial;
    }
  }
`;

interface SizeInterface {
  available: boolean;
  active?: boolean;
}

export const Size = styled.div<SizeInterface>`
  width: 40px;
  height: 40px;

  margin-right: 11px;

  border-radius: 50%;
  border: 1.5px solid ${props => props.active ? props.theme.colors.greyLight1 : props.theme.colors.white};

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${props => props.theme.colors.white};

  cursor: pointer;

  .size-content {
    width: 32px;
    height: 32px;

    border-radius: 50%;
    border: 1px solid ${props => props.theme.colors.greyLight1};

    display: flex;
    align-items: center;
    justify-content: center;

    background: ${props => props.theme.colors.greyLight3};

    position: relative;

    > span {
      font-size: 12px;
      color: ${props => props.theme.colors.greyDark};
      text-transform: uppercase;
    }

    .disabled {
      display: ${props => !props.available ? 'flex' : 'none'};
      width: 100%;
      height: 100%;

      border-radius: 50%;

      background: rgba(220, 220, 220, .8);

      z-index: 1;

      position: absolute;

      align-items: center;
      justify-content: center;

      .line {
        width: 100%;
        border: 1px solid ${props => props.theme.colors.greyLight1};
        transform: rotate(-60deg);
      }
    }
  }
`;


interface ColorInterface {
  hex?: string;
  active?: boolean;
}

export const Color = styled.button<ColorInterface>`
  width: 32px;
  height: 32px;
  margin-right: 12px;

  border-radius: 50%;

  cursor: pointer;

  border: 1.5px solid ${props => props.active ? props.theme.colors.greyLight1 : props.theme.colors.white};

  display: flex;
  align-items: center;
  justify-content: center;

  .color-content {
    width: 24px;
    height: 24px;

    border: 1px solid ${props => props.theme.colors.greyLight1};
    border-radius: 50%;

    background: ${(props) => props.hex !== undefined ? props.hex : props.theme.colors.white};

    > img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }
`;

export const VariantsRows = styled.div`
  display: flex;
`;
