import React, {useCallback, useEffect, useState} from 'react';
import {
  Container,
  Title,
  ContentWrapper,
  ContentTitle,
  InputStyled,
  ButtonContainer,
  AccountInfo,
  ChangePassword,
  PersonalData,
  ContactInfo,
  CkeckboxLabel,
  TooltipText,
  CheckboxContainer,
} from './styles';
import useForm from '../../../hooks/useForm';
import Checkbox from '../../../components/Checkbox';
import Select from './components/Select';
import AlertModal from '../../../components/AlertModal';
import NewPassword from './components/NewPassword';
import Button from '../../../components/Button';
import { RequiredTip } from '../../../components/Input'
import { api } from '../../../services/api';
import { format } from 'date-fns';
import { handleErrors } from '../../../helpers/utils';
import { useLoading } from '../../../hooks/useLoading';
import { validationTypes } from '../../../helpers/inputValidation';
import { validateBirthDate } from '../../../helpers/validateBirthDate';

interface Gender {
  id: string;
  name: string;
}

const MyRegister: React.FC = () => {
  const [modalSuccess, setModalSuccess] = useState<boolean>(false);
  const [modalPassword, setModalPassword] = useState<boolean>(false);
  const [whatsappContact, setWhatsappContact] = useState<boolean>(false);
  const [selectError, setSelectError] = useState<boolean>(false);
  const [selectedGender, setSelectedGender] = useState<string>('');
  const [genders, setGenders] = useState<Gender[]>([]);
  const {showLoading, closeLoading} = useLoading();


  const { form, error, onChange, validateForm, onBlur, setValueForm, setError } = useForm({
    username: {
      type: 'username',
      required: true,
      limit: 100,
    },
    email: {
      type: 'email',
      required: true,
      limit: 100,
    },
    fullName: {
      type: 'fullname',
      required: true,
      limit: 100,
    },
    birthDate: {
      type: 'date',
      required: true,
      limit: 10,
    },
    cpf: {
      type: 'cpf',
      required: true,
      limit: 14,
    },
    cellphone: {
      type: 'phone',
      limit: 15,
      required: true,
    },
    phone: {
      type: 'telephone',
      limit: 14,
    },
  });

  const openModalPassword = () => {
    setModalPassword(true);
  };

  const handleChecked = () => {
    setWhatsappContact(!whatsappContact);
  };

  const handleBirthDate = () => {
    const birthDate = validateBirthDate(form.birthDate);

    if(!birthDate.valid) {
      error.birthDate = birthDate.message;
      setError({ ...error });
    }
  }

  const onSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();

    const birthDate = validateBirthDate(form.birthDate);

    if(!birthDate.valid) {
      error.birthDate = birthDate.message;
      setError({ ...error });
      return false
    }

    if (!selectedGender) {
      setSelectError(true);
    }

    const { valid } = validationTypes['cpf'](form.cpf);

    if (
      validateForm() &&
      valid &&
      !selectError && 
      !error.email && 
      !error.cpf && 
      !error.phone && 
      !error.birthDate
    ) {
      try {
        showLoading();
        const data = {
          ...form,
          whatsappContact: whatsappContact
            ? format(new Date(), 'yyyy-MM-dd HH:mm:ss')
            : null,
          genderId: parseInt(selectedGender),
        };

        await api.post('account-data', data);

        setModalSuccess(true);
        closeLoading();
      } catch (e: any) {
        setError(handleErrors(e, error));
        closeLoading();
      }
    }
  }, 
  [
    error, 
    form, 
    selectError, 
    selectedGender, 
    setError, 
    validateForm, 
    whatsappContact, 
    showLoading, 
    closeLoading,
  ]);

  const getData = useCallback(async () => {
    try {
      showLoading();
      const { data } = await api.get('/account-data');

      setValueForm('username', data.username);
      setValueForm('email', data.email);
      setValueForm('fullName', data.fullName);
      setValueForm('birthDate', data.birthDate);
      setValueForm('cpf', data.cpf);
      setValueForm('cellphone', data.cellphone);
      setValueForm('phone', data.phone);
      setWhatsappContact(data.whatsappContact !== null);
      setSelectedGender(data.gender.id);
      closeLoading();
    } catch (e: any) {
      console.log(e);
      closeLoading();
    }
  }, [setValueForm, showLoading, closeLoading]);

  const getGenders = async () => {
    try {
      const {data} = await api.get('genders');

      setGenders(data);
    }catch (e: any){
      console.log(e);
    }
  };

  const init = useCallback(async () => {
    await Promise.all([
      getGenders(),
      getData(),
    ]);
  }, [getData]);

  useEffect(() => {
    init();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* Modals */}
      <AlertModal
        open={modalSuccess}
        type="success"
        description="Dados alterados com sucesso!"
        submitText="Ok"
        onSubmit={() => setModalSuccess(false)}
        close={() => setModalSuccess(false)}
      />
      <NewPassword open={modalPassword} setOpen={setModalPassword} />
      {/* Modals */}
      <Container onSubmit={onSubmit}>
        <Title>Meus Dados</Title>
        <ContentWrapper className="first">
          <ContentTitle>Informações da Conta</ContentTitle>
          <AccountInfo>
            <InputStyled
              title="Nome do usuário"
              value={form.username}
              onBlur={() => onBlur('username')}
              onChange={(e) => onChange('username', e)}
              error={error.username}
              placeholder="Digite o seu nome de usuário"
              required
              tooltipContent={
                <TooltipText>
                  O nome do usuário será o seu acesso para entrar no houpa!
                </TooltipText>
              }
            />
            <InputStyled
              className="email"
              title="E-mail"
              value={form.email}
              onBlur={() => onBlur('email')}
              onChange={(e) => onChange('email', e)}
              error={error.email}
              placeholder="Digite o seu e-mail"
              required
            />
            <ChangePassword onClick={openModalPassword}>
              Trocar senha
            </ChangePassword>
          </AccountInfo>
        </ContentWrapper>
        <ContentWrapper>
          <ContentTitle>Dados Pessoais</ContentTitle>
          <PersonalData>
            <InputStyled
              title="Nome completo"
              value={form.fullName}
              onBlur={() => onBlur('fullName')}
              onChange={(e) => onChange('fullName', e)}
              error={error.fullName}
              placeholder="Digite seu nome e sobrenome"
              required
            />
            <InputStyled
              title="Data de nascimento"
              value={form.birthDate}
              onBlur={() => {
                onBlur('birthDate');
                handleBirthDate();
              }}
              onChange={(e) => onChange('birthDate', e)}
              error={error.birthDate}
              placeholder="Digite apenas números"
              required
            />
            <InputStyled
              title="CPF"
              value={form.cpf}
              onBlur={() => onBlur('cpf')}
              onChange={(e) => onChange('cpf', e)}
              error={error.cpf}
              placeholder="Digite apenas números"
              required
            />
            <Select
              label="Gênero"
              disabledOption="Selecione a opção desejada"
              options={genders}
              selectError={selectError}
              setselectError={setSelectError}
              selectedValue={selectedGender}
              setSelectedValue={setSelectedGender}
              required
            />
          </PersonalData>
        </ContentWrapper>
        <ContentWrapper>
          <ContentTitle>Informações de Contato</ContentTitle>
          <ContactInfo>
            <InputStyled
              title="Celular"
              value={form.cellphone}
              onBlur={() => onBlur('cellphone')}
              onChange={(e) => onChange('cellphone', e)}
              error={error.cellphone}
              placeholder="Digite seu celular com DDD"
              required
            />
            <InputStyled
              title="Telefone"
              value={form.phone}
              onBlur={() => onBlur('phone')}
              onChange={(e) => onChange('phone', e)}
              placeholder="Digite seu telefone com DDD"
            />
          </ContactInfo>
          <CheckboxContainer>
            <Checkbox
              color="var(--purple)"
              checked={whatsappContact}
              onChange={handleChecked}
            >
              <CkeckboxLabel>
                Aceito receber novidades via Whatsapp.
              </CkeckboxLabel>
            </Checkbox>
            <Checkbox
              scale="scale-1"
              color="var(--purple)"
              checked={whatsappContact}
              onChange={handleChecked}
            >
              <CkeckboxLabel>
                Aceito receber novidades via Whatsapp.
              </CkeckboxLabel>
            </Checkbox>
          </CheckboxContainer>
        </ContentWrapper>
        <RequiredTip />
        <ButtonContainer>
          <Button
            scale="scale-4"
            color="var(--purple)"
            expand
            className="btnWeb"
          >
            Salvar alterações
          </Button>
          <Button
            scale="scale-4"
            color="var(--purple)"
            expand
            className="btnMobile"
          >
            Salvar
          </Button>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default MyRegister;
