import React from 'react';
import { Container, Icon, TipWrapper, Tip } from './styles';

interface Props {
  children: JSX.Element;
  className?: string;
}

const Tooltip: React.FC<Props> = ({ children, className }) => {
  return (
    <Container className={className}>
      <Icon className="icon">i</Icon>
      <TipWrapper className="tipWrapper">
        <Tip>{children}</Tip>
      </TipWrapper>
    </Container>
  );
};

export default Tooltip;
