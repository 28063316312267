import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Back,
  BodyModal,
  BrandName,
  ButtonModalContainer,
  Color,
  Description,
  Details,
  GridContainer,
  HeaderModal,
  HeaderProduct,
  Launch,
  ModalContainer,
  ModalContent,
  ModalDivMain,
  Parcel,
  PriceModal,
  PricePromotion,
  Size,
  SoldBy,
  TitleModal,
  VariantsCol,
  VariantsRows,
  SelectASize,
} from './styles';
import ProductImagesContainer from '../ProductImagesContainer';
import Button from '../Button';
import ShareModal from '../ShareModal';
import { ProductOrders } from '../../interfaces/orderProduct';
import { api } from '../../services/api';
import {
  ColorInterface,
  VariantInterface,
} from '../../pages/Product/interfaces';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToast } from '../../hooks/useToast';
import { useCart } from '../../hooks/useCart';
import { useAuth } from '../../hooks/useAuth';

interface Props {
  modalVisible: boolean;
  closeModal: () => void;
  callCart?: () => void;
  productSlug?: string;
  profileUrl?: string;
}

const ModalProduct: FunctionComponent<Props> = ({
  modalVisible,
  closeModal,
  callCart,
  productSlug,
  profileUrl,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { addToCart } = useCart();

  const [product, setProduct] = useState<ProductOrders>({} as ProductOrders);
  const [selectedColor, setSelectedColor] = useState<ColorInterface>(
    {} as ColorInterface,
  );
  const [selectedVariant, setSelectedVariant] = useState<VariantInterface>(
    {} as VariantInterface,
  );
  const [sizeError, setSizeError] = useState<string>('');

  const { user } = useAuth();

  const previousUrl = useRef(
    process.env.REACT_APP_PUBLIC_URL + location.pathname,
  ).current;
  const productUrl = useMemo(() => `/produto/${profileUrl}/${productSlug}`, [
    profileUrl,
    productSlug,
  ]);

  const navigateToProduct = useCallback(() => {
    navigate(productUrl, { replace: true });
  }, [navigate, productUrl]);

  const navigateToProfile = useCallback(() => {
    navigate(`/perfil/${profileUrl}`, { replace: true });
  }, [navigate, profileUrl]);

  const favoriteProduct = useCallback(async () => {
    if (Object.keys(product).length === 0 || product.isFavorite === 1) {
      return;
    }

    if (!user) {
      navigate(`/login?ReturnUrl=produto/${profileUrl}/${productSlug}`);
      return;
    }

    try {
      await api.post(`favorites-products/${product.id}`);
      toast({
        type: 'success',
        description: 'Produto favoritado com sucesso!',
      });

      setProduct({ ...product, isFavorite: 1 } as ProductOrders);
    } catch (e: any) {
      toast({
        type: 'error',
        description: 'Não foi possível favoritar o produto',
      });
    }
  }, [navigate, product, productSlug, profileUrl, toast, user]);

  const handleAddToCart = async (openSideCart = true as boolean) => {
    try {
      if (!selectedVariant.id) {
        setSizeError('Selecione um tamanho para continuar.');
        return false;
      }
      if (!selectedVariant.availableQuantity) {
        setSizeError('O tamanho selecionado não está disponível.');
        return false;
      }
      if (!profileUrl) {
        toast({
          type: 'error',
          description: 'Ocorreu um erro, Tente novamente.',
        });
        return false;
      }
      await addToCart(profileUrl, Number(selectedVariant.id));
      openSideCart && callCart && callCart();
      return true;
    } catch {
      toast({
        type: 'error',
        description: 'Não foi possível adicionar esse produto no carrinho',
      });
    }
  };

  const handlePurchase = async () => {
    const addedToCart = await handleAddToCart(false);
    if (addedToCart)
      window.location.href = process.env.PUBLIC_URL + '/carrinho';
  };

  useEffect(() => {
    window.history.pushState(
      '',
      '',
      process.env.REACT_APP_PUBLIC_URL + productUrl,
    );
    if (productSlug !== undefined && profileUrl !== undefined) {
      api
        .get(`/products/${profileUrl}/${productSlug}`)
        .then(({ data }) => {
          setProduct(data);
          setSelectedColor(data.colors[0]);
        })
        .catch((e) => console.log(e));
    }

    return () => {
      setProduct({} as ProductOrders);
      setSelectedColor({} as ColorInterface);
      setSelectedVariant({} as VariantInterface);
    };
  }, [previousUrl, productSlug, productUrl, profileUrl]);

  useEffect(() => {
    selectedVariant.id && selectedVariant.availableQuantity && setSizeError('');
  }, [selectedVariant.id, selectedVariant.availableQuantity]);

  return (
    <ModalContainer
      open={modalVisible}
      onClose={() => {
        window.history.pushState('', '', previousUrl);
        closeModal();
      }}
    >
      <ModalContent>
        <button
          onClick={() => {
            window.history.pushState('', '', previousUrl);
            closeModal();
          }}
        >
          <i className="icon icon-exit" />
        </button>
        <ModalDivMain>
          <HeaderModal>
            <Back
              onClick={() => {
                window.history.pushState('', '', previousUrl);
                closeModal();
              }}
            >
              <i className="icon icon-arrow-left" /> Voltar
            </Back>
          </HeaderModal>
          <BodyModal>
            <HeaderProduct>
              <BrandName>
                <span>{product.supplier}</span>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {product.newReleaseStatus === '1' && (
                    <Launch>LANÇAMENTO</Launch>
                  )}
                  <ShareModal />
                </div>
              </BrandName>
              <TitleModal>
                <h1>{product.name}</h1>
              </TitleModal>
              <SoldBy>
                <p>
                  Vendido por:{' '}
                  <strong onClick={() => navigateToProfile()}>
                    {product.seller}
                  </strong>
                </p>
                <span>Ref.: {product.reference}</span>
              </SoldBy>
            </HeaderProduct>
            <PriceModal>
              <PricePromotion>{product.originalPrice}</PricePromotion>
              <p className="price">{product.price}</p>
              <Parcel>{product.paymentCondition}</Parcel>
            </PriceModal>
            <div className="ds-flex">
              <GridContainer>
                <ProductImagesContainer
                  smallColumnWidth={91}
                  bigColumnWidth={400}
                  images={product.images}
                  mainImage={product.mainImage}
                  isFavorite={product.isFavorite === 1}
                  onFavorite={favoriteProduct}
                />

                <Details onClick={() => navigateToProduct()} className={'web'}>
                  <p className="details">
                    Clique aqui para ver todos os detalhes do produto.
                  </p>
                </Details>
              </GridContainer>
              <div>
                <HeaderProduct className={'web'}>
                  <BrandName>
                    <span>{product.supplier}</span>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {product.newReleaseStatus === '1' && (
                        <Launch>LANÇAMENTO</Launch>
                      )}
                      <ShareModal />
                    </div>
                  </BrandName>
                  <TitleModal>
                    <h1>{product.name}</h1>
                  </TitleModal>
                  <SoldBy>
                    <p>
                      Vendido por:{' '}
                      <strong onClick={() => navigateToProfile()}>
                        {product.seller}
                      </strong>
                    </p>
                    <span>Ref.: {product.reference}</span>
                  </SoldBy>
                </HeaderProduct>
                <PriceModal className={'web'}>
                  <PricePromotion>{product.originalPrice}</PricePromotion>
                  <p className="price">{product.price}</p>
                  <Parcel>{product.paymentCondition}</Parcel>
                </PriceModal>
                <Details onClick={() => navigateToProduct()}>
                  <p className="details">
                    Clique aqui para ver todos os detalhes do produto.
                  </p>
                </Details>
                <Description>
                  <p className="description">{product?.description}</p>
                </Description>
                <VariantsCol>
                  <p>Selecione a cor:</p>
                  <VariantsRows>
                    {product.colors !== undefined &&
                      product.colors.map((color) => (
                        <Color
                          key={color.id}
                          hex={color.hexadecimal}
                          onClick={() => setSelectedColor(color)}
                          active={selectedColor.id === color.id}
                        >
                          {color.image ? (
                            <img src={color.image} alt={`Cor ${color.name}`} />
                          ) : (
                            <div className="color-content" />
                          )}
                        </Color>
                      ))}
                  </VariantsRows>
                </VariantsCol>

                <VariantsCol>
                  <p>Tamanho:</p>
                  <VariantsRows>
                    {selectedColor.variants !== undefined &&
                      selectedColor.variants.map((variant) => (
                        <Size
                          key={variant.id}
                          active={variant.id === selectedVariant.id}
                          available={variant.availableQuantity > 0}
                          onClick={() => setSelectedVariant(variant)}
                        >
                          <div className="size-content">
                            <span>{variant.sizeName}</span>
                            <div className="disabled">
                              <div className="line" />
                            </div>
                          </div>
                        </Size>
                      ))}
                  </VariantsRows>
                  {sizeError && <SelectASize>{sizeError}</SelectASize>}
                </VariantsCol>
                <ButtonModalContainer>
                  <Button expand scale={'scale-3'} onClick={handlePurchase}>
                    Comprar
                  </Button>
                  <Button
                    onClick={handleAddToCart}
                    className={'cart'}
                    expand
                    outline
                    scale={'scale-3'}
                  >
                    Adicionar ao carrinho
                  </Button>
                </ButtonModalContainer>
              </div>
            </div>
          </BodyModal>
        </ModalDivMain>
      </ModalContent>
    </ModalContainer>
  );
};

export default ModalProduct;
