import styled from 'styled-components';

interface Props {
  color?: string

}

export const Container = styled.button`
  display: flex;

  align-items: center;
  padding: 8px 12px;
  border: 1px solid var(--grey-light-2);
  border-radius: 2px;

  > p {
    font-size: 10px;
    font-family: Roboto-Medium, Poppins-Medium Arial, sans-serif;
    font-weight: 500;
    margin-right: 8px;
  }

  > i {
    font-size: 10px;
    cursor: pointer;
  }
`;

export const ColorContainer = styled.div<Props>`
  width: 13px;
  height: 12px;
  border-radius: 2px;
  margin-right: 7px;
  background-color: ${({color}) => color ?? ''};
`;
