import React from 'react';
import useForm from '../../../hooks/useForm';

import {
  CardInfo,
  CardHeader,
  CardBody,
  Content,
  Container,
  Wraper,
  CodeBlock,
} from './styles';
import Input from '../../../components/Input';

const Inputs: React.FC = () => {
  const { form, error, onBlur, onChange } = useForm({
    name: {
      type: 'fullname',
      required: true,
    },
    email: {
      type: 'email',
      required: true,
    },
    password: {
      type: 'password',
      required: true,
    },
    phone: {
      type: 'phone',
      required: false,
    },
    cpf: {
      type: 'cpf',
      required: true,
    },
    cnpj: {
      type: 'cnpj',
      required: true,
    },
  });

  return (
    <Container>
      <Content>
        <h2>Houpa Checkbox</h2>
        <p></p>

        <CardInfo>
          <CardHeader>
            <Input
              title={'Nome e sobrenome'}
              value={form.name}
              onBlur={() => onBlur('name')}
              onChange={(e) => onChange('name', e)}
              error={error.name}
              required
              placeholder={'Insira o seu nome'}
            />

            <Input
              title={'E-mail'}
              value={form.email}
              onBlur={() => onBlur('email')}
              onChange={(e) => onChange('email', e)}
              error={error.email}
              required
              placeholder={'exemplo@exemplo.com'}
            />

            <Input
              title={'Senha'}
              value={form.password}
              onBlur={() => onBlur('password')}
              onChange={(e) => onChange('password', e)}
              error={error.password}
              type={'password'}
              placeholder={'********'}
            />

            <Input
              title={'Phone'}
              value={form.phone}
              onBlur={() => onBlur('phone')}
              onChange={(e) => onChange('phone', e)}
              error={error.phone}
              type={'phone'}
              placeholder={'(00) 0000-0000'}
            />

            <Input
              title={'Cpf'}
              value={form.cpf}
              onBlur={() => onBlur('cpf')}
              onChange={(e) => onChange('cpf', e)}
              error={error.cpf}
              required
              placeholder={'000.000.000-00'}
            />

            <Input
              title={'Cnpj'}
              value={form.cnpj}
              onBlur={() => onBlur('cnpj')}
              onChange={(e) => onChange('cnpj', e)}
              error={error.cnpj}
              required
              placeholder={'00.000.000/0000-00'}
            />
          </CardHeader>
          <CardBody>
            <p>Import Input from "components/forms/input"</p>
            <Wraper>
              <CodeBlock>
                <h1>Nome Sobrenome</h1>
                <pre>
                  {`
                <Input
                  title={"Nome e sobrenome"}
                  value={form.name}
                  onBlur={() => onBlur("name")}
                  onChange={(e) => onChange("name", e)}
                  error={error.name}
                  required
                  placeholder={"Insira o seu nome"}
                />
                `}
                </pre>
              </CodeBlock>
            </Wraper>
            <Wraper>
              <CodeBlock>
                <h1>Email</h1>
                <pre>
                  {`
                <Input
                  title={"E-mail"}
                  value={form.email}
                  onBlur={() => onBlur("email")}
                  onChange={(e) => onChange("email", e)}
                  error={error.email}
                  required
                  placeholder={"exemplo@exemplo.com"}
                />
            `}
                </pre>
              </CodeBlock>
            </Wraper>
            <Wraper>
              <CodeBlock>
                <h1>Senha</h1>
                <pre>
                  {`
                <Input
                  title={"Senha"}
                  value={form.password}
                  onBlur={() => onBlur("password")}
                  onChange={(e) => onChange("password", e)}
                  error={error.password}
                  type={"password"}
                  required
                  placeholder={"********"}
                />`}
                </pre>
              </CodeBlock>
            </Wraper>
            <Wraper>
              <CodeBlock>
                <h1>Celular</h1>
                <pre>
                  {`
                <Input
                  title={"Phone"}
                  value={form.phone}
                  onBlur={() => onBlur("phone")}
                  onChange={(e) => onChange("phone", e)}
                  error={error.phone}
                  type={"phone"}
                  required
                  placeholder={"(00) 00000-0000"}  
                />`}
                </pre>
              </CodeBlock>
            </Wraper>
            <Wraper>
              <CodeBlock>
                <h1>Cpf</h1>
                <pre>
                  {`
                <Input
                  title={"Cpf"}
                  value={form.cpf}
                  onBlur={() => onBlur("cpf")}
                  onChange={(e) => onChange("cpf", e)}
                  error={error.cpf}
                  required
                  placeholder={"000.000.000-00"}
                />`}
                </pre>
              </CodeBlock>
            </Wraper>
            <Wraper>
              <CodeBlock>
                <h1>Cnpj</h1>
                <pre>
                  {`
                <Input
                  title={"Cnpj"}
                  value={form.cnpj}
                  onBlur={() => onBlur("cnpj")}
                  onChange={(e) => onChange("cnpj", e)}
                  error={error.cnpj}
                  required
                  placeholder={"00.000.000/0000-00"}
                />`}
                </pre>
              </CodeBlock>
            </Wraper>
          </CardBody>
        </CardInfo>
      </Content>
    </Container>
  );
};

export default Inputs;
