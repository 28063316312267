import React, { useCallback, useState } from 'react';
import InputCode from '../../../components/InputCode';

import {
  Container,
  PanelFooter,
  PanelFooterText,
  Wrapper,
  EmailContainer,
} from './styles';

import { api } from '../../../services/api';
import { useToast } from '../../../hooks/useToast';

interface Props {
  callback: () => void;
  email: string;
  userId: number;
}

const Code: React.FC<Props> = ({ callback, email, userId }) => {

  const [status, setStatus] = useState('');

  const formated = `${email.slice(0, 2)}******@${email.split('@')[1]}`;
  const { toast } = useToast();

  const verifyCode = useCallback(async (code) => {
    try {
      await api.put(`/access-code/${code}`, {userId});

      setStatus('success');
      setTimeout(() => {
        callback();
      }, 500);

    } catch (error: any) {
      setStatus('');
      setStatus('error');
    }
  }, [callback, userId]);

  const resendingEmail = useCallback(async () => {
    try {
      await api.post('/access-code', { userId: userId });

      toast({
        type: 'success',
        description: 'Email reenviado com sucesso!'
      });
    } catch (e: any) {
      toast({
        type: 'error',
        description: e.message
      });

    }
    // eslint-disable-next-line
  }, [toast, userId]);

  return (
    <Container>
      <EmailContainer>
        <p>
          Insira o código de 4 dígitos que enviamos para o e-mail{' '}
          <b>{formated}</b>
        </p>
      </EmailContainer>
      <Wrapper>
        <InputCode validateCode={verifyCode} status={status} />
        <PanelFooter>
          <PanelFooterText>Se você não receber o código</PanelFooterText>
          <button onClick={resendingEmail}> Clique aqui</button>
        </PanelFooter>
      </Wrapper>
    </Container>
  );
};

export default Code;
