import styled from 'styled-components';

export const Header = styled.header`
  text-align: center;
  padding: 80px 0 48px;
  
  > h1 {
    color: ${props => props.theme.colors.purple};
  }
`;

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  li {
    cursor: pointer;
    border-radius: .25em;
    transition: background 0.2s;
    overflow: hidden;
    text-align: center;
    padding: 1em;

    &:hover {
      background: #f8f8f8;
    }
  }

  .icon {
    display: inline-block;
    font: normal normal normal 32px/1 'houpa';
    speak: none;
    text-transform: none;
    /* Better Font Rendering */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;
