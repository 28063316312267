import React, { useCallback, useEffect, useState, useContext } from 'react';
import {
  Container,
  CloseIcon,
  Header,
  Title,
  Return,
  ReturnIcon,
} from './styles';
import Cropper from './Cropper';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { api } from '../../services/api';
import { base64toBlob } from '../../helpers/formatting';
import { useToast } from '../../hooks/useToast';
import { useLoading } from '../../hooks/useLoading';
import { AuthContext } from '../../hooks/useAuth';

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

interface Props {
  userPhotoModalOpen: boolean;
  setUserPhotoModalOpen: (arg: boolean) => void;
}

export interface Image {
  base64: string;
  type: string;
}

const UserPhotoModal: React.FC<Props> = ({
  userPhotoModalOpen,
  setUserPhotoModalOpen,
}) => {
  const [image, setImage] = useState<Image>({} as Image);
  const classes = useStyles();
  const { toast } = useToast();
  const { showLoading, closeLoading } = useLoading();
  const { user, setUser } = useContext(AuthContext);

  const handleClose = () => {
    setUserPhotoModalOpen(false);
  };

  const updateProfileImage = useCallback(
    async () => {
      try {
        showLoading();
        const contentType = image.type === 'jpg' ? 'image/jpg' : 'image/png';
        const blob = base64toBlob(image.base64, contentType);
        const body = new FormData();
        body.append('image', blob);

        const { data } = await api.post(`/account-data/image`, body);
        const _user = {...user};
        _user.image = data.data.imageUrl;
        setUser(_user);

        setImage({} as Image);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (err: any) {
        closeLoading();
        toast({
          type: 'error',
          description: err.message,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [image],
  );

  useEffect(() => {
    image.base64 && updateProfileImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      className={classes.modal}
      open={userPhotoModalOpen}
      onClose={handleClose}
      closeAfterTransition
    >
      <Container>
        <CloseIcon onClick={handleClose}>
          <i className="icon icon-exit" />
        </CloseIcon>
        <Header>
          <Return onClick={handleClose}>
            <ReturnIcon className="icon icon-arrow-left" />
            <p>Voltar</p>
          </Return>
          <Title>Recortar foto do perfil</Title>
        </Header>
        <Cropper
          userPhotoModalOpen={userPhotoModalOpen}
          setUserPhotoModalOpen={setUserPhotoModalOpen}
          image={image}
          setImage={setImage}
        />
      </Container>
    </Modal>
  );
};

export default UserPhotoModal;
