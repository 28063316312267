import styled from 'styled-components';

export const AddCardContainer = styled.div`
  width: 310px;
  height: 256px;
  border: 1px solid var(--grey-light-1);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 607px) and (max-width: 1279px) {
    width: 296px;
    height: 240px;
  }
  @media screen and (max-width: 607px) {
    width: 312px;
    height: 183px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 310px;
  height: 256px;
  border: 1px solid var(--grey-light-1);
  border-radius: 16px;

  @media screen and (min-width: 607px) and (max-width: 1279px) {
    width: 296px;
    height: 240px;
  }
  @media screen and (max-width: 607px) {
    width: 312px;
    height: 183px;
  }
`;

export const AddButton = styled.button`
  display: flex;
  gap: 16px;
  font-size: 16px;
  font-weight: 700;
  align-items: center;
  text-transform: capitalize;
  width: 100%;
  height: 100%;
  justify-content: center;
  cursor: pointer;
  color: var(--grey-dark);
  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: var(--purple);
    color: var(--white);
    font-size: 22px;
  }
  @media screen and (max-width: 607px) {
    gap: 8px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > p {
    font-size: 18px;
    font-weight: 700;
    color: var(--grey-dark);
    letter-spacing: 0.2px;
    @media screen and (max-width: 607px) {
      font-size: 16px;
    }
  }
`;

export const HeaderActionContainer = styled.div`
  display: flex;
  gap: 15px;
`;

export const HeaderAction = styled.button`
  width: 16px;
  height: 19px;
  cursor: pointer;
  i {
    font-size: 18px;
    color: var(--grey);
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const Payment = styled.div`
  display: flex;
  gap: 32px;
  @media screen and (min-width: 607px) and (max-width: 1279px) {
    gap: 20px;
  }
  @media screen and (max-width: 607px) {
    gap: 12px;
  }
`;

export const CardIcon = styled.div`
  max-width: 55px;
  height: 35px;
  img {
    width: 100%;
  }
`;

export const CardInfoContainer = styled.div`
  .lastNumbers {
    font-size: 16px;
    font-weight: 500;
  }

  .cardBanner {
    margin-top: 5px;
    text-transform: capitalize;
  }

  .cardBanner,
  .dueDate {
    font-size: 16px;
    color: var(--grey-medium-4);
    line-height: 22px;
  }
`;

export const AddressContainer = styled.div`
  display: flex;
`;

export const AddressInfoWrapper = styled.ul`
  li {
    line-height: 20px;
    color: var(--grey-dark);
    @media screen and (min-width: 607px) and (max-width: 1279px) {
      font-size: 14px;
      line-height: 18px;
    }
    @media screen and (max-width: 607px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
`;

export const Footer = styled.div`
  width: 100%;
`;
