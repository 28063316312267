import React from 'react';
import {
  Col,
  Container,
  ProfileContainer,
  Row,
  Title,
  Text,
  InfoCol,
  LinkRow,
  TooltipContainer,
  LinkRowMobile,
  Content,
  DevolutionButton,
  ReplaceLink,
  ProductUnavailable,
} from './styles';
import { Link } from 'react-router-dom';
import Tooltip from '../../../../../components/Tooltip';
import { ProductsInterface } from '../../Details';

interface OwnProps {
  product: ProductsInterface;
  brand: string;
  profileUrl: string;
  isCanceled: boolean;
  exchangeEnabled: boolean;
  onReplace?: () => void;
}

type Props = OwnProps;

const OrderProductCard: React.FC<Props> = ({
  product,
  onReplace,
  brand,
  isCanceled,
  exchangeEnabled,
  profileUrl
}) => {

  return (
    <Container
      exchangedOrReturned={
        product.exchangeReturnRequested === 1 &&
          product.exchangeReturnStatus === 0
          ? true
          : false
      }
    >
      {!isCanceled && exchangeEnabled && (
        <LinkRowMobile>
          {product.exchangeReturnRequested === 0 && onReplace && (
            <ReplaceLink onClick={() => onReplace()}>
              Trocar ou devolver
            </ReplaceLink>
          )}
          {product.exchangeReturnRequested === 1 && (
            <>
              <Link className={'devolution'} to={'#'}>
                Troca/devolução solicitada
              </Link>
              <Tooltip>
                <TooltipContainer>
                  <div>
                    <p>Status da solicitação:</p>
                    <strong>Troca em andamento</strong>
                  </div>
                  <div>
                    <p>Data da solicitação: {product.exchangeReturnDate}</p>
                    <p>Motivo da solicitação:</p>
                    <p>{product.reasons}</p>
                  </div>
                </TooltipContainer>
              </Tooltip>
            </>
          )}
        </LinkRowMobile>
      )}

      <Content>
        <Row>
          {product.product_status === 1
            ? <Link to={`/produto/${profileUrl}/${product.product_slug}`}>
              <ProfileContainer>
                <img src={product.product_image} alt={product.product_name} />
              </ProfileContainer>
            </Link>
            : <ProfileContainer>
              <ProductUnavailable>
                <Text>Produto</Text>
                <Text>Indisponível</Text>
              </ProductUnavailable>
            </ProfileContainer>
          }
          <Col>
            <div className={'storeInfo'}>
              <Title className={'noMb'}>Loja: {brand}</Title>
              <Text title={product.product_name}>{product.product_name}</Text>
            </div>

            <div>
              <Text>Tam: {product.product_size}</Text>
              <Text>Cor: {product.product_color}</Text>
              <Text>SKU: {product.SKU}</Text>
            </div>

            {product.exchangeReturnRequested === 1 &&
              product.exchangeReturnStatus === 0 && (
                <DevolutionButton className={'mb'}>
                  <div>
                    <i className="icon icon-arrows" />
                  </div>
                  Item devolvido
                </DevolutionButton>
              )}
          </Col>
        </Row>

        <Row style={{ justifyContent: 'space-between' }}>
          <Col>
            <InfoCol>
              <Title>Valor Unitário</Title>
              <Text>{product.unit_price}</Text>

              {product.exchangeReturnRequested === 1 &&
                product.exchangeReturnStatus === 0 && (
                  <DevolutionButton>
                    <div>
                      <i className="icon icon-arrows" />
                    </div>
                    Item devolvido
                  </DevolutionButton>
                )}
            </InfoCol>
          </Col>

          <Col>
            <InfoCol>
              <Title>Quantidade</Title>
              <Text>{product.quantity}</Text>
            </InfoCol>
          </Col>

          <Col style={{ alignItems: 'flex-end' }}>
            <InfoCol>
              <Title>Subtotal</Title>
              <Text>{product.subtotal}</Text>
            </InfoCol>

            {!isCanceled && exchangeEnabled && (
              <LinkRow>
                {product.exchangeReturnRequested === 0 && onReplace && (
                  <ReplaceLink onClick={() => onReplace()}>
                    Trocar ou devolver
                  </ReplaceLink>
                )}
                {product.exchangeReturnRequested === 1 && (
                  <>
                    <Link to="#" className={'devolution'}>
                      Troca/devolução solicitada
                    </Link>
                    <Tooltip>
                      <TooltipContainer>
                        <div>
                          <p>Status da solicitação:</p>
                          <strong>
                            {product.exchangeReturnType === 1
                              ? 'Troca em andamento'
                              : 'Devolução em andamento'}
                          </strong>
                        </div>
                        <div>
                          <p>
                            Data da solicitação: {product.exchangeReturnDate}
                          </p>
                          <p>Motivo da solicitação:</p>
                          <p>{product.reasons}</p>
                        </div>
                      </TooltipContainer>
                    </Tooltip>
                  </>
                )}
              </LinkRow>
            )}
          </Col>
        </Row>
      </Content>
    </Container>
  );
};

export default OrderProductCard;
