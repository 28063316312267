import styled from 'styled-components';

export const ModalContent = styled.div`
  position: relative;
  outline: none;
  width: 640px;
  padding: 52px 0;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 680px) and (max-width: 1279px) {
    width: 456px;
    padding: 40px 0 32px;
  }
  @media screen and (max-width: 680px) {
    width: 310px;
    padding: 30px 0 40px;
    border-radius: 16px;
  }
`;

export const CloseModalIcon = styled.div`
  position: absolute;
  top: 36px;
  right: 36px;
  > i {
    color: var(--grey-light-1);
    font-size: 18px;
  }
  cursor: pointer;
  @media screen and (min-width: 680px) and (max-width: 1279px) {
    top: 24px;
    right: 24px;
  }
  @media screen and (max-width: 680px) {
    top: 16px;
    right: 16px;
  }
`;

export const FormContainer = styled.form`
  width: 360px;
  display: flex;
  flex-direction: column;
  gap: 34px;
  @media screen and (min-width: 680px) and (max-width: 1279px) {
    width: 300px;
  }
  @media screen and (max-width: 680px) {
    width: 230px;
  }
`;

export const Title = styled.h2`
  font-size: 30px;
  color: var(--black);
  margin-bottom: 64px;
  @media screen and (max-width: 680px) {
    font-size: 24px;
    margin-bottom: 37px;
  }
`;

export const ButtonContainer = styled.div`
  margin: 14px auto 0 auto;
  width: 264px;
  .btnDevice {
    display: none;
  }
  @media screen and (min-width: 680px) and (max-width: 1279px) {
    width: 250px;
    .btnWeb {
      display: none;
    }
    .btnDevice {
      display: block;
    }
  }
  @media screen and (max-width: 680px) {
    margin: 0 auto;
    width: 120px;
    .btnWeb {
      display: none;
    }
    .btnDevice {
      display: block;
      font-size: 14px;
    }
  }
`;
